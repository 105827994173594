import { render, staticRenderFns } from "./SpecimenCard.vue?vue&type=template&id=259f068f&scoped=true"
import script from "./SpecimenCard.vue?vue&type=script&lang=js"
export * from "./SpecimenCard.vue?vue&type=script&lang=js"
import style0 from "./SpecimenCard.vue?vue&type=style&index=0&id=259f068f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259f068f",
  null
  
)

export default component.exports