export default [
	{
		path: "",
		component: () => import(/* webpackChuckName: 'auth' */ "@/views/auth/layouts/Auth.vue"),
		children: [
			{
				path: "/login",
				name: "login",
				component: () => import(/* webpackChuckName: 'login' */ "@/views/auth/views/Login.vue"),
				meta: {
					layout: "full",
					resource: "Auth",
					redirectIfLoggedIn: true,
				},
			},
			{
				path: "/forget-password",
				name: "forget-password",
				component: () =>
					import(/* webpackChuckName: 'forget-password' */ "@/views/auth/views/ForgetPassword.vue"),
				meta: {
					layout: "full",
					resource: "Auth",
					redirectIfLoggedIn: true,
				},
			},
		],
	},
];
