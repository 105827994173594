<template>
	<div>
		<div class="specimen">
			<img
				v-if="!not_image && is_specimen"
				onerror="this.src='/rooster/rooster.jpg'"
				@click.self="clickImageSpecimen(specimen)"
				class="img-specimen"
				:src="specimen.thumb ? specimen.thumb : '/rooster/rooster.jpg'"
				:alt="specimen.plate"
			/>
			<div class="info">
				<div class="d-flex align-items-center">
					<div class="container-text">
						<p
							v-if="specimen.plate"
							@click="is_specimen && linkSpecimen(specimen.id)"
							class="plate"
							:class="[is_specimen ? 'cursor-pointer' : '']"
							:style="{
								color: is_specimen ? 'white' : '#ffffff',
								background: is_specimen ? 'rgba(0, 0, 0, 0.7)' : '#7367f0',
							}"
						>
							{{ specimen.plate ? specimen.plate : "" }}
						</p>
						<!--  -->
						<p v-if="specimen.alias" class="alias text-center">
							{{ specimen.alias ? specimen.alias : "" }}
						</p>

						<p v-if="!specimen.alias && assignableAlias" class="alias text-center">
							<span class="text-danger">SIN ALIAS</span>
						</p>
						<!--  -->
						<p v-if="show_name_cate" class="mb-0 font-weight-bold status text-warning mb-25">
							{{ specimen.category }}
						</p>
						<p
							v-if="specimen.additional_plate"
							class="plate plate-additional"
							:class="[is_specimen ? 'cursor-pointer mt-0 ' : '']"
						>
							{{ specimen.additional_plate ? specimen.additional_plate : "" }}
						</p>
					</div>
					<div v-if="specimen.ran" class="ml-1">
						<IconSpecimenRan :guilty="specimen.guilty ? true : false" />
					</div>
				</div>
			</div>
			<!-- <template v-if="is_icon">
				<img
					v-if="is_specimen"
					src="/pollos/icon-rooster-plate-component.svg"
					alt=""
					:style="{ height: '20px' }"
				/>
				<img v-else src="/pollos/icon-chick-plate-component.svg" alt="" :style="{ height: '20px' }" />
			</template> -->
		</div>

		<SlidersImageVideo
			:url="clickImage.url"
			:type="clickImage.type"
			:text="clickImage.text"
			:text_url="clickImage.text_url"
			:open="clickImage.open"
			v-if="clickImage.open"
			@refreshSlider="refresh"
			:idSpecimen="specimen.id"
		/>
	</div>
</template>

<script>
import { ref } from "@vue/composition-api"
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue"
import IconSpecimenRan from "./IconSpecimenRan.vue"

export default {
	name: "SpecimenPlate",
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		not_image: {
			type: Boolean,
			default: false,
		},
		setWidth: {
			type: String,
			default: "200px",
		},
		is_specimen: {
			type: Boolean,
			default: true,
		},
		is_icon: {
			type: Boolean,
			default: false,
		},
		show_name_cate: {
			type: Boolean,
			default: true,
		},
		assignableAlias: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		SlidersImageVideo,
		IconSpecimenRan,
	},
	setup() {
		const newObjectClickImage = () => ref({ url: null, type: "image", text: null, text_url: null, open: false })

		const clickImage = newObjectClickImage()

		const clickImageSpecimen = (item) => {
			// console.log('ref', item);
			clickImage.value = {
				open: true,
				text_url: `detalle/${item.id}`,
				type: "image",
				text: `${item.plate} ${item.alias ? "-" : ""} ${item.alias ? item.alias : ""}`,
				url: item.image ? item.image : "/rooster/rooster.jpg",
			}
		}

		return {
			clickImage,
			clickImageSpecimen,
			linkSpecimen: (id) => window.open(`/ejemplares/detalle/${id}`, "_blank"),
			refresh: () => (clickImage.value = newObjectClickImage()),
		}
	},
}
</script>

<style lang="scss" scoped>
.specimen {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 1.5rem;
	// background: red;
	// width: auto;
}

@media screen and (min-width: 700px) {
	.specimen {
		flex-direction: row;
		justify-content: center;
	}
}
.img-specimen {
	width: 50px;
	height: 50px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: cover;
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 70px;
		height: 70px;
		// background: red;
	}
}
.info {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;

	@media screen and (min-width: 700px) {
		align-items: center;
	}
	.alias {
		margin: 0.3rem 0;
		// font-weight: bold;
		color: #6e6b7b !important;
		font-size: 12.5px;
	}
	.container-text {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}
.plate {
	margin-top: 0.5rem;
	// background: rgba(0, 0, 0, 0.7);
	// color: white;
	font-weight: bold;
	padding: 0.08rem 0.5rem !important;
	border-radius: 6px;
	width: auto;
	font-size: 12px;
	margin-bottom: 0.5rem !important;
	@media screen and (min-width: 700px) {
		font-size: 14px;
		padding: 0.1rem 0.8rem !important;
		border-radius: 8px;
	}
}
.plate-additional {
	color: var(--primary);
}
</style>
