export default [
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/amg/settings/SettingsComponent.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Settings',
            breadcrumb: [
                {
                    text: "Settings",
                    active: true
                }
            ],
            roles: [1]
        }
    }
];
