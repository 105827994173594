import router from "@/router";
import types from "./types";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	// [types.FETCH_PEDIGREE]: (state, payload) => {
	// 	state.pedigree = payload;
	// },

	[types.TOGGLE_CHECK_SPECIMEN_MATCH]: (state, { index, check }) => {
		state.match[index].check = check;
	},

	[types.GET_INVENTORIES]: (state, { data, module }) => {
		const inventories = state.inventories;
		let dataNew = data;
		inventories.forEach((it, index) => {
			module == 1 && (dataNew[index].active = inventories[index].active);
		});
		state.inventories = dataNew;
	},

	[types.SELECT_INVENTORY]: (state, { item }) => {
		state.inventory = item;
	},

	[types.GET_MATCH]: (state, { data, from, to, total, last_page, type, counters }) => {
		state.start_page = from;
		state.to_page = to;
		state.total_data = total;

		// console.log("DATA", data);

		switch (type) {
			case 1:
				state.match = data;
				state.countersMatch = counters;
				break;
			// case 2:
			// 	state.unmatch = data;
			// 	break;
			case 2:
				state.spare = data;
				break;
			case 3:
				state.missing = data;
				break;

			default:
				break;
		}
	},

	[types.GET_UN_MATCH]: (state, { data, from, to, total, last_page }) => {
		state.unmatch = data;
		state.start_page = from;
		state.to_page = to;
		state.total_data = total;
	},

	[types.SET_FILTERS]: (state, { search, page }) => {
		state.search = search;
		state.page = page;
	},

	[types.TOGGLE_CHECK_UNMATCH]: (state, { key, toggle }) => {
		state.unmatch[key].check = toggle;
	},

	[types.GET_COUNTERS_INVENTORY]: (
		state,
		{ data: { match, missing, spare, un_match, code, is_change = true, matchIndex, value, status_id, id, module, alias, dad_code } }
	) => {
		console.log("object", matchIndex, match, spare, missing);

		if (matchIndex == 0) {
			if (match > 0) {
				state.indexInventory = 0;
			} else {
				state.indexInventory = 1;
			}
		}

		// if (matchIndex == 1) {
		// 	if (un_match > 0) {
		// 		state.indexInventory = 1;
		// 	} else {
		// 		state.indexInventory = 2;
		// 	}
		// }

		if (matchIndex == 1) {
			if (missing > 0) {
				if (module == 1) {
					state.indexInventory = 1;
				} else {
					state.indexInventory = 1;
				}
			} else {
				if (module == 1) {
					state.indexInventory = 0;
				} else {
					state.indexInventory = 2;
				}
			}
		}

		if (matchIndex == 2) {
			if (spare > 0) {
				state.indexInventory = 2;
			} else {
				state.indexInventory = 0;
			}
		}

		state.counters[0].counter = match;

		state.counters[1].counter = missing;

		state.counters[2].counter = spare;

		state.counters[0].visible = true;
		state.counters[1].visible = true;
		if (module == 1) {
			state.counters[2].visible = false;
		} else {
			state.counters[2].visible = true;
		}

		state.inventory.code = code;
		state.inventory.value = value;
		state.inventory.id = id;
		state.inventory.status_id = status_id;
		state.inventory.alias = alias;

		state.inventory.dad_code = dad_code;
	},

	[types.UPDATE_INDEX_TAB]: (state, { index }) => {
		state.indexUnMatch = index;
	},

	[types.UPDATE_INDEX_INVENTORY]: (state, { index }) => {
		state.indexInventory = index;
	},

	[types.TOGGLE_RELOAD]: (state, { key }) => {
		state.reload = key;
	},

	[types.CLEAN_DATA]: (state, payload) => {
		state.match = [];
		state.unmatch = [];
		state.spare = [];
		state.missing = [];
		state.countersMatch = [];

		// state.search = null;

		// state.counters[0].counter = 0;

		// state.counters[1].counter = 0;

		// state.counters[2].counter = 0;
	},

	[types.ACTIVE_CHILDREN_INVENTORIES]: (state, { index, key }) => {
		state.inventories[index].active = key;
	},

	[types.UPDATE_COMMENT]: (state, { index, key, comment }) => {
		state.inventories[index].children[key].comment = comment;
	},

	[types.CLEAN_SEARCH]: (state, payload) => {
		state.search = null;
	},
};
