export default [
	{
		path: "/brain/others",
		name: "brain-others-main",
		redirect: { name: "brain-others-clients" },
		component: () => import("@/views/brain/others/main.vue"),
		children: [
			{
				path: "clients",
				name: "brain-others-clients",
				component: () => import("@/views/brain/others/views/clients/ListClientsBrain.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Clientes",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "videos",
				name: "brain-others-videos",
				component: () => import("@/views/brain/others/views/videos/SettingsVideos.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Videos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "categories",
				name: "brain-others-categories",
				component: () => import("@/views/brain/others/views/categories/components/MgtCategoryList.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Categorias",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "assistance",
				name: "brain-others-assistance",
				component: () => import("@/views/amg/header-bookmarks/attendance/views/AttendanceMain.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Assistance",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "tasks",
				name: "brain-others-tasks",
				component: () => import("@/views/brain/others/views/Tasks.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Tareas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "razones",
				name: "brain-others-reasons",
				component: () => import("@/views/brain/others/views/reasons/ReasonsList.vue"),
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Motivos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "decrest",
				name: "brain-others-decrest",
				component: () => import("@/views/brain/others/views/Decrest.vue"),
				props: { origin: "others" },
				meta: {
					pageTitle: "Otras configuraciones",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Otros",
							active: true,
						},
						{
							text: "Descreste",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
]
