<template>
	<b-modal hide-footer centered :title="title" size="lg" @hidden="handleHidden" v-model="isActive">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header style="min-height: 60vh">
				<b-thead>
					<b-tr class="text-center">
						<template v-if="type == 1">
							<b-th>MADRILLA</b-th>
							<b-th>HUEVOS</b-th>
							<b-th>REGISTRADO POR</b-th>
							<b-th>ACEPTADO POR</b-th>
						</template>
						<template v-else-if="type == 2">
							<b-th>MADRILLA</b-th>
							<b-th>PADRILLO</b-th>
							<b-th>CODIGO</b-th>
							<b-th>HUEVOS</b-th>
						</template>
						<template v-else-if="type == 3">
							<b-th>MADRILLA</b-th>
							<b-th>HUEVOS</b-th>
						</template>
					</b-tr>
				</b-thead>
				<b-thead>
					<b-tr class="text-center" v-for="(row, i) in rows" :key="i">
						<template v-if="type == 1">
							<b-td>
								<SpecimenPlate :specimen="row.madrilla" />
							</b-td>
							<b-td>{{ row.eggs_qty }}</b-td>
							<b-td
								>{{ row.accepted_by.user }} <br />
								<small>{{ row.accepted_by.created_at | myGlobalDayShort }}</small></b-td
							>
							<b-td
								>{{ row.created_by.user }} <br />
								<small>{{ row.created_by.created_at | myGlobalDayShort }}</small></b-td
							>
						</template>
						<template v-else-if="type == 2">
							<b-td>
								<SpecimenPlate :specimen="row.madrilla" />
							</b-td>
							<b-td>
								<SpecimenPlate :specimen="row.padrillo" />
							</b-td>
							<b-td>{{ row.postura_code }}</b-td>
							<b-td>{{ row.qty }}</b-td>
						</template>
						<template v-else-if="type == 3">
							<b-td>
								<SpecimenPlate :specimen="row.madrilla" />
							</b-td>
							<b-td>{{ row.qty }}</b-td>
						</template>
					</b-tr>
				</b-thead>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import eggsFoundService from "@/services/header/eggs-found.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: String,
		type: Number,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	computed: {
		title() {
			switch (this.type) {
				case 1:
					return "REGISTRADOS / " + this.info;
				case 2:
					return "EN POSTURAS / " + this.info;
				case 3:
					return "DEL AIRE / " + this.info;
			}
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.$emit("closing");
		},
		async getRows() {
			this.isLoading = true;
			let payload = { date: this.info };
			let res;
			switch (this.type) {
				case 1:
					res = await eggsFoundService.getAcceptedRegistered(payload);
					break;
				case 2:
					res = await eggsFoundService.getAcceptedPostura(payload);
					break;
				case 3:
					res = await eggsFoundService.getAcceptedAire(payload);
					break;
			}
			this.rows = res.data;
			this.isLoading = false;
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val;
				this.getRows();
			}
		},
	},
};
</script>
