import Vue from "vue"

import router from "./router"
import store from "./store"
import App from "./App.vue"

// Buscador
import Fuse from "fuse.js"
window.Fuse = Fuse

// Global Mixins general.js
import generalMixins from "./mixins/general"
Vue.mixin(generalMixins)

// Global Components
import "./global-components"

// Plugins
import "./plugins"
import VueHtml2Pdf from 'vue-html2pdf'
// Socket

import "./socket"

// Filters
import "./helpers/filters"
import "@/libs/acl"
import "@/libs/portal-vue"
import "@/libs/clipboard"
import "@/libs/toastification"
import "@/libs/sweet-alerts"
import "@/libs/vue-select"
import "@/libs/tour"
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css") // For form-wizard

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

import "./helpers/validators"

Vue.directive("click-outside", {
	bind: function (el, binding, vNode) {
		const bubble = binding.modifiers.bubble
		const handler = (e) => {
			if (bubble || (!el.contains(e.target) && el !== e.target)) {
				binding.value(e)
			}
		}
		el.__vueClickOutside__ = handler
		document.addEventListener("click", handler)
	},

	unbind: function (el, binding) {
		document.removeEventListener("click", el.__vueClickOutside__)
		el.__vueClickOutside__ = null
	},
})

Vue.config.productionTip = false

Vue.prototype.$log = console.log
Vue.use(VueHtml2Pdf)
export const bus = new Vue()

const app = new Vue({
	el: "#app",
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
