<template>
	<svg width="17" height="20" fill="none" viewBox="0 0 14 17" :class="[guilty ? 'text-danger' : 'text-info']" class="">
		<g>
			<path
				fill="currentColor"
				d="M0 4.799c3.385 2.494 6.918 2.88 10.705 1.094v1.801c-.484.037-.975.03-1.436.124-1.324.292-2.373 1.386-2.485 2.954-.03.386.238.693.588.714.35.022.632-.248.67-.634.118-1.24.907-1.94 2.187-1.947h1.004c.513 0 .744-.219.744-.73.007-1.13 0-2.26.007-3.39 0-.49.335-.898.781-.978.513-.095.997.146 1.175.613.053.145.067.306.067.459.008 1.13-.007 2.26.008 3.391a4.88 4.88 0 0 1-.722 2.633c-.454.737-.84 1.48-.9 2.363H1.963c-.044-.175-.059-.365-.133-.525-.238-.518-.446-1.05-.759-1.524C.41 10.225.007 9.175.015 7.979.015 6.943 0 5.9 0 4.799Z"
			/>
			<path
				fill="currentColor"
				d="M6.182 0c1.13 0 2.254-.007 3.384 0 1.37.007 2.44.853 2.753 2.159.067.299.074.496-.298.642-.617.24-.982.743-1.212 1.356a.945.945 0 0 1-.357.452 8.346 8.346 0 0 1-9.143-.394 6.501 6.501 0 0 1-1.026-.933c-.305-.336-.328-.759-.216-1.203C.394.839 1.443.015 2.767 0h3.415Zm1.004 17H2.351c-.833 0-1.37-.489-1.384-1.24-.007-.773.536-1.305 1.369-1.305 3.213-.008 6.42 0 9.633 0 .848 0 1.369.496 1.369 1.29 0 .781-.506 1.255-1.354 1.255H7.186Z"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	name: "IconSpecimenRan",
	props: {
		guilty: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
