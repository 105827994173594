export default [
	{
		path: "/brain/nursery",
		name: "brain-nursery-main",
		redirect: { name: "brain-nursery-diagnostic" },
		component: () => import("@/views/brain/nursery/main.vue"),
		children: [
			{
				path: "diagnostic",
				name: "brain-nursery-diagnostic",
				component: () => import("@/views/brain/nursery/views/diagnostic.vue"),
				meta: {
					pageTitle: "Enfermeria",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Enfermeria",
							active: true,
						},
						{
							text: "Diagnosticos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "treatment",
				name: "brain-nursery-treatment",
				component: () => import("@/views/brain/nursery/views/treatment.vue"),
				meta: {
					pageTitle: "Enfermeria",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Enfermeria",
							active: true,
						},
						{
							text: "Tratamientos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
]
