import Vue from "vue"

import ClientsService from "@/services/clients.service"
import ReasonsService from "@/services/reasons.service"
import UtilitiesService from "@/services/utilities.service"
import DeadsService from "@/services/deads.service"
import specimenService from "@/services/specimens.service"
import moment from "moment"
// TODO: Modificar este servicio
import * as diseasesService from "@/views/brain/specimens/views/diseases/diseases.service.js"
import { setEvidencesBase64, justNumbers } from "@/helpers/functions"

/* STATES */
const state = {
	selectedSpecimensStatus: [],
	arrayStatus: [],
	arrayReasons: [],
	arrayClients: [],
	arrayDiseases: [],
	arrayUsers: [],
	arrayImagesSpecimens: [],
	indexImages: null,
	globalChanges: {
		new_status_id: null,
		responsable_id: null,
		disease_id: null,
		reason_death_id: null,
		client_id: null,
		date_status: moment().format("YYYY-MM-DD"),
		others_description: null,
		price: null,
	},
	category_id: 3,
	init: false,
}

/* GETTERS */
const getters = {
	arrayStatusToExcessPlates(state) {
		// VENDIDO, MUERTO, REGALADO, PRESTADO, DONADO
		return state.arrayStatus.filter((s) => [2, 3, 4, 5, 11].includes(s.id)) || []
	},
	arrayStatusToPlatesToRegister(state) {
		// ACTIVO, ENFERMO, , MUDA, PREPARACION, PRE-ENCASTE, HERIDO,
		return state.arrayStatus.filter((s) => [1, 6, 9, 10, 12, 13].includes(s.id)) || []
	},
	arrayStatus: (state) => state.arrayStatus,
}

/* MUTATIONS */
const mutations = {
	SET_CLEAR_ARRAYS(state) {
		state.arrayStatus = []
		state.arrayReasons = []
		state.arrayClients = []
		state.arrayDiseases = []
		state.arrayUsers = []
		state.arrayAvailableSpecimens = []
		state.selectedSpecimensStatus = []
	},
	SET_ARRAY_STATUS(state, data) {
		state.arrayStatus = data
	},
	SET_ARRAY_STATUS_WITH_INDEX(state, data) {
		state.selectedSpecimensStatus[data.index] = data.statuses
	},
	SET_ARRAY_REASONS(state, data) {
		state.arrayReasons = data
	},
	SET_ARRAY_REASONS_WITH_INDEX(state, data) {
		state.selectedSpecimensStatus[data.index].reasons = data.reasons
	},
	SET_ARRAY_CLIENTS(state, data) {
		state.arrayClients = data
	},
	SET_ARRAY_DISEASES(state, data) {
		state.arrayDiseases = data
		state.arrayDiseases.push({
			id: 1,
			name: "OTROS",
		})
	},
	SET_ARRAY_USERS(state, data) {
		state.arrayUsers = data
	},
	SET_ARRAY_AVAILABLE_SPECIMENS(state, data) {
		if (state.selectedSpecimensStatus.length) {
			// compararing with selected specimens
			state.arrayAvailableSpecimens = data.filter(
				(s) => !state.selectedSpecimensStatus.find((ss) => ss.specimen_id === s.specimen_id)
			)
		} else {
			state.arrayAvailableSpecimens = data
		}
		state.arrayInitialAvailableSpecimens = structuredClone(state.arrayAvailableSpecimens)
	},
	async PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS(state, data) {
		let datas = await specimenService.getAllStatusSpecimens(data.category_id)

		const specimen = {
			...data,
			specimen_id: data.id || data.specimen_id, //
			new_status_id: null,
			new_is_reason: null,
			reasons: [],
			statuses: datas,
			responsable_id: null,
			date_status: moment().format("YYYY-MM-DD"),
			description: null,
			reason_death_id: null,
			others_description: null,
			disease_id: null,
			weight: null,
			pounds_weight: null,
			type_ouput: null,
			evidences: [],
			customFormData: [],
			price: null,
			client_id: null,
			check: false,
		}

		
		
		state.selectedSpecimensStatus.unshift(specimen)

		if (data.thumb) {
			state.arrayImagesSpecimens.unshift({
				title: data.alias + "  |  " + data.plate,
				src: data.photo,
				specimen_id: data.specimen_id,
			})
		}

		for (let key in specimen) {
			for (let keyGlobal in state.globalChanges) {
				if (key == keyGlobal && state.globalChanges[keyGlobal] != null) {
					specimen[key] = state.globalChanges[keyGlobal]
				}
			}
		}
	},
	DELETE_SELECTED_SPECIMENS_STATUS(state, data) {
		for (let key in data.specimen) {
			if (
				key !== "alias" &&
				key !== "category" &&
				key !== "category_id" &&
				key !== "plate" &&
				key !== "specimen_id" &&
				key !== "status" &&
				key !== "status_id"
			) {
				Vue.set(data.specimen, key, null)
			}
		}
		state.selectedSpecimensStatus.splice(data.index, 1)
		state.arrayImagesSpecimens.splice(data.index, 1)
	},
	async CLEAN_SPECIMENS(state) {
		state.selectedSpecimensStatus = []
		state.arrayImagesSpecimens = []
		for (let key in state.globalChanges) {
			state.globalChanges[key] = null
		}
		state.selectedSpecimensStatus = []
	},
	CHANGE_STATUS_GLOBAL(state, data) {
		state.selectedSpecimensStatus.forEach((specimen) => {
			specimen.new_status_id = data
		})
	},
	CHANGE_RESPONSABLE_GLOBAL(state, data) {
		state.selectedSpecimensStatus.forEach((specimen) => {
			specimen.responsable_id = data
		})
	},
	CHANGE_DATE_GLOBAL(state, data) {
		state.selectedSpecimensStatus.forEach((specimen) => {
			specimen.date_status = data
		})
	},
	M_CLEAN_GLOBAL_CHANGES(state) {
		for (let key in state.globalChanges) {
			if (key !== "date_status") {
				state.globalChanges[key] = null
			}
		}
		state.globalChanges.date_status = moment().format("YYYY-MM-DD")
	},
	M_UPDATE_CHANGES(state, { key, value }) {
		state.globalChanges[key] = value
		state.selectedSpecimensStatus.forEach((specimen) => {
			specimen[key] = state.globalChanges[key]
		})
	},
	M_SET_INDEX_IMAGES(state, data) {
		if (data) {
			let index = state.arrayImagesSpecimens.findIndex((image) => image.specimen_id === data.specimen_id)
			state.indexImages = index
			return
		}
		state.indexImages = null
	},
	M_SET_CATEGORY_ID(state, data) {
		state.category_id = data
	},
	M_SET_SPECIMENS_TO_CHANGE_STATUS(state, data) {
		state.selectedSpecimensStatus = data
	},
	M_TOGGLE_CHECK_SPECIMEN(state, { check, key, new_status_id = null }) {
		state.selectedSpecimensStatus[key].check = check
		new_status_id && (state.selectedSpecimensStatus[key].new_status_id = new_status_id)
	},
	M_SET_NEW_STATUS_SPECIMEN(state, { id, new_status_id = null }) {
		new_status_id && (state.selectedSpecimensStatus[0].new_status_id = new_status_id)
	},
	M_INIT(state, { init }) {
		state.init = init
	},
}

/* ACTIONS */
const actions = {
	async getAllStatusSpecimens({ commit }, { category_id = null, index = null }) {
		// console.log("index: ", index)
		let data = await specimenService.getAllStatusSpecimens(category_id)
		// if (index != null) commit("SET_ARRAY_STATUS_WITH_INDEX", { index, statuses: data })
		// else 
		commit("SET_ARRAY_STATUS", data)
	},
	async getReasons({ commit }, { module_id, status_id = null, index = null }) {
		let resp = await ReasonsService.getByModule(module_id, status_id)
		if (index != null) commit("SET_ARRAY_REASONS_WITH_INDEX", { index, reasons: resp })
		else commit("SET_ARRAY_REASONS", resp)
	},
	async getClients({ commit }) {
		let { data } = await ClientsService.getClients()
		commit("SET_ARRAY_CLIENTS", data)
	},
	async getDiseases({ commit }) {
		let data = await diseasesService.getDiseases()
		commit("SET_ARRAY_DISEASES", data)
	},
	async getUsersByRole({ commit }, rolId) {
		let { data } = await UtilitiesService.getUsersByRole(rolId)
		data.forEach((item) => (item.name = item.name.toUpperCase()))
		commit("SET_ARRAY_USERS", data)
	},

	async updateStatusPlates({ commit, state, dispatch, rootGetters }, { specimens, type_plates }) {
		commit("app/M_IS_PRELOADING", true, { root: true })
		await dispatch("A_SAVE_STATUS_MASSIVELY")

		if (type_plates == "excess") {
			await dispatch(
				"squareUpInventory/updateExcessPlates",
				{ specimens, arrayStatus: state.arrayStatus },
				{ root: true }
			)
		}
		if (type_plates == "to_register") {
			await dispatch(
				"squareUpInventory/updatePlatesToRegisterWhenChangeStatus",
				{ specimens, arrayStatus: state.arrayStatus },
				{ root: true }
			)
		}
		commit("app/M_IS_PRELOADING", false, { root: true })
	},
	async saveDeadEspecimen({ rootGetters }, deads) {
		try {
			deads = await setEvidencesBase64(deads)
			deads = deads.map((d) => ({
				...d,
				id_specimen: d.id,
				id: d.plate,
				observation: d.resason || null,
				type: 3,
				others_description: d.other_description,
			}))
			deads = JSON.stringify(deads)
			await DeadsService.saveDead(deads)
		} catch (error) {
			console.error(error)
		}
	},

	async A_SAVE_STATUS_MASSIVELY({ state, rootGetters }) {
		try {
			let deads = []

			state.selectedSpecimensStatus.forEach(async (specimen) => {
				specimen.disease_id = specimen.reason_death_id
				specimen.reason_id = specimen.reason_death_id

				//VENDIDO
				if (specimen.new_status_id === 2) {
					specimen.cleanPrice = JSON.parse(JSON.stringify(specimen.price))
					specimen.cleanPrice = justNumbers(specimen.cleanPrice)
				}
				

				//DONADO, VENDIDO, REGALADO, PRESTADO
				if ([2, 4, 5, 11].includes(specimen.new_status_id)) {
					specimen.type_ouput =
						specimen.new_status_id === 2
							? 1
							: specimen.new_status_id === 4
							? 2
							: specimen.new_status_id === 5
							? 3
							: specimen.new_status_id === 11
							? 4
							: null
				}

				//DEADS
				if (specimen.new_status_id === 3) {
					deads.push(specimen)
				}
			})
			if (deads.length) deads = await setEvidencesBase64(deads)
			let body = {
				created_by: rootGetters["auth/currentUser"].user_id,
				all_status: state.selectedSpecimensStatus,
				deads: JSON.stringify(deads),
			}
			await specimenService.saveStatusMassively(body)
		} catch (error) {
			throw error
		}
	},

	async A_SAVE_STATUS_MASSIVELY_CHECK({ state, rootGetters }) {
		try {
			let deads = []
			console.log("add")

			const specimens = state.selectedSpecimensStatus.filter((it) => it.check)

			specimens
				.filter((it) => it.check)
				.forEach(async (specimen) => {
					//VENDIDO
					if (specimen.new_status_id === 2) {
						specimen.cleanPrice = JSON.parse(JSON.stringify(specimen.price))
						specimen.cleanPrice = justNumbers(specimen.cleanPrice)
					}

					//DONADO, VENDIDO, REGALADO, PRESTADO
					if ([2, 4, 5, 11].includes(specimen.new_status_id)) {
						specimen.type_ouput =
							specimen.new_status_id === 2
								? 1
								: specimen.new_status_id === 4
								? 2
								: specimen.new_status_id === 5
								? 3
								: specimen.new_status_id === 11
								? 4
								: null
					}

					//DEADS
					if (specimen.new_status_id === 3) {
						deads.push(specimen)
					}
				})
			if (deads.length) deads = await setEvidencesBase64(deads)
			let body = {
				created_by: rootGetters["auth/currentUser"].user_id,
				all_status: specimens,
				deads: JSON.stringify(deads),
			}
			await specimenService.saveStatusMassively(body)
		} catch (error) {
			throw error
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
