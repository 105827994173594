<template>
	<div class="Note" ref="note">
		<button class="Note__dragger" ref="dragger">
			<feather-icon class="ml-50 mt-50" icon="MoveIcon" size="16" />
		</button>
		<button class="Note__close" @click="closeNote">
			<feather-icon icon="XIcon" size="16" />
		</button>
		<b-overlay :show="isLoading" :variant="skin">
			<input class="Note__title" v-model="title" placeholder="Titulo de nueva nota" />
			<div class="Note__colors">
				<ColorsRadio v-model="color" />
				<div class="Note__colors__color">
					<div class="Note__colors__color__circle"></div>
				</div>
			</div>
			<quill-editor class="Note__editor" v-model="content" :options="editorOption" />
			<div class="d-flex justify-content-end w-100">
				<b-button class="" variant="primary" v-if="isNew" @click="saveNote">Guardar</b-button>
				<b-button class="" variant="primary" v-else @click="updateNote">Guardar</b-button>
			</div>
		</b-overlay>
	</div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { ref, computed, onMounted } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";

import useAppConfig from "@core/app-config/useAppConfig";
import stickyNotesService from "@/services/header/sticky-notes.service.js";
import useStickyNotes from "@/views/amg/header-bookmarks/sticky-notes/useStickyNotes";

import ColorsRadio from "./ColorsRadio.vue";

export default {
  components: {
    ColorsRadio,
    quillEditor,
  },
  setup() {
    const { activeNote, noteCreatedTrigger } = useStickyNotes();
    const { skin } = useAppConfig();

    // drag and drop
    let note = ref();
    let dragger = ref();
    function handleTouchDragNote() {
      let elNote = note.value;
      let elDrag = dragger.value;
      let pos1 = 0;
      let pos2 = 0;
      let pos3 = 0;
      let pos4 = 0;
      // i firefox set dom.w3c_touch_events.enabled to 1
      elDrag.addEventListener("mousedown", dragMouseDown);
      elDrag.addEventListener("touchstart", dragMouseDown);

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.addEventListener("mouseup", closeDragElement);
        document.addEventListener("mousemove", elementDrag);
        document.addEventListener("touchend", closeDragElement);
        document.addEventListener("touchmove", elementDrag);
      }

      function elementDrag(e) {
        e = e || window.event;
        if ("TouchEvent" in window && e instanceof TouchEvent) {
          e = e.targetTouches[0];
        } else {
          e.preventDefault();
        }
        let rect = note.value.getBoundingClientRect();

        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;

        let finalx = elNote.offsetLeft - pos1;
        let finaly = elNote.offsetTop - pos2;

        if (
          finalx < 0 ||
          finaly < 0 ||
          finalx + rect.width > document.body.clientWidth ||
          finaly + rect.height > document.body.clientHeight
        )
          return;

        elNote.style.left = finalx + "px";
        elNote.style.top = finaly + "px";
      }

      function closeDragElement() {
        document.removeEventListener("mouseup", closeDragElement);
        document.removeEventListener("mousemove", elementDrag);
        document.removeEventListener("touchend", closeDragElement);
        document.removeEventListener("touchmove", elementDrag);
      }
    }
    onMounted(() => handleTouchDragNote());

    // load data
    let isLoading = ref(false);
    let isNew = computed(() => activeNote.id === null);
    async function getNoteContent() {
      isLoading.value = true;
      let body = {
        id: activeNote.id,
      };
      let data = await stickyNotesService.getNote(body);
      title.value = data.data[0].title;
      content.value = data.data[0].content;
      color.value = data.data[0].color_light;
      isLoading.value = false;
    }
    if (!isNew.value) {
      getNoteContent();
    }

    // editor
    let editorOption = {
      theme: "snow",
      placeholder: "Insertar texto aqui...",
      modules: {
        toolbar: [["bold", { list: "bullet" }, { background: [] }, "image"]],
      },
    };
    let title = ref("");
    let content = ref("");
    let color = ref("#ea5455");

    function closeNote() {
      activeNote.id = null;
      activeNote.show = false;
    }

		// note actions
		async function saveNote() {
			if (!!!title.value) {
				this.showToast("danger", "top-right", "Validacion", "XIcon", "Error en titulo");
				return;
			}
			if (!!!content.value) {
				this.showToast("danger", "top-right", "Validacion", "XIcon", "Error en contenido");
				return;
			}
			let body = {
				title: title.value,
				content: content.value,
				color_light: color.value,
			};
			isLoading.value = true;
			let data = await stickyNotesService.createNote(body);
			if (!data.success) console.error(data);
			activeNote.id = data.data[0];
			getNoteContent();
			noteCreatedTrigger.value = Symbol();
		}
		async function updateNote() {
			if (!!!title.value) {
				this.showToast("danger", "top-right", "Validacion", "XIcon", "Error en titulo");
				return;
			}
			if (!!!content.value) {
				this.showToast("danger", "top-right", "Validacion", "XIcon", "Error en contenido");
				return;
			}
			let body = {
				id: activeNote.id,
				title: title.value,
				content: content.value,
				color_light: color.value,
			};
			isLoading.value = true;
			let data = await stickyNotesService.updateNote(body);
			if (!data.success) console.error(data);
			getNoteContent();
			noteCreatedTrigger.value = Symbol();
		}

    return {
      activeNote,
      skin,
      note,
      dragger,
      content,
      editorOption,
      isLoading,
      title,
      color,
      closeNote,
      saveNote,
      updateNote,
      isNew,
    };
  },
};
</script>

<style lang="scss">
.Note {
  position: fixed;
  top: 5rem;
  left: calc(50% - 35vw / 2);
  padding: 2rem;
  padding-top: 3rem;
  width: 35vw;
  min-width: 20vw;
  min-height: 10rem;
  background: var(--darken);
  color: var(--dark);
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.5);
  z-index: 9999;
  resize: both;

  @media screen and (max-width: 1024px) {
    width: 50vw;
  }

  @media screen and (max-width: 768px) {
    top: 1rem;
    left: 1rem;
    width: 80vw;
  }

  &__dragger {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    // background-color: var(--darkest);
    color: var(--secondary);
    background: transparent;
    border: none;
    cursor: all-scroll !important;
    text-align: left;
  }

  &__close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 2rem;
    height: 2rem;
    background: var(--danger);
    color: var(--light);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  &__editor {
    margin-bottom: 2rem;
  }

  &__title {
    background: rgba(167, 167, 167, 0.1);
    padding: 0.8rem 1.5rem;
    border: none;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 1rem;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3);
  }
}

.dark-layout {
  .Note {
    color: var(--light);
  }
}

.quill-editor {
	position: relative !important;
	background: rgba(167, 167, 167, 0.1);
	border-radius: 12px;
	border: none;
	box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3);
	.ql-toolbar {
		position: absolute !important;
		bottom: 0 !important;
		right: 5px !important;
		z-index: 100;
		background: #f6f6f6;
		border: none;
		text-align: right;
		border-top-left-radius: 12px;
		width: 25% !important;
		.ql-formats {
			margin-right: 0;
			// background: green;
			.ql-color-picker {
				// background: red !important;
				// margin-bottom: .2rem;
				display: flex;
				align-items: center;
				justify-content: center;
				.ql-picker-label {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

    @media screen and (max-width: 1024px) {
      width: 50% !important;
    }

    @media screen and (max-width: 768px) {
      width: 70% !important;
    }
  }
  .ql-container {
    .ql-editor {
      height: 400px !important;
      overflow-y: auto !important;
      padding: 2rem !important;
      border: none !important;
      font-size: 1.2rem;
    }
    .ql-editor::-webkit-scrollbar {
      position: relative;
      right: -10px;
      width: 4px !important;
      background: #d5d1fb !important;
    }

    .ql-editor::-webkit-scrollbar-thumb {
      background: #7367f0 !important;
      border-radius: 6px !important;
    }
  }
}
.ql-container.ql-snow {
  border: none !important;
}
</style>
