export default [
	{
		path: "/encaste",
		name: "encaste-sr",
		component: () => import("@/views/amg/encaste/EncasteMain.vue"),
		children: [
			{
				path: "dashboard",
				name: "dashboard-encaste-sr",
				component: () => import("@/views/amg/encaste/dashboard-encaste/DashboardEncaste.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Dashboard Encaste",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "posturas",
				name: "posturas-sr",
				component: () => import("@/views/amg/encaste/posturas/PosturasMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					// breadcrumb: [
					// 	{
					// 		text: "Encaste",
					// 		active: true,
					// 	},
					// ],
					roles: [1, 4, 6],
				},
				redirect: {
					name: "posturas-sr-pendientes",
				},
				children: [
					{
						path: "pendientes",
						name: "posturas-sr-pendientes",
						component: () => import("@/views/amg/encaste/posturas/submodules/PosturasStatusContainer.vue"),
						meta: {
							status: 1,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Posturas",
									active: true,
								},
								{
									text: "Pendientes",
									active: true,
								},
							],
						},
					},
					{
						path: "activas",
						name: "posturas-sr-activas",
						component: () => import("@/views/amg/encaste/posturas/submodules/PosturasStatusContainer.vue"),
						meta: {
							status: 2,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Posturas",
									active: true,
								},
								{
									text: "Activas",
									active: true,
								},
							],
						},
					},
					{
						path: "por-finalizar",
						name: "posturas-sr-por finalizar",
						component: () => import("@/views/amg/encaste/posturas/submodules/PosturasStatusContainer.vue"),
						meta: {
							status: 3,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Posturas",
									active: true,
								},
								{
									text: "Por Finalizar",
									active: true,
								},
							],
						},
					},
					{
						path: "finalizadas",
						name: "posturas-sr-finalizadas",
						component: () => import("@/views/amg/encaste/posturas/submodules/PosturasStatusContainer.vue"),
						meta: {
							status: 4,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Posturas",
									active: true,
								},
								{
									text: "Finalizadas",
									active: true,
								},
							],
						},
					},
					{
						path: "anuladas",
						name: "posturas-sr-anuladas",
						component: () => import("@/views/amg/encaste/posturas/submodules/PosturasStatusContainer.vue"),
						meta: {
							status: 5,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Posturas",
									active: true,
								},
								{
									text: "Anuladas",
									active: true,
								},
							],
						},
					},
					{
						path: "por-padrillos",
						name: "posturas-sr-por-padrillos",
						component: () =>
							import("@/views/amg/encaste/posturas/submodules/PosturasCategoryContainer.vue"),
						meta: {
							category: 1,
							roles: [1, 4, 6],
						},
						redirect: {
							name: "padrillos-con-posturas-sr",
						},
						children: [
							{
								path: "con-posturas",
								name: "padrillos-con-posturas-sr",
								component: () =>
									import("@/views/amg/encaste/posturas/SpecimenPostureReportByCategory.vue"),
								meta: {
									category: 1,
									tab: 1,
									roles: [1, 4, 6],
									pageTitle: "Encaste",
									breadcrumb: [
										{
											text: "Posturas",
											active: true,
										},
										{
											text: "Padrillo",
											active: true,
										},
										{
											text: "Con postura",
											active: true,
										},
									],
								},
							},
							{
								path: "sin-posturas",
								name: "padrillos-sin-posturas-sr",
								component: () =>
									import("@/views/amg/encaste/posturas/SpecimenPostureReportByCategory.vue"),
								meta: {
									category: 1,
									tab: 2,
									roles: [1, 4, 6],
									pageTitle: "Encaste",
									breadcrumb: [
										{
											text: "Posturas",
											active: true,
										},
										{
											text: "Padrillo",
											active: true,
										},
										{
											text: "Sin postura",
											active: true,
										},
									],
								},
							},
						],
					},
					{
						path: "por-madrillas",
						name: "posturas-sr-por-madrillas",
						component: () =>
							import("@/views/amg/encaste/posturas/submodules/PosturasCategoryContainer.vue"),
						meta: {
							category: 2,
						},
						redirect: {
							name: "madrillas-con-posturas-sr",
						},
						children: [
							{
								path: "con-posturas",
								name: "madrillas-con-posturas-sr",
								component: () =>
									import("@/views/amg/encaste/posturas/SpecimenPostureReportByCategory.vue"),
								meta: {
									category: 2,
									tab: 1,
									roles: [1, 4, 6],
									pageTitle: "Encaste",
									breadcrumb: [
										{
											text: "Posturas",
											active: true,
										},
										{
											text: "Madrilla",
											active: true,
										},
										{
											text: "Con postura",
											active: true,
										},
									],
								},
							},
							{
								path: "sin-posturas",
								name: "madrillas-sin-posturas-sr",
								component: () =>
									import("@/views/amg/encaste/posturas/SpecimenPostureReportByCategory.vue"),
								meta: {
									category: 2,
									tab: 2,
									roles: [1, 4, 6],
									pageTitle: "Encaste",
									breadcrumb: [
										{
											text: "Posturas",
											active: true,
										},
										{
											text: "Madrilla",
											active: true,
										},
										{
											text: "Sin postura",
											active: true,
										},
									],
								},
							},
							{
								path: "recomendadas",
								name: "madrillas-recommended-posturas-sr",
								component: () =>
									import("@/views/amg/encaste/posturas/SpecimenPostureReportByCategory.vue"),
								meta: {
									category: 2,
									tab: 3,
									roles: [1, 4, 6],
								},
							},
						],
					},
				],
			},

			{
				path: "insemination",
				name: "insemination-sr",
				component: () => import("@/views/amg/encaste/insemination/InseminationMain.vue"),
				meta: {
					roles: [1, 4, 6],
					// pageTitle: "Inseminación",
					// breadcrumb: [
					// 	{
					// 		text: "Listado",
					// 		active: true,
					// 	},
					// ],
				},
				children: [
					{
						path: "pendientes",
						name: "insemination-sr-pendientes",
						component: () => import("@/views/amg/encaste/posturas/insemination/components/TabHistory.vue"),
						meta: {
							status: 1,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Inseminación",
									active: true,
								},
								{
									text: "Pendientes",
									active: true,
								},
							],
						},
					},
					{
						path: "realizadas",
						name: "insemination-sr-realizadas",
						component: () => import("@/views/amg/encaste/posturas/insemination/components/TabHistory.vue"),
						meta: {
							status: null,
							roles: [1, 4, 6],
							pageTitle: "Encaste",
							breadcrumb: [
								{
									text: "Inseminación",
									active: true,
								},
								{
									text: "Realizadas",
									active: true,
								},
							],
						},
					},
				],
			},
			{
				path: "register-eggs",
				name: "regsiter-eggs",
				component: () => import("@/views/amg/encaste/eggs/RegisterMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Registro de huevos",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "lotes",
				name: "lotes-sr",
				component: () => import("@/views/amg/encaste/lotes/LotesMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Lotes",
							active: true,
						},
					],
					type: 1, // para saber si quiero todos los lotes -> 1 , o por mayor infertilidad -> 2.
					roles: [1, 4, 6],
				},
			},
			{
				path: "pollos",
				name: "pollos-sr",
				component: () => import("@/views/amg/encaste/chickens/ChickensMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Pollos",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "lotes/crear",
				name: "lotes-create-sr",
				component: () => import("@/views/amg/encaste/lotes/LotesCreateDragAndDrop.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Lotes",
							active: false,
						},
						{
							text: "Crear",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "lotes/editar/:lote_id",
				name: "lotes-editar-sr",
				component: () => import("@/views/amg/encaste/lotes/LotesCreateDragAndDrop.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Lotes",
							active: false,
						},
						{
							text: "Editar",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "migracion",
				name: "migracion-sr",
				component: () => import("@/views/amg/encaste/migration/MigrationMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Lista de Migraciones",
					breadcrumb: [
						{
							text: "Migración",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "calendario",
				name: "calendar-sr",
				component: () => import("@/views/amg/encaste/posturas/CalendarPosturasView.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Calendario lotes",
					breadcrumb: [
						{
							text: "Lotes",
							active: false,
							to: "/encaste/lotes",
						},
						{
							text: "Calendario lotes",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "corrales",
				name: "corrales-sr",
				component: () => import("@/views/amg/encaste/corrales/CorralesMain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Encaste",
					breadcrumb: [
						{
							text: "Corrales",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "/encaste/*",
				name: "encaste-not-found",
				redirect: { name: "dashboard-encaste-sr" },
			},
		],
	},
];
