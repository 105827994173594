import { ref, reactive, watch } from "@vue/composition-api"

import tasksService from "@/services/tasks.service.js"
import store from "@/store"

let showTasks = ref(false)
let tasksCounters = reactive({
	pending: 0,
	finished: 0,
	canceled: 0,
})
let counterTrigger = ref(Symbol())
watch(counterTrigger, () => {
	store.state.auth.currentUser && getTotalCounter({ user_id: store.state.auth.currentUser.id })
})

async function getTotalCounter({ user_id }) {

	const { data } = await tasksService.getUserTaskCounters({ user_id })
	tasksCounters.pending = data.find((c) => c.id == 1).counter
	tasksCounters.finished = data.find((c) => c.id == 2).counter
	tasksCounters.canceled = data.find((c) => c.id == 3).counter
}

export default function () {
	return {
		showTasks,
		tasksCounters,
		counterTrigger,
	}
}
