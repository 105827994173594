import types from '@/store/modules/specimens/types';

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.FETCH_SPECIMEN]: (state, payload) => {
		state.specimen = payload;
	},

	[types.CLEAR_SPECIMEN]: (state, payload) => {
		state.specimen = {};
	},
}