const ordersMutations = {
	ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
	INIT_LOCAL_STORAGE: 'INIT_LOCAL_STORAGE',
	REFRESH_LOCAL_STORAGE: 'REFRESH_LOCAL_STORAGE',
	DECREMENT_ITEM_TO_CART: 'DECREMENT_ITEM_TO_CART',
	REMOVE_TOTAL_ITEMS_TO_CART: 'REMOVE_TOTAL_ITEMS_TO_CART',
	REMOVE_ITEM_TO_ARRAY: 'REMOVE_ITEM_TO_ARRAY',
	GET_ITEMS: 'GET_ITEMS', 
	SET_ITEMS: 'SET_ITEMS',
	SET_SEARCH_ITEMS: 'SET_SEARCH_ITEMS',
	SET_PRICE_CART: 'SET_PRICE_CART',
	SET_CATEGORY_SELECT: 'SET_CATEGORY_SELECT',
	SET_ARRAY_CATEGORY: 'SET_ARRAY_CATEGORY',
	RELOAD_CATEGORIES: 'RELOAD_CATEGORIES',
	FILTER_CATEGORY_ARRAY: 'FILTER_CATEGORY_ARRAY'
};

export default Object.freeze({
	...ordersMutations,
});
