<template>
	<b-modal centered :title="title" :hide-footer="info.readonly" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple small sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Fotos</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="pending in pendings" :key="pending.id">
						<b-td>
							<SpecimenPlate :specimen="pending.specimen" />
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center">
								<InputImages single :readonly="info.readonly" v-model="pending.images" />
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="info" @click="save(true)" :disabled="isLoading || !canFinalize">Finalizar</b-button>
			<b-button variant="success" @click="save()" :disabled="isLoading || !hasChanges">Guardar</b-button>
		</template>
		<UpdateTask
			:show="updateTaskModal.show"
			:info="updateTaskModal.info"
			@closing="updateTaskModal.show = false"
			@refresh="$emit('refresh'), handleHidden()"
		/>
	</b-modal>
</template>

<script>
import { getBase64 } from "@/helpers/functions"
import tasksService from "@/services/tasks.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import InputImages from "@/components/commons/InputImages.vue"
import UpdateTask from "./UpdateTask.vue"

export default {
	components: { SpecimenPlate, InputImages, UpdateTask },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		pendings: [],
		cleanPendings: [],
		updateTaskModal: { show: false, info: {} },
	}),
	computed: {
		canFinalize() {
			for (let idx = 0; idx < this.pendings.length; idx++) {
				const p = this.pendings[idx]
				const clean = p.images.filter((i) => i.delete !== true)
				if (clean.length == 0) return false
			}
			return true
		},
		hasChanges() {
			let change = false
			for (let i = 0; i < this.pendings.length; i++) {
				if (change) break
				const cur = this.pendings[i]
				const prev = this.cleanPendings[i]
				if (cur.images.length != prev.images.length) change = true
				for (let j = 0; j < cur.images.length; j++) {
					if (change) break
					const curImg = cur.images[j]
					const prevImg = prev.images[j]
					if (curImg.url != prevImg.url) change = true
					if (curImg.delete != prevImg.delete) change = true
				}
			}
			if (change) {
				return true
			}
			return false
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.title = null
			this.pendings = []
			this.cleanPendings = []
			this.updateTaskModal = { show: false, info: {} }
			this.$emit("closing")
		},
		async getAssignedPendings() {
			this.isLoading = true
			const data = await tasksService.getPendingsByTask({ task_id: this.info.id })
			this.pendings = data.map((s) => {
				let images = []
				if (s.task_image) images.push(s.task_image)
				return { ...s, images }
			})
			this.cleanPendings = this.pendings.map((p) => {
				// se hace doble map a objetos anidados para que las copias no se afecten entre si
				const images = p.images.map((i) => ({ ...i }))
				return { ...p, images }
			})
			this.isLoading = false
		},
		async save(finalize = false) {
			if (!finalize) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se GUARDARA la tarea`,
				})
				if (!isConfirmed) return
			}

			this.isLoading = true
			let data = []
			for (let idx = 0; idx < this.pendings.length; idx++) {
				const pending = this.pendings[idx]
				// verificar si hay modificacion
				const clearImages = pending.images.filter((img) => img.delete !== true)
				let isOld = false
				let base = null
				if (clearImages.length != 0) {
					if (!!clearImages[0].id) {
						isOld = true
					} else {
						base = await getBase64(clearImages[0].file)
					}
				}

				data.push({
					id: pending.specimen.id,
					new: !isOld,
					tasks_users_specimen_id: pending.tasks_users_specimen_id,
					base: base,
				})
			}
			await tasksService.storePendingsData({
				task_user_id: this.info.id,
				type_action: 1,
				specimens: data,
			})
			this.isLoading = false
			!finalize &&
				this.showToast(
					"success",
					"top-right",
					"Tareas",
					"CheckIcon",
					`Se guardaron las imágenes de forma correcta`
				)
			this.$emit("refresh")
			if (finalize) {
				this.updateTaskModal.info = { ...this.info, _status: 2 }
				this.updateTaskModal.show = !this.updateTaskModal.show
			} else {
				this.handleHidden()
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `${this.info.readonly ? "Ver fotos asignadas" : "Asignar fotos"} / Tarea de ${
					this.info.assign.name
				}`
				this.getAssignedPendings()
			}
		},
	},
}
</script>
