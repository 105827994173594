import { ref, reactive } from "@vue/composition-api"

let showNotesList = ref(false)
let activeNote = reactive({
    id: null,
    show: false,
})

let noteCreatedTrigger = ref(Symbol())

export default function () {
    return {
        showNotesList,
        activeNote,
        noteCreatedTrigger,
    }
}