import store from "@/store"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import moment from "moment"
import videosService from "@/services/videos.service"
import { uid } from "uid"

export default {
	computed: {
		skinComputed() {
			return this.$store.getters["appConfig/layout"].skin
		},
		currentBreakPoint() {
			return this.$store.getters["app/currentBreakPoint"]
		},
		borderColorC() {
			return this.skinComputed == "dark" ? "#3B4253" : "#d3c7c7"
		},
		borderLightOrDark() {
			return `border: 1px solid ${this.borderColorC}`
		},
		borderRightLightOrDark() {
			return `border-right: 1px solid ${this.borderColorC}`
		},
		currentUser() {
			return this.$store.state.auth.currentUser || {}
		},
		isAdmin() {
			const isDueñoInRoles = this.roles.filter((rol) => rol == 1)
			if (isDueñoInRoles.length > 0) return true
			return false
		},
		isInvitado() {
			const isInvitadoInRoles = this.roles.filter((rol) => rol == 7)
			if (isInvitadoInRoles.length > 0) return true
			return false
		},
		// isSupervidor() {
		// 	const isSupervisorInRoles = this.roles.filter((rol) => rol == 11) || [];
		// 	if (isSupervisorInRoles.length > 0) return true;
		// 	return false;
		// },
		isEncastador() {
			const isEncastadorInRoles = this.roles.filter((rol) => rol == 6)
			if (isEncastadorInRoles.length > 0) return true
			return false
		},
		isAdministrator() {
			const isEncastadorInRoles = this.roles.filter((rol) => rol == 2) || []
			if (isEncastadorInRoles.length > 0) return true
			return false
		},
		roles() {
			if (this.currentUser.id) {
				return this.currentUser.roles
			}
			return []
		},
		privileges() {
			if (this.currentUser.id) return this.currentUser.privileges
			return []
		},
		isSupervisor() {
			const isUserPrivileges = this.privileges.filter((rol) => rol == 1) || []
			// console.log('fd', isUserPrivileges);
			if (isUserPrivileges.length > 0) return true
			return false
		},
		isPreparador() {
			const hasRole = this.roles.filter((rol) => rol == 5)
			if (hasRole.length > 0) return true
			return false
		},
		isCargos: () => (arrayIds) => {
			let access = true
			// const privilegios = store.state.auth.currentUser.privileges || [];
			// privilegios.forEach((it) => {
			// 	arrayIds.forEach((pr) => {
			// 		if (it == pr) {
			// 			access = true;
			// 		}
			// 	});
			// });
			return access
		},
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		seasons() {
			return this.$store.state.utilities.seasons
		},
		disabledEncaste() {
			return this.$store.state.utilities.disabled
		},
	},
	methods: {
		isPreloading(status = true) {
			// console.log("active", status);
			this.$store.commit("app/M_IS_PRELOADING", status)
		},
		isPreloadingBg(status = true) {
			// console.log("ADD", status);
			this.$store.commit("app/M_IS_PRELOADING_BG", status)
		},

		/* SWALS */
		showInfoSwal({ title = "¿Estás seguro?", text = "¡No podrás revertir esto!", type = "warning" } = {}) {
			return this.$swal(title, text, type)
		},
		showConfirmSwal({ title = "¿Estás seguro?", text = "¡No podrás revertir esto!", html, config = {}, confirmButtonText = "Confirmar", cancelButtonText = "Cancelar" } = {}) {
			return this.$swal({
				title,
				text,
				html,
				icon: "warning",
				showCancelButton: true,
				buttonsStyling: false,
				cancelButtonText,
				confirmButtonText,
				customClass: {
					cancelButton: "btn btn-outline-danger ml-1",
					confirmButton: "btn btn-primary",
				},
				...config,
			})
		}, // return { isConfirmed }
		showErrorSwal(
			error = "",
			title = "Los sentimos, hubo un error... inténtelo otra vez o contacte con soporte !!!",
			icon = "error",
			color = "danger"
		) {
			this.$swal({
				html: `<h4><b>${title}</b></h4> <br/> <span class="font-small-3 text-${color}">${error}</span>`,
				confirmButtonText: "Ok",
				icon: icon,
				customClass: {
					confirmButton: `btn btn-${color}`,
				},
				buttonsStyling: false,
			})
		},

		/* TOASTS */
		showSuccessToast(
			text,
			variant = "success",
			position = "top-right",
			title = "Felicidades!",
			icon = "CheckIcon"
		) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			)
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			)
		},
		// this.showToast(
		// 	"danger",
		// 	"top-right",
		// 	"Error",
		// 	"AlertCircleIcon",
		// 	"Registro Repetido"
		// );
		fixedMoney(money) {
			return Number(money)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		},
		justNumbers(number) {
			return number.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
		},
		seasonFromId(id) {
			return this.$store.state.utilities.seasons.find((season) => season.id == id)
		},
		seasonFromStr(intYear) {
			return this.$store.state.utilities.seasons.find((season) => season.season == String(intYear))
		},
		async loadSeaons() {
			if (this.$store.state.utilities.seasons.length == 0 && this.$store.state.auth.currentUser) {
				await this.$store.dispatch({ type: "utilities/getPeriods" })
				const { end } = this.$store.state.utilities.seasons[0]
				if (moment().format("YYYY-MM-DD") > moment(end).format("YYYY-MM-DD")) {
					this.showToast(
						"warning",
						"top-center",
						"Temporadas",
						"WarningIcon",
						"Es necesario agregar una nueva Temporada."
					)
				}
			}
		},
		slugify(str) {
			return str
				.toLowerCase()
				.trim()
				.replace(/[^\w\s-]/g, "")
				.replace(/[\s_-]+/g, "-")
				.replace(/^-+|-+$/g, "")
		},
		async uploadVideoLaravel(fileVideo, config) {
			let file = fileVideo
			let chunkSize = 10000000

			// console.log(
			//   "e",
			//   await getBase64(document.getElementById("file").files[0])
			// );

			const fileChunks = []
			let offset = 0
			while (offset < file.size) {
				const chunk = file.slice(offset, offset + chunkSize)
				fileChunks.push(chunk)
				offset += chunkSize
			}

			// Enviar cada trozo a través de una petición HTTP
			// let namefile = ;

			// console.log('file', file.name);
			let fileName = file.name
			let fileParts = fileName.split(".")
			let fileExtension = fileParts[fileParts.length - 1]
			fileName = uid(16) + "." + fileExtension
			// console.log(fileExtension); // "pdf"

			// this.setRecursive(fileChunks, fileExtension, fileName);

			// return;
			let url = null
			let statusResponse = 200
			for (let i = 0; i < fileChunks.length; i++) {
				// console.log("blob", fileChunks[i]);
				const formData = new FormData()
				formData.append("file", fileChunks[i])
				formData.append("extension", fileExtension)
				formData.append("name", fileName)
				formData.append("end", i == fileChunks.length - 1)
				formData.append("start", i == 0)
				// formData.append("index", i);
				//   console.log("fileChunks", fileChunks[i]);

				const response = await videosService.setChunks({ videoForm: formData })
				config()
				url = response.url ?? null
				// if (response == 500) {
				// 	const response = await videosService.setChunks({ videoForm: formData })
				// 	config()
				// 	url = response.url ?? null

				// }
				statusResponse = response
				if (statusResponse == 500) break
			}
			return {
				url,
				fileName,
				statusResponse,
			}
		},
	},
}
