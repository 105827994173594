<template>
	<div>
		<b-card>
			<b-tabs
				class="d-flex justify-content-center justify-content-md-start"
				v-model="curTab"
				@input="
					tSearch = null
					getTableData()
				"
			>
				<b-tab class="w-100">
					<template #title>
						<p class="p-50 mb-0 w-100">
							Pendientes
							<b-badge
								class="ml-1"
								variant="danger"
								v-if="counters.pending.count > 0 && counters.pending.show"
							>
								{{ counters.pending.count }}
							</b-badge>
						</p>
					</template>
				</b-tab>
				<b-tab class="w-100">
					<template #title>
						<p class="mb-0 p-50">
							Aceptadas
							<b-badge
								class="ml-1"
								variant="danger"
								v-if="counters.accepted.count > 0 && counters.accepted.show"
							>
								{{ counters.accepted.count }}
							</b-badge>
						</p>
					</template>
				</b-tab>
				<b-tab class="w-100">
					<template #title>
						<p class="mb-0 p-50">
							Rechazadas
							<b-badge
								class="ml-1"
								variant="danger"
								v-if="counters.rejected.count > 0 && counters.rejected.show"
							>
								{{ counters.rejected.count }}
							</b-badge>
						</p>
					</template>
				</b-tab>
			</b-tabs>

			<div
				class="d-flex flex-column align-items-end flex-md-row justify-content-md-between align-items-md-center"
			>
				<span class="text-muted">
					Mostrando {{ pagination.startPage }} a {{ pagination.toPage }} de
					{{ pagination.totalRows }} registros
				</span>
				<div class="d-flex align-items-center">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.totalRows"
						:per-page="pagination.perPage"
						first-number
						last-number
						prev-class="prev-item"
						next-class="next-item"
						class="mt-1 mt-md-50 mb-1 mb-md-50"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
					<FeatherIcon
						@click="sidebarFilterAceppted = !sidebarFilterAceppted"
						v-if="curTab == 1"
						icon="FilterIcon"
						size="20"
						class="ml-1 text-primary cursor-pointer"
					/>
				</div>
			</div>
			<div class="d-flex flex-column flex-md-row w-100 justify-content-md-between mb-2">
				<div class="d-flex flex-column flex-md-row align-items-center justify-content-between w-100">
					<!-- <div class="d-flex flex-column flex-md-row" style="gap: 1rem" v-if="curTab == 1">
						<flat-pickr
							v-model="activeTabInfo.startDate"
							class="form-control bg-white"
							@on-change="getTableData"
						/>
						<flat-pickr
							v-model="activeTabInfo.endDate"
							class="form-control bg-white"
							@on-change="getTableData"
						/>
					</div> -->

					<div v-if="tData.filter((it) => it.check).length > 0" class="d-flex align-items-center">
						<button
							@click="updateMassivelyEggs(2)"
							class="btn btn-success d-flex align-items-center justify-content-center p-50 my-50 px-4"
						>
							<font-awesome-icon
								icon="fa-regular fa-thumbs-up"
								size="xl"
								class="text-white cursor-pointer mr-50"
							/>
							<p class="mb-0">Aceptar</p>
						</button>
						<button
							@click="updateMassivelyEggs(3)"
							class="btn btn-danger d-flex align-items-center justify-content-center ml-1 p-50 my-50 px-4"
						>
							<font-awesome-icon
								icon="fa-regular fa-thumbs-down"
								size="xl"
								class="text-white cursor-pointer mr-50"
							/>
							<p class="mb-0">Rechazar</p>
						</button>
					</div>
				</div>
				<div class="d-flex align-items-center mt-1 mt-md-0" v-if="curTab != 1">
					<b-input-group class="width-input-group">
						<b-form-input @keyup.enter="getTableData" placeholder="Placa o Alias" v-model="tSearch" />
						<b-input-group-append class="">
							<b-button variant="primary" @click="getTableData">
								<FeatherIcon icon="SearchIcon" size="14" class="" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
					<FeatherIcon
						@click="sidebarFilters.show = true"
						icon="FilterIcon"
						size="20"
						class="ml-1 text-primary cursor-pointer"
					/>
				</div>
			</div>
			<b-overlay :show="tLoading">
				<template v-if="curTab == 1">
					<b-table-simple sticky-header style="min-height: 60vh">
						<b-thead>
							<b-tr class="text-center">
								<b-th>
									<div
										@click="
											order == 'asc' ? (order = 'desc') : (order = 'asc')
											getTableData()
										"
										class="cursor-pointer d-flex align-items-center justify-content-center"
									>
										<p class="mb-0">DIA</p>
										<FeatherIcon
											:icon="order == 'desc' ? 'ChevronDownIcon' : 'ChevronUpIcon'"
											size="18"
											class="text-primary cursor-pointer ml-1"
										/>
									</div>
								</b-th>
								<b-th>REGISTRADOS</b-th>
								<b-th>EN POSTURAS</b-th>
								<b-th>DEL AIRE</b-th>
							</b-tr>
						</b-thead>
						<b-thead>
							<b-tr v-for="(data, i) in tData" :key="i" class="text-center">
								<b-td>{{ data.date | myGlobalDayShort }}</b-td>
								<b-td>
									<span
										@click="data.registered > 0 && toogleEggsActiveModal(data.date, 1)"
										:class="[
											data.registered > 0
												? 'text-primary font-weight-bold underline cursor-pointer'
												: '',
										]"
									>
										{{ data.registered }}
									</span>
								</b-td>
								<b-td class="text-center">
									<span
										@click="data.count_posturas > 0 && toogleEggsActiveModal(data.date, 2)"
										:class="[
											data.count_posturas > 0
												? 'text-primary font-weight-bold underline cursor-pointer'
												: '',
										]"
									>
										{{ data.count_posturas }}
									</span>
								</b-td>
								<b-td class="text-center">
									<span
										@click="data.eggs_air > 0 && toogleEggsActiveModal(data.date, 3)"
										:class="[
											data.eggs_air > 0
												? 'text-primary font-weight-bold underline cursor-pointer'
												: '',
										]"
									>
										{{ data.eggs_air }}
									</span>
								</b-td>
							</b-tr>
							<b-tr v-if="tData.length == 0">
								<b-td class="text-center" colspan="4">No hay datos para mostrar.</b-td>
							</b-tr>
						</b-thead>
					</b-table-simple>
				</template>
				<b-table-simple sticky-header style="min-height: 60vh" v-else>
					<!--  -->
					<b-thead>
						<b-tr>
							<b-th
								v-if="curTab == 0 && tData.length > 0 && (isSupervisor || isAdmin)"
								class="text-center"
							>
								<!-- {{ check_massively }} -->
								<div class="d-flex align-items-center justify-content-center">
									<b-form-checkbox
										@change="changeCheckMassively"
										v-model="check_massively"
									></b-form-checkbox>
								</div>
							</b-th>
							<b-th
								v-for="(header, i) in tDataHead"
								:key="i"
								:style="{ width: header == 'madrilla' ? '100px' : 'auto' }"
							>
								<div
									@click="resetORderBy(header)"
									class="d-flex align-items-center justify-content-center"
									:class="[header == 'madrilla' ? 'cursor-pointer' : '']"
								>
									<p class="mb-0 mr-1">{{ header }}</p>
									<FeatherIcon
										v-if="header == 'madrilla'"
										:icon="orderBy == 'asc' ? 'ChevronUpIcon' : 'ChevronDownIcon'"
										size="18"
									/>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(row, i) in tData" :key="i">
							<b-td v-if="curTab == 0 && (isSupervisor || isAdmin)">
								<!-- {{ row.check }} -->
								<div class="d-flex align-items-center justify-content-center">
									<b-form-checkbox v-model="row.check"></b-form-checkbox>
								</div>
							</b-td>
							<b-td>
								<SpecimenPlate :specimen="row.madrilla" />
							</b-td>
							<b-td>
								{{ row.quantity }}
							</b-td>

							<!-- <b-td>
								{{ row.comment ? row.comment : "---" }}
							</b-td> -->
							<b-td v-if="tDataHead.includes('postura')">
								<template v-if="row.postura_found_code">
									{{ row.postura_found_code }}
								</template>
								<template v-else>---</template>
							</b-td>
							<b-td>
								{{ row.created.user }}
								<br />
								{{ row.created.date | myGlobalDayWithHour }}
							</b-td>
							<template v-if="isSupervisor || isAdmin">
								<b-td
									v-if="
										tDataHead.includes('acciones') ||
										tDataHead.includes('registrado por') ||
										tDataHead.includes('rechazado por') ||
										tDataHead.includes('aceptado por')
									"
								>
									<template v-if="curTab == 0">
										<font-awesome-icon
											@click="updateUnitStatus(row.id, 1)"
											icon="fa-regular fa-thumbs-up"
											size="xl"
											class="text-success cursor-pointer"
											v-if="row.postura_found_id"
										/>
										<font-awesome-icon
											@click="openAcceptConfirm(row)"
											icon="fa-regular fa-thumbs-up"
											size="xl"
											class="text-success cursor-pointer"
											v-else
										/>
										<font-awesome-icon
											@click="updateUnitStatus(row.id, 2)"
											icon="fa-regular fa-thumbs-down"
											size="xl"
											class="text-danger ml-1 cursor-pointer"
										/>
									</template>
									<template v-else>
										<div>
											<p class="mb-0">
												{{ row.updated_by.user }}
											</p>
											<span>{{ row.updated_by.date | myGlobalDayWithHour }}</span>
										</div>
									</template>
								</b-td>
							</template>
							<template v-else>
								<b-td v-if="!(curTab == 0)">
									<div>
										<p class="mb-0">
											{{ row.updated_by.user }}
										</p>
										<span>{{ row.updated_by.date | myGlobalDayWithHour }}</span>
									</div>
								</b-td>
							</template>
						</b-tr>
						<b-tr v-if="tData.length == 0">
							<b-td colspan="10" class="text-center">
								<p class="mb-0 font-weight-bold">Sin registros encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-overlay>
		</b-card>
		<ApproveRegisterModal
			:show="infoApproveRegister.show"
			:id="infoApproveRegister.id"
			:type="infoApproveRegister.type"
			@closing="clearReviewRegister"
			@refresh="getTableData"
			:title="infoApproveRegister.title"
		/>

		<b-modal @hide="hideModalTracking" hide-footer v-model="modalTracking.show" :title="modalTracking.title">
			<div>
				<b-table-simple sticky-header class="position-relative">
					<b-thead>
						<b-tr class="text-center">
							<template>
								<b-th class="p-1">Comentario</b-th>
								<b-th class="p-1">USUARIO</b-th>
								<b-th class="p-1">FECHA</b-th>
							</template>
						</b-tr>
					</b-thead>

					<b-tbody>
						<b-tr v-for="(tracking, index) in modalTracking.data" :key="index">
							<b-td class="text-center">
								{{ tracking.comment ? tracking.comment : "---" }}
							</b-td>

							<b-td class="text-center">
								{{ tracking.user_name }}
							</b-td>

							<b-td class="text-center">
								{{ tracking.created_at | myGlobalDayShort }}
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>

		<b-sidebar v-model="sidebarFilters.show" :title="sidebarFilters.title" body-class="p-2 bg-white">
			<b-form-group label="Inicio" class="select-date">
				<flat-pickr
					v-model="date_in"
					class="form-control bg-white"
					placeholder="Seleccionar fecha"
					:config="{ maxDate: date_fn }"
				/>
			</b-form-group>
			<b-form-group label="Fin" class="select-date">
				<flat-pickr
					v-model="date_fn"
					class="form-control bg-white"
					placeholder="Seleccionar fecha"
					:config="{ minDate: date_in }"
				/>
			</b-form-group>
			<div>
				<button
					@click="
						getTableData()
						sidebarFilters.show = false
					"
					class="btn btn-primary w-100"
				>
					Buscar
				</button>
			</div>
			<div>
				<button
					@click="
						refreshFilters()
						sidebarFilters.show = false
					"
					class="mt-1 btn btn-info w-100"
				>
					<v-icon name="gi-broom"></v-icon>
					Limpiar
				</button>
			</div>
		</b-sidebar>

		<b-modal title="Acciones masivas" v-model="modalActionsMassively.show" hide-footer>
			<b-form-group label="Comentario">
				<b-form-textarea
					v-model="modalActionsMassively.comment"
					placeholder="Enter something..."
				></b-form-textarea>
			</b-form-group>
			<div class="d-flex align-items-center justify-content-end">
				<!-- <button class="btn btn-primary" @click="updateMassivelyEggs(2)">Aceptar</button> -->
				<button @click="updateMassivelyEggs(2)" v-if="modalActionsMassively.type == 1" class="btn btn-success">
					Aprobar
				</button>
				<button @click="updateMassivelyEggs(3)" v-if="modalActionsMassively.type == 2" class="btn btn-danger">
					Rechazar
				</button>
			</div>
		</b-modal>

		<EggsActiveModal
			:show="showModalEggActive"
			:info="activeTabInfo.info"
			:type="activeTabInfo.type"
			@closing="showModalEggActive = false"
		/>

		<b-sidebar v-model="sidebarFilterAceppted" title="FILTROS" body-class="p-2 bg-white">
			<b-form-group label="Inicio" class="select-date">
				<flat-pickr
					v-model="activeTabInfo.startDate"
					class="form-control bg-white"
					placeholder="Agregar fecha"
				/>
			</b-form-group>
			<b-form-group label="Fin" class="select-date">
				<flat-pickr v-model="activeTabInfo.endDate" class="form-control bg-white" placeholder="Agregar fecha" />
			</b-form-group>

			<div>
				<button
					@click="
						getTableData()
						sidebarFilterAceppted = false
					"
					class="btn btn-primary w-100"
				>
					Buscar
				</button>
			</div>
			<div>
				<button
					@click="
						activeTabInfo.startDate = null
						activeTabInfo.endDate = null
						sidebarFilterAceppted = false
						getTableData()
					"
					class="mt-1 btn btn-info w-100"
				>
					<v-icon name="gi-broom"></v-icon>
					Limpiar
				</button>
			</div>
		</b-sidebar>
		<b-modal
			hide-footer
			hide-header
			centered
			size="xs"
			v-model="acceptConfirmModal.show"
			@hidden="clearAcceptConfirm"
		>
			<h4 class="text-center mt-2 mb-2">
				¡La madrilla
				<span v-if="getMadrillaByRowId(addPosturaModal.modifiedId)">
					{{ getMadrillaByRowId(addPosturaModal.modifiedId).alias || "---" }} / ({{
						getMadrillaByRowId(addPosturaModal.modifiedId).plate
					}})
				</span>
				no tiene una postura activa!
			</h4>
			<div class="d-flex flex-column justify-content-center gap-x-2 gap-y-2">
				<b-button variant="success" class="btn-eggs-found" @click="acceptConfirmActions(1)">ACEPTAR</b-button>
				<b-button variant="primary" class="btn-eggs-found" @click="acceptConfirmActions(2)">
					ACEPTAR Y CREAR POSTURA
				</b-button>
				<b-button variant="danger" class="btn-eggs-found" @click="acceptConfirmActions(3)">CERRAR</b-button>
			</div>
		</b-modal>
		<!-- eggs_air para bloquear cambio de madrilla, eggs_found para activar postura al crear -->
		<AddPostura
			:placa="addPosturaModal.info"
			madrillaController
			is_eggs_air
			is_eggs_found
			v-if="addPosturaModal.show"
			@hidden=";(addPosturaModal.show = false), (acceptConfirmModal.show = false)"
			@refresh="resumeAfterNewPostura"
		/>
	</div>
</template>

<script>
import { ref, watch, computed, reactive, onMounted } from "@vue/composition-api"
import moment from "moment"
import store from "@/store"

import eggsFoundService from "@/services/header/eggs-found.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ApproveRegisterModal from "./ApproveRegisterModal.vue"
import EggsActiveModal from "./EggsActiveModal.vue"
import AddPostura from "@/views/amg/encaste/posturas/modals/AddPostura.vue"

export default {
	components: { ApproveRegisterModal, SpecimenPlate, EggsActiveModal, AddPostura },
	props: { show: Boolean },
	setup(props, ctx) {
		// handle sidebar model
		let isActive = ref(false)
		watch(
			() => props.show,
			(val) => {
				if (val) {
					isActive.value = true
					getCounters()
					getTableData()
				} else {
					getCounters()
					getTableData()
				}
			}
		)
		function handleHidden() {
			isActive.value = false
			ctx.emit("closing")
		}

		// counters
		let counters = reactive({
			pending: 0,
			accepted: 0,
			rejected: 0,
		})

		const order = ref("desc")

		async function getCounters() {
			let data = await eggsFoundService.getCounters()
			counters.pending = data.data.find((counter) => counter.id == 1)
			counters.accepted = data.data.find((counter) => counter.id == 2)
			counters.rejected = data.data.find((counter) => counter.id == 3)

			store.commit("headers/SET_VALUES_EGGS_FOUND", {
				eggsFound: {
					...store.state.headers.eggsFound,
					counter: counters.pending.count,
				},
			})
		}

		let sidebarFilterAceppted = ref(false)

		let orderBy = ref("asc")
		// table handling
		let check_massively = ref(false)
		let date_fn = ref(null)
		let date_in = ref(null)
		let curTab = ref(0)
		let tLoading = ref(false)
		let tData = ref([])
		let pagination = reactive({
			startPage: 1,
			toPage: 2,
			totalRows: 1,
			curPage: 1,
			perPage: 50,
		})

		let sidebarFilters = ref({
			show: false,
			title: "FILTROS",
		})
		let tSearch = ref("")
		let isSupervisor = computed(() => store.state.auth.currentUser.privileges.includes(1))
		let isAdmin = computed(() => store.state.auth.currentUser.roles.includes(1))
		let tDataHead = computed(() => {
			let row = tData.value[0]
			if (!!!row) return {}
			let heads = Object.keys(row)
			// remove rows
			heads = heads.filter((key) => {
				let hiddenHeads = [
					"id",
					"created_at",
					"postura_code",
					"updated_at",
					"check",
					"updated_by",
					"comment",
					"postura_found_id",
				]
				if (curTab.value != 0) hiddenHeads.push("postura_found_code")
				// if (["id", "created_at", "postura_code", "updated_at", "check", "updated_by", "comment"].includes(key))
				if (hiddenHeads.includes(key)) return false
				return true
			})
			// add rows
			if (curTab.value == 0 ? isSupervisor.value || isAdmin.value : true) {
				if (curTab.value == 1) {
					heads.push("aceptado por")
				} else if (curTab.value == 2) {
					heads.push("rechazado por")
				} else if (curTab.value != 0 || isSupervisor.value || isAdmin.value) {
					heads.push("acciones")
				}
			}

			if ([1, 2].includes(curTab.value)) {
				// heads.push("registrado por");
				// heads.slice(ind, 1);
				// heads.pop();
				// console.log('hd', heads, ind);
			}

			if (curTab.value == 1) {
				heads.splice(heads.length - 2, 0, "postura")
			}

			// change rows label
			heads = heads.map((key) => {
				switch (key) {
					case "quantity":
						return "cantidad"
					case "created":
						return "registrado por"
					case "comment":
						return "comentario"
					case "postura_found_code":
						return "postura"
				}
				return key
			})

			return heads
		})

		async function getTableData() {
			if (curTab.value == 1) {
				getAcceptedTab()
				return
			}
			tLoading.value = true
			let { data } = await eggsFoundService.getAll({
				page: pagination.curPage,
				perpage: pagination.perPage,
				q: curTab.value + 1,
				search: tSearch.value,
				start_date: date_in.value,
				end_date: date_fn.value,
				order: orderBy.value,
			})
			pagination.totalRows = data.total
			pagination.startPage = data.from
			pagination.toPage = data.to
			tData.value = data.data.map((row) => {
				let rMadrilla = row.madrilla
				return {
					id: row.id,
					madrilla: { ...rMadrilla, image: rMadrilla.url },
					quantity: row.quantity,
					comment: row.comment,
					postura_code: row.postura_code,
					postura_found_code: row.postura_found_code,
					postura_found_id: row.postura_found_id,
					created: row.created,
					updated_at: row.updated_at,
					check: false,
					updated_by: row.updated_by,
				}
			})
			await getCounters()
			tLoading.value = false
		}

		watch(
			() => pagination.curPage,
			(val) => getTableData()
		)

		function formatDate(date) {
			return moment(date).format("MM/DD/YY")
		}

		// modals
		let showCreateRegister = ref(false)
		let infoApproveRegister = reactive({
			show: false,
			id: null,
			type: 1,
			title: null,
		})

		let showSidebarList = ref(false)

		showSidebarList.value = true

		let modalTracking = reactive({
			show: false,
			title: null,
			data: [],
		})

		function reviewRegister(id, type, madrilla) {
			infoApproveRegister.id = id
			infoApproveRegister.show = !infoApproveRegister.show
			infoApproveRegister.type = type
			infoApproveRegister.title = `Madrilla / ${madrilla.alias ? madrilla.alias : ""} (${madrilla.plate})`
		}

		function clearReviewRegister(id) {
			infoApproveRegister.id = null
			infoApproveRegister.show = false
		}

		async function openModal(item) {
			const { data } = await eggsFoundService.getTracking({
				id: item.id,
			})
			modalTracking.show = true
			modalTracking.data = data
			modalTracking.title = `Madrilla / ${item.madrilla.alias ? item.madrilla.alias : ""}(${item.madrilla.plate})`
		}
		function hideModalTracking() {
			modalTracking.show = false
			modalTracking.data = []
		}

		async function refreshFilters() {
			date_in.value = null
			date_fn.value = null
			await getTableData()
		}

		function changeCheckMassively() {
			for (let index = 0; index < tData.value.length; index++) {
				tData.value[index].check = check_massively.value
			}
		}

		async function updateMassivelyEggs(status) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			const resp = await eggsFoundService.updateMassively({
				eggs: tData.value.filter((egg) => egg.check).map((it) => ({ ...it, status_id: status, comment: null })),
			})
			await getTableData()
			modalActionsMassively.show = false
			modalActionsMassively.comment = null
			check_massively.value = false
		}

		// modal massively

		let modalActionsMassively = reactive({
			show: false,
			title: null,
			comment: null,
			type: null,
		})

		onMounted(async () => {
			await getTableData()
		})

		async function updateUnitStatus(id, type) {
			//
			const row = tData.value.find((row) => row.id == id)
			if (!addPosturaModal.modifiedId) {
				const { isConfirmed } = await this.showConfirmSwal({
					title: `Se ${type == 1 ? "aceptara" : "rechazara"} el huevo encontrado de ${
						row.madrilla.alias || row.madrilla.plate
					}`,
				})
				if (!isConfirmed) return
			}
			let body = {
				id,
				status_id: type == 1 ? 2 : 3,
				comment: "",
			}
			await eggsFoundService.reviewRegister(body)
			if (type == 1) {
				this.showToast(
					"success",
					"top-right",
					"Huevos Encontrados",
					"AlertIcon",
					"El huevo fue aceptado exitosamente"
				)
			} else {
				this.showToast(
					"success",
					"top-right",
					"Huevos Encontrados",
					"AlertIcon",
					"El huevo fue rechazado exitosamente"
				)
			}
			await getTableData()
		}

		async function resumeAfterNewPostura() {
			// let body = {
			// 	id: addPosturaModal.modifiedId,
			// 	status_id: 2,
			// 	comment: "",
			// }
			// await eggsFoundService.reviewRegister(body)
			addPosturaModal.show = false
			addPosturaModal.info = null
			addPosturaModal.modifiedId = null
			await getTableData()
		}

		function clearAcceptConfirm() {
			addPosturaModal.show = false
			addPosturaModal.info = null
			addPosturaModal.modifiedId = null
		}

		// modal active registered
		async function getAcceptedTab() {
			tLoading.value = true
			const {
				data: { data, total, from, to },
			} = await eggsFoundService.getAccepted({
				start_date: activeTabInfo.startDate ? moment(activeTabInfo.startDate).format("YYYY-MM-DD") : null,
				end_date: activeTabInfo.endDate ? moment(activeTabInfo.endDate).format("YYYY-MM-DD") : null,
				page: pagination.curPage,
				perpage: pagination.perPage,
				order: order.value,
			})

			pagination.totalRows = total
			pagination.startPage = from
			pagination.toPage = to
			pagination.perPage = pagination.perPage

			tData.value = data
			tLoading.value = false
		}
		const showModalEggActive = ref(false)
		const activeTabInfo = reactive({
			startDate: null,
			endDate: null,
			info: null,
			type: null,
		})
		function toogleEggsActiveModal(info, type) {
			activeTabInfo.info = info
			activeTabInfo.type = type
			showModalEggActive.value = !showModalEggActive.value
		}

		const resetORderBy = async (header) => {
			if (header != "madrilla") return
			orderBy.value = orderBy.value == "asc" ? "desc" : "asc"
			await getTableData()
		}

		// Add posturas component
		const addPosturaModal = reactive({
			show: false,
			info: null,
			modifiedId: null,
		})

		const acceptConfirmModal = reactive({
			show: false,
		})

		function openAcceptConfirm(row) {
			addPosturaModal.info = row.madrilla.plate
			addPosturaModal.modifiedId = row.id
			acceptConfirmModal.show = true
		}
		async function acceptConfirmActions(type) {
			//
			switch (type) {
				case 1:
					this.updateUnitStatus(addPosturaModal.modifiedId, 1)
					acceptConfirmModal.show = false
					break
				case 2:
					await this.updateUnitStatus(addPosturaModal.modifiedId, 1)
					addPosturaModal.show = true
					// addPosturaModal.info = row.madrilla.plate
					// addPosturaModal.modifiedId = id
					// acceptConfirmModal.show = false
					break
				case 3:
					acceptConfirmModal.show = false
					break
			}
		}

		function getMadrillaByRowId(id) {
			let row = tData.value.find((row) => row.id == id)
			if (!row) return null
			return row.madrilla
		}

		return {
			isActive,
			handleHidden,
			counters,
			curTab,
			tLoading,
			tData,
			tDataHead,
			pagination,
			tSearch,
			formatDate,
			getTableData,
			showCreateRegister,
			infoApproveRegister,
			reviewRegister,
			clearReviewRegister,
			modalTracking,
			openModal,
			hideModalTracking,
			date_in,
			date_fn,
			sidebarFilters,
			refreshFilters,
			showSidebarList,
			check_massively,
			changeCheckMassively,
			updateMassivelyEggs,
			modalActionsMassively,
			updateUnitStatus,
			showModalEggActive,
			activeTabInfo,
			toogleEggsActiveModal,
			resetORderBy,
			orderBy,
			sidebarFilterAceppted,
			order,
			addPosturaModal,
			resumeAfterNewPostura,
			acceptConfirmModal,
			openAcceptConfirm,
			acceptConfirmActions,
			getMadrillaByRowId,
			clearAcceptConfirm,
		}
	},
}
</script>
<style>
.btn-eggs-found {
	font-size: 12px !important;
	font-weight: bold !important;
}
</style>
