import itemsService from "@/services/items.service";
import store from "@/store";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async getNotifications({ commit }, jsonSearchItems) {

		// store.commit('app/M_IS_PRELOADING')
		// store.commit('appConfig/TOGGLE_RTL', val)

		// store.dispatch("app/toggleLoading", true);

		const {
			message,
			data: { data, from, last_page, to, total, current_page },
		} = await itemsService.getTotalItem(jsonSearchItems);
		commit(types.GET_ITEMS, {
			from,
			to,
			total,
			data,
			current_page
		});

		// commit(types.GET_NOTIFICATIONS, data);
	},
};
