import SpecimenService from "@/services/specimens.service";
import { deleteImageGallery } from "@/views/amg/header-bookmarks/service.header-book.js";
import store from "@/store";

export const A_SAVE_PHOTO = async ({}, specimen) => {
	try {
		const image = specimen.file;
		const formData = new FormData();
		formData.append("file", image, image.data);
		formData.append("id_specimens", specimen.specimen_id);
		formData.append("user_id", store.getters["auth/currentUser"].user_id);
		formData.append("statusTope", false);
		formData.append("feature", 1);
		const { data } = await SpecimenService.addImageBy(formData);
		return data;
	} catch (error) {
		throw error;
	}
};

export const A_DELETE_PHOTO = async ({}, specimen) => {
	try {
		await deleteImageGallery({
			specimen_id: specimen.specimen_id,
			id: specimen.new_photo_id,
		});
	} catch (error) {
		throw error;
	}
};
