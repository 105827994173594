const tournamentMutation = {
	FILL_TOURNAMENT: 'FILL_TOURNAMENT',
	FAILURE_FETCH_TOURNAMENT: 'FAILURE_FETCH_TOURNAMENT',
	FETCH_COLISEUM: 'FETCH_COLISEUM',
	FETCH_TOURNAMENT_CONTACTS: 'FETCH_TOURNAMENT_CONTACTS',
	FETCH_TOURNAMENT_BANKS: 'FETCH_TOURNAMENT_BANKS',

	DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
	ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',

	DELETE_CONTACT: 'DELETE_CONTACT',
	ADD_CONTACT: 'ADD_CONTACT',

	FETCH_TOURNAMENT_FILES: 'FETCH_TOURNAMENT_FILES',
	DELETE_TOURNAMENT_FILE: 'DELETE_TOURNAMENT_FILE',
	ADD_TOURNAMENT_FILES: 'ADD_TOURNAMENT_FILES',

	DELETE_ORGANIZERS: 'DELETE_ORGANIZERS',

	FETCH_TOURNAMENT_DATE: 'FETCH_TOURNAMENT_DATE',
	FETCH_TOURNAMENT_DATE_PENDING: 'FETCH_TOURNAMENT_DATE_PENDING',

	FETCH_TOURNAMENT_STAGE: 'FETCH_TOURNAMENT_STAGE',
	FETCH_TOURNAMENT_STAGE_PENDING: 'FETCH_TOURNAMENT_STAGE_PENDING',

	ALL_THE_AWARD_OF_THE_YEAR: 'ALL_THE_AWARD_OF_THE_YEAR'
};

export default Object.freeze({
	...tournamentMutation,
});
