Object.defineProperty(exports, '__esModule', { value: true })

function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex.default : ex
}

const _mergeJSXProps = _interopDefault(
  require('babel-helper-vue-jsx-merge-props'),
)

const Calendarv1Icon = {
  name: 'Calendarv2Icon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            id:"Layer_1",
            version:"1.1",
            xmlns:"http://www.w3.org/2000/svg", 
             x:"0px",
             y:"0px",
             viewBox:"0 0 500.004 500.004",
             style:"enable-background:new 0 0 500.004 500.004;",
             xml:space="preserve",
          },
          class: 'feather feather-calendarv1',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M427.848,65.122c-0.488-0.208-1.02-0.324-1.58-0.324h-3.628c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4 c21.588,0,34.492,20.444,34.492,40.204v260.584c0,21.532-13.86,36-34.492,36H81.044c-21.408,0-38.18-15.812-38.18-36V113.002 c0-21.416,17.844-40.204,38.18-40.204h27.552c2.208,0,4-1.792,4-4c0-2.208-1.792-4-4-4H81.044 c-25.032,0-46.18,22.076-46.18,48.204v260.584c0,24.672,20.284,44,46.18,44H422.64c25.02,0,42.492-18.092,42.492-44V113.002 C465.132,90.894,451.3,68.082,427.848,65.122z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M372.032,64.802H127.968c-2.208,0-4,1.792-4,4s1.792,4,4,4h244.064c2.208,0,4-1.792,4-4S374.24,64.802,372.032,64.802z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M418.52,64.802h-27.116c-2.208,0-4,1.792-4,4s1.792,4,4,4h27.116c2.208,0,4-1.792,4-4S420.728,64.802,418.52,64.802z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M364.284,122.914H42.74c-2.208,0-4,1.792-4,4s1.792,4,4,4h321.544c2.212,0,4-1.792,4-4S366.492,122.914,364.284,122.914z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M461.132,122.914h-54.236c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.236c2.208,0,4-1.792,4-4S463.34,122.914,461.132,122.914z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M325.544,169.398h-54.236c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h54.236c2.208,0,4-1.792,4-4 C329.544,171.19,327.752,169.398,325.544,169.398z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M325.544,293.366h-54.236c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.236c2.208,0,4-1.792,4-4S327.752,293.366,325.544,293.366z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M325.544,355.35h-54.236c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.236c2.208,0,4-1.792,4-4S327.752,355.35,325.544,355.35z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M422.396,169.398h-54.24c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h54.24c2.208,0,4-1.792,4-4 C426.396,171.19,424.604,169.398,422.396,169.398z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M422.396,231.382h-54.24c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h54.24c2.208,0,4-1.792,4-4 C426.396,233.174,424.604,231.382,422.396,231.382z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M422.396,293.366h-54.24c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.24c2.208,0,4-1.792,4-4S424.604,293.366,422.396,293.366z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M422.396,355.35h-54.24c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.24c2.208,0,4-1.792,4-4S424.604,355.35,422.396,355.35z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M131.84,169.398H81.48c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h50.36c2.212,0,4-1.792,4-4 C135.84,171.19,134.048,169.398,131.84,169.398z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M131.84,293.366H81.48c-2.208,0-4,1.792-4,4s1.792,4,4,4h50.36c2.212,0,4-1.792,4-4S134.048,293.366,131.84,293.366z',
            fill: 'currentColor',
          },    
        }),     
        h('path', {
          attrs: {
            d: 'M131.84,355.35H81.48c-2.208,0-4,1.792-4,4s1.792,4,4,4h50.36c2.212,0,4-1.792,4-4S134.048,355.35,131.84,355.35z',
            fill: 'currentColor',
          },    
        }),  
        h('path', {
          attrs: {
            d: 'M228.692,169.398h-54.236c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h54.236c2.212,0,4-1.792,4-4 C232.692,171.19,230.9,169.398,228.692,169.398z',
            fill: 'currentColor',
          },    
        }),  
        h('path', {
          attrs: {
            d: 'M228.692,231.382h-54.236c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h54.236c2.212,0,4-1.792,4-4 C232.692,233.174,230.9,231.382,228.692,231.382z',
            fill: 'currentColor',
          },    
        }), 
        h('path', {
          attrs: {
            d: 'M228.692,293.366h-54.236c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.236c2.212,0,4-1.792,4-4S230.9,293.366,228.692,293.366z',
            fill: 'currentColor',
          },    
        }),   
        h('path', {
          attrs: {
            d: 'M228.692,355.35h-54.236c-2.208,0-4,1.792-4,4s1.792,4,4,4h54.236c2.212,0,4-1.792,4-4S230.9,355.35,228.692,355.35z',
            fill: 'currentColor',
          },    
        }),   
        h('path', {
          attrs: {
            d: 'M118.284,39.802c-7.544,0-13.684,6.148-13.684,13.704v34.876c0,7.556,6.136,13.704,13.684,13.704 s13.684-6.148,13.684-13.704V53.506C131.968,45.95,125.828,39.802,118.284,39.802z M123.968,88.382 c0,3.144-2.552,5.704-5.684,5.704c-3.132,0-5.684-2.56-5.684-5.704V53.506c0-3.144,2.548-5.704,5.684-5.704 c3.132,0,5.684,2.556,5.684,5.704V88.382z',
            fill: 'currentColor',
          },    
        }),  
        h('path', {
          attrs: {
            d: 'M381.716,39.802c-7.544,0-13.684,6.148-13.684,13.704v34.876c0,7.556,6.14,13.704,13.684,13.704 c7.544,0,13.688-6.148,13.688-13.704V53.506C395.404,45.95,389.264,39.802,381.716,39.802z M387.404,88.382 c0,3.144-2.552,5.704-5.688,5.704s-5.684-2.56-5.684-5.704V53.506c0-3.144,2.548-5.704,5.684-5.704s5.688,2.556,5.688,5.704 V88.382z',
            fill: 'currentColor',
          },    
        }), ,
        h('path', {
          attrs: {
            d: 'M434.312,64.802h-23.536c-2.208,0-4,1.792-4,4s1.792,4,4,4h23.536c31.272,0,57.692,27.532,57.692,60.124v263.048 c0,31.528-25.344,56.228-57.692,56.228H69.652C36.232,452.202,8,426.454,8,395.974V132.926c0-31.468,29.384-60.124,61.652-60.124 h15.704c2.208,0,4-1.792,4-4s-1.792-4-4-4H69.652C33.196,64.802,0,97.27,0,132.926v263.048c0,34.816,31.9,64.228,69.652,64.228 h364.656c36.836,0,65.692-28.212,65.696-64.228V132.926C500.004,95.998,469.92,64.802,434.312,64.802z',
            fill: 'currentColor',
          },    
        }),  



      ],
    )
  },
}

const Calendarv2Icon = {
  name: 'Calendarv2Icon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            id:"Layer_1",
            version:"1.1",
            xmlns:"http://www.w3.org/2000/svg", 
             x:"0px",
             y:"0px",
             viewBox:"0 0 500 500",
             style:"enable-background:new 0 0 500 500;",
             xml:space="preserve",
          },
          class: 'feather feather-calendarv1',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M251.952,289.572c-4.768,0-9.86,3.084-9.86,11.744v50.248c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4v-50.248 c0-3.744,1.256-3.744,1.86-3.744s1.856,0,1.856,3.744v50.248c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4v-50.248 C261.808,292.656,256.72,289.572,251.952,289.572z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M128.956,156.232H70.384c-2.212,0-4,1.792-4,4s1.788,4,4,4h58.568c2.212,0,4.004-1.792,4.004-4 S131.168,156.232,128.956,156.232z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M128.956,218.708H70.384c-2.212,0-4,1.792-4,4c0,2.208,1.788,4,4,4h58.568c2.212,0,4.004-1.792,4.004-4 C132.956,220.5,131.168,218.708,128.956,218.708z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M128.956,281.184H70.384c-2.212,0-4,1.792-4,4s1.788,4,4,4h58.568c2.212,0,4.004-1.792,4.004-4 S131.168,281.184,128.956,281.184z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M128.956,343.66H70.384c-2.212,0-4,1.792-4,4s1.788,4,4,4h58.568c2.212,0,4.004-1.792,4.004-4 S131.168,343.66,128.956,343.66z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M455.46,42.992H48.28C22.564,42.992,0,64.876,0,89.82v278.076c0,25.068,22.108,46.244,48.28,46.244h80.672 c2.212,0,4-1.792,4-4s-1.788-4-4-4H48.28C26.444,406.14,8,388.624,8,367.896V89.82c0-20.684,18.824-38.828,40.28-38.828h407.18 c20.488,0,36.54,17.056,36.54,38.828v278.072c0,21.444-16.052,38.244-36.54,38.244h-80.508c-2.212,0-4,1.792-4,4s1.788,4,4,4 h80.508c24.976,0,44.54-20.312,44.54-46.24V89.82C500,63.56,480.436,42.992,455.46,42.992z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M382.76,89.848H11.808c-2.212,0-4,1.792-4,4c0,2.208,1.788,4,4,4H382.76c2.212,0,4-1.792,4-4 C386.76,91.64,384.972,89.848,382.76,89.848z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M492.092,89.848H433.52c-2.212,0-4,1.792-4,4c0,2.208,1.788,4,4,4h58.572c2.212,0,4-1.792,4-4 C496.092,91.64,494.304,89.848,492.092,89.848z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M74.284,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4s4-1.792,4-4V27.468 C78.284,25.26,76.496,23.468,74.284,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M132.86,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4V27.468 C136.86,25.26,135.072,23.468,132.86,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M191.428,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4V27.468 C195.428,25.26,193.64,23.468,191.428,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M250,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4V27.468 C254,25.26,252.212,23.468,250,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M308.572,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.208,0,4-1.792,4-4V27.468 C312.572,25.26,310.784,23.468,308.572,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M367.144,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.212,0,4-1.792,4-4V27.468 C371.144,25.26,369.356,23.468,367.144,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M425.716,23.468c-2.212,0-4,1.792-4,4v39.048c0,2.208,1.788,4,4,4c2.208,0,4-1.792,4-4V27.468 C429.716,25.26,427.928,23.468,425.716,23.468z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M226.572,156.232h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.208,0,4-1.792,4-4S228.784,156.232,226.572,156.232z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M226.572,218.708h-54.664c-2.212,0-4,1.792-4,4c0,2.208,1.788,4,4,4h54.664c2.208,0,4-1.792,4-4 C230.572,220.5,228.784,218.708,226.572,218.708z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M328.092,156.232h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.212,0,4-1.792,4-4S330.304,156.232,328.092,156.232z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M328.092,218.708h-54.664c-2.212,0-4,1.792-4,4c0,2.208,1.788,4,4,4h54.664c2.212,0,4-1.792,4-4 C332.092,220.5,330.304,218.708,328.092,218.708z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M328.092,156.232h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.212,0,4-1.792,4-4S330.304,156.232,328.092,156.232z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M429.616,156.232h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.212,0,4-1.792,4-4S431.828,156.232,429.616,156.232z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M429.616,281.184h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.212,0,4-1.792,4-4S431.828,281.184,429.616,281.184z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M429.616,343.66h-54.664c-2.212,0-4,1.792-4,4s1.788,4,4,4h54.664c2.212,0,4-1.792,4-4S431.828,343.66,429.616,343.66z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M251.984,250.168c-62.408,0-113.18,50.772-113.18,113.18c0,62.412,50.772,113.184,113.18,113.184 c62.412,0,113.184-50.776,113.184-113.184S314.396,250.168,251.984,250.168z M251.984,468.532 c-57.996,0-105.18-47.188-105.18-105.184c0-57.996,47.184-105.18,105.18-105.18c58,0,105.184,47.18,105.184,105.18 S309.984,468.532,251.984,468.532z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M251.984,265.776c-53.804,0-97.576,43.772-97.576,97.572s43.776,97.572,97.576,97.572c53.804,0,97.58-43.768,97.58-97.572 C349.564,309.544,305.788,265.776,251.984,265.776z M251.984,452.92c-49.392,0-89.576-40.18-89.576-89.572 c0-49.392,40.184-89.572,89.576-89.572c49.396,0,89.58,40.184,89.58,89.572C341.564,412.74,301.38,452.92,251.984,452.92z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M251.984,347.54c-8.716,0-15.804,7.092-15.804,15.808s7.088,15.808,15.804,15.808c8.72,0,15.812-7.092,15.812-15.808 S260.704,347.54,251.984,347.54z M251.984,371.156c-4.304,0-7.804-3.5-7.804-7.808c0-4.308,3.5-7.808,7.804-7.808 c4.308,0,7.812,3.504,7.812,7.808C259.796,367.656,256.292,371.156,251.984,371.156z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M314.452,351.468h-52.736c-2.212,0-4,1.792-4,4s1.788,4,4,4h52.736c2.064,0,3.744,1.708,3.744,3.808 c0,2.104-1.68,3.812-3.744,3.812h-52.736c-2.212,0-4,1.792-4,4s1.788,4,4,4h52.732c6.476,0,11.748-5.3,11.748-11.812 S320.928,351.468,314.452,351.468z',
            fill: 'currentColor',
          },
        }),
        //
        
      




      ],
    )
  },
}

const Calendarv3Icon = {
  name: 'Calendarv3Icon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            id:"Layer_1",
            version:"1.1",
            xmlns:"http://www.w3.org/2000/svg", 
             x:"0px",
             y:"0px",
             viewBox:"0 0 500 500",
             style:"enable-background:new 0 0 500 500;",
             xml:space="preserve",
          },
          class: 'feather feather-calendarv3',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M468.664,52.716c-2.208,0-4,1.788-4,4v316.192H35.332V56.716c0-2.212-1.792-4-4-4c-2.208,0-4,1.788-4,4v320.192 c0,2.212,1.792,4,4,4h437.336c2.208,0,4-1.792,3.996-4V56.716C472.664,54.504,470.872,52.716,468.664,52.716z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M484.288,21.476H15.716c-2.208,0-4,1.788-4,4v31.24c0,2.212,1.792,4,4,4H414c2.208,0,4-1.788,4-4c0-2.212-1.792-4-4-4 H19.716v-23.24h460.568v23.24h-31.14c-2.208,0-4,1.788-4,4c0,2.212,1.792,4,4,4h35.14c2.212,0,4-1.792,4.004-4v-31.24 C488.288,23.264,486.496,21.476,484.288,21.476z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M496,21.476H4c-2.208,0-4,1.788-4,4c0,2.212,1.792,4,4,4h492c2.208,0,4-1.788,4-4C500,23.264,498.208,21.476,496,21.476z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M250,1.952c-2.208,0-4,1.788-4,4v15.62c0,2.212,1.792,4,4,4s4-1.792,4-4.004V5.952C254,3.74,252.208,1.952,250,1.952z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M250,372.908c-2.208,0-4,1.788-4,4v117.14c0,2.212,1.792,4,4,4s4-1.792,4-4v-117.14 C254,374.696,252.208,372.908,250,372.908z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M363.24,490.048H136.76c-2.208,0-4,1.788-4,4c0,2.212,1.792,4,4,4h226.48c2.208,0,4-1.788,4-4 C367.24,491.836,365.448,490.048,363.24,490.048z',
            fill: 'currentColor',
          },
        }),

        //

        

      ],
    )
  },
}

const Calendarv4Icon = {
  name: 'Calendarv4Icon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            id:"Layer_1",
            version:"1.1",
            xmlns:"http://www.w3.org/2000/svg", 
             x:"0px",
             y:"0px",
             viewBox:"0 0 500 500",
             style:"enable-background:new 0 0 500 500;",
             xml:space="preserve",
          },
          class: 'feather feather-calendarv3',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M421.918,66.108h-74.24c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h70.236v390.54H85.986V74.108h66.332 c2.208,0,4-1.792,4-4c0-2.208-1.792-4-4-4H81.986c-2.208,0-4,1.792-4,4v398.54c0,2.208,1.792,4,4,4h339.928 c2.212,0,4-1.792,4.004-4V70.108C425.918,67.9,424.126,66.108,421.918,66.108z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M427.73,46.576h-80.052c-2.208,0-4,1.792-4,4s1.792,4,4,4h80.052c14.456,0,25.356,11.692,25.356,27.192v382.26 c0,15.684-11.136,27.972-25.356,27.972H76.078c-15.264,0-29.164-13.332-29.164-27.972V81.768 c0-14.484,13.628-27.192,29.164-27.192h76.24c2.208,0,4-1.792,4-4s-1.792-4-4-4h-76.24c-19.8,0-37.164,16.444-37.164,35.192 v382.26c0,19.164,17.368,35.972,37.164,35.972H427.73c18.7,0,33.352-15.8,33.356-35.972V81.768 C461.086,61.704,446.746,46.576,427.73,46.576z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M251.902,15.08c-8.672,0-15.724,7.052-15.724,15.72c0,8.672,7.056,15.724,15.724,15.724c8.668,0,15.72-7.052,15.72-15.724 C267.622,22.132,260.57,15.08,251.902,15.08z M251.902,38.524c-4.26,0-7.724-3.468-7.724-7.724s3.468-7.72,7.724-7.72 c4.26,0,7.72,3.46,7.72,7.72S256.162,38.524,251.902,38.524z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M318.01,19.224h-37.256C276.102,7.448,265.078,0,251.902,0c-13.18,0-24.2,7.452-28.856,19.224h-37.728 c-20.056,0-37,15.968-37,34.868V85.74c0,2.208,1.792,4,4,4h195.36c2.208,0,4-1.792,3.996-4V54.092 C351.674,34.216,337.202,19.224,318.01,19.224z M156.318,81.74V54.092c0-14.312,13.552-26.868,29-26.868h40.584 c1.776,0,3.34-1.176,3.84-2.884C232.63,14.416,241.326,8,251.902,8c10.576,0,19.272,6.416,22.16,16.34 c0.496,1.708,2.06,2.884,3.84,2.884h40.112c14.632,0,25.664,11.548,25.664,26.868V81.74H156.318z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M382.846,187.236h-160.2c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h160.2c2.208,0,4-1.792,4-4 C386.846,189.028,385.054,187.236,382.846,187.236z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M269.534,249.752h-46.888c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h46.888c2.208,0,4-1.792,4-4 S271.742,249.752,269.534,249.752z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M382.846,249.752h-78.144c-2.208,0-4,1.792-4,4c0,2.208,1.792,4,4,4h78.144c2.208,0,4-1.792,4-4 S385.054,249.752,382.846,249.752z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M382.846,374.784h-46.888c-2.208,0-4,1.792-4,4s1.792,4,4,4h46.888c2.208,0,4-1.792,4-4S385.054,374.784,382.846,374.784z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M300.79,374.784h-78.144c-2.208,0-4,1.792-4,4s1.792,4,4,4h78.144c2.208,0,4-1.792,4-4S302.998,374.784,300.79,374.784z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M382.846,312.268h-160.2c-2.208,0-4,1.792-4,4s1.792,4,4,4h160.2c2.208,0,4-1.792,4-4S385.054,312.268,382.846,312.268z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M191.938,164.404c-1.564-1.564-4.092-1.564-5.656,0l-32.528,32.528l-16.488-16.488c-1.564-1.564-4.092-1.564-5.656,0 c-1.564,1.564-1.564,4.092,0,5.656l19.316,19.316c0.78,0.78,1.804,1.172,2.828,1.172c1.024,0,2.048-0.392,2.828-1.172 l35.356-35.356C193.502,168.496,193.502,165.968,191.938,164.404z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M191.938,226.704c-1.564-1.564-4.092-1.564-5.656,0l-32.528,32.528l-16.488-16.488c-1.564-1.564-4.092-1.564-5.656,0 c-1.564,1.564-1.564,4.092,0,5.656l19.316,19.316c0.78,0.78,1.804,1.172,2.828,1.172c1.024,0,2.048-0.388,2.828-1.172 l35.356-35.356C193.502,230.796,193.502,228.268,191.938,226.704z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M191.938,289.148c-1.564-1.564-4.092-1.564-5.656,0l-32.528,32.528l-16.488-16.488c-1.564-1.564-4.092-1.564-5.656,0 c-1.564,1.564-1.564,4.092,0,5.656l19.316,19.316c0.78,0.78,1.804,1.172,2.828,1.172c1.024,0,2.048-0.392,2.828-1.172 l35.356-35.356C193.502,293.24,193.502,290.712,191.938,289.148z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'M191.938,351.724c-1.564-1.564-4.092-1.564-5.656,0l-32.528,32.528l-16.488-16.488c-1.564-1.564-4.092-1.564-5.656,0 c-1.564,1.564-1.564,4.092,0,5.656l19.316,19.316c0.78,0.78,1.804,1.172,2.828,1.172c1.024,0,2.048-0.392,2.828-1.172 l35.356-35.356C193.502,355.816,193.502,353.288,191.938,351.724z',
            fill: 'currentColor',
          },
        }),
      ],
    )
  },
}

const QuoteLEft = {
  name: 'QuoteLEft',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            width:"24",
            height:"24",
            xmlns:"http://www.w3.org/2000/svg", 
            fillRule:"evenodd", 
            clipRule:"evenodd",
          },  
          class: 'feather feather-quote-left',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M9 3c-5.252 1.039-9 4.905-9 10.609v7.391h9.983v-10h-3.983c0-2.211 1.563-4.932 3.996-5.849l-.996-2.151zm14 0c-5.252 1.039-8.983 4.905-8.983 10.609v7.391h9.983v-10h-4c0-2.211 1.563-4.932 3.995-5.849l-.995-2.151zm-.567 1.156l.241.52c-2.394 1.391-3.674 4.159-3.674 6.324v1h4v8h-7.983v-6.391c0-4.687 2.82-8.248 7.416-9.453m-14-.001l.241.521c-2.394 1.391-3.674 4.159-3.674 6.324v1h3.983v8h-7.983v-6.391c0-4.686 2.827-8.247 7.433-9.454',
            fill: 'currentColor',
          },
        }),


      ],
    )
  },
}

const CalendarCup = {
  name: 'CalendarCupIcon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            version: "1.1",
            id:"Capa_1",
            xmlns:"http://www.w3.org/2000/svg",
            xmlns:xlink="http://www.w3.org/1999/xlink",
            x:"0px",
            y:"0px",
            viewBox:"0 0 348.27 348.27",
            style:"enable-background:new 0 0 348.27 348.27;",
            xml:space="preserve"
          },
          class: 'feather feather-calendarv3',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'm504.102,70.943c-4.455-4.477-10.379-6.943-16.682-6.943h-88.435v-18.234c9.29-3.138 16-11.93 16-22.266 0-12.958-10.542-23.5-23.5-23.5h-272c-12.958,0-23.5,10.542-23.5,23.5 0,10.336 6.71,19.128 16,22.266v18.234h-88.405c-6.303,0-12.227,2.466-16.682,6.943-4.471,4.493-6.915,10.457-6.883,16.793 0.228,44.947 12.27,88.467 34.824,125.855 22.548,37.377 53.563,65.318 89.692,80.803 0.745,0.319 1.513,0.506 2.279,0.575 20.131,40.648 58.96,70.45 105.176,78.097v74.934h-40.5c-12.958,0-23.5,10.542-23.5,23.5v32c0,4.143 3.358,7.5 7.5,7.5h160c4.142,0 7.5-3.357 7.5-7.5v-32c0-12.958-10.542-23.5-23.5-23.5h-40.5v-74.935c46.215-7.646 85.043-37.448 105.175-78.095 0.776-0.067 1.554-0.253 2.309-0.576 36.129-15.484 67.145-43.425 89.693-80.803 22.554-37.388 34.595-80.908 34.823-125.855 0.031-6.336-2.413-12.3-6.884-16.793zm-384.617-55.943h272c4.687,0 8.5,3.813 8.5,8.5s-3.813,8.5-8.5,8.5h-272c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5zm-71.802,190.843c-21.158-35.073-32.454-75.94-32.668-118.183-0.012-2.315 0.882-4.495 2.517-6.138 1.617-1.626 3.766-2.522 6.048-2.522h88.406v152.5c0,15.16 2.371,29.776 6.748,43.504-28.246-14.946-52.636-38.633-71.051-69.161zm280.302,265.657v24.5h-145v-24.5c0-4.687 3.813-8.5 8.5-8.5h128c4.687,0 8.5,3.813 8.5,8.5zm-64-23.5h-17v-73.259c2.813,0.165 5.646,0.259 8.5,0.259s5.687-0.094 8.5-0.259v73.259zm-8.5-88c-70.855,0-128.5-57.645-128.5-128.5v-184.5h257v184.5c0,70.855-57.645,128.5-128.5,128.5zm207.833-154.157c-18.424,30.541-42.826,54.234-71.087,69.18 4.381-13.734 6.754-28.355 6.754-43.522v-152.501h88.435c2.282,0 4.43,0.896 6.048,2.522 1.635,1.643 2.528,3.822 2.517,6.137-0.214,42.244-11.51,83.111-32.667,118.184z',
            fill: 'currentColor',
          },
        }),
        h('path', {
          attrs: {
            d: 'm332.091,151.657h-44.81l-13.848-42.616c-2.573-7.922-9.618-13.041-17.947-13.041-8.329,0-15.375,5.119-17.949,13.041l-13.847,42.616h-44.81c-8.33,0-15.375,5.119-17.949,13.041-2.574,7.922 0.118,16.205 6.856,21.1l36.251,26.339-13.847,42.617c-2.574,7.922 0.117,16.204 6.856,21.1 3.37,2.448 7.231,3.672 11.093,3.672s7.724-1.224 11.093-3.672l36.252-26.338 36.252,26.338c6.739,4.896 15.447,4.896 22.186,0 6.739-4.896 9.43-13.178 6.856-21.101l-13.847-42.616 36.252-26.339c6.738-4.896 9.429-13.178 6.855-21.1-2.573-7.922-9.619-13.041-17.948-13.041zm2.277,22.006l-40.66,29.542c-2.629,1.91-3.729,5.295-2.725,8.385l15.531,47.799c0.785,2.416-0.723,3.833-1.407,4.33-0.684,0.497-2.498,1.493-4.552,0l-40.661-29.542c-2.628-1.909-6.188-1.91-8.816,0l-40.661,29.542c-2.053,1.493-3.868,0.498-4.552,0-0.685-0.498-2.192-1.915-1.407-4.33l15.531-47.799c1.004-3.09-0.096-6.475-2.725-8.385l-40.66-29.542c-2.054-1.493-1.668-3.525-1.407-4.33s1.144-2.676 3.683-2.676h50.259c3.249,0 6.129-2.092 7.133-5.183l15.531-47.798c0.785-2.415 2.837-2.676 3.683-2.676 0.846,0 2.898,0.261 3.683,2.675l15.531,47.799c1.004,3.09 3.884,5.182 7.133,5.182h50.259c2.539,0 3.421,1.871 3.683,2.676 0.261,0.806 0.647,2.838-1.407,4.331z',
            fill: 'currentColor',
          },
        }),
      ],
    )
  },
}

const Star1Icon = {
  name: 'Star1Icon',
  props: {
    size: {
      type: String,
      default: '18',
      validator: function validator(s) {
        return (
          !isNaN(s)
          || (s.length >= 2
            && !isNaN(s.slice(0, s.length - 1))
            && s.slice(-1) === 'x')
        )
      },
    },
  },
  functional: true,
  render: function render(h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    ctx.data.attrs = attrs
    return h(
      'svg',
      _mergeJSXProps([
        {
          attrs: {
            xmlns:"http://www.w3.org/2000/svg",
                class:"h-5 w-5",
                viewBox:"0 0 20 20",
                fill:"#ff9f43",
          },
          class: 'feather feather-star1',
        },
        ctx.data,
      ]),
      [
        h('path', {
          attrs: {
            d: 'M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z',
            fill: 'currentColor',
          },
        }),
        
      ],
    )
  },
}

exports.Calendarv1Icon = Calendarv1Icon
exports.Calendarv2Icon = Calendarv2Icon
exports.Calendarv3Icon = Calendarv3Icon
exports.Calendarv4Icon = Calendarv4Icon
exports.CalendarCup = CalendarCup
exports.QuoteLEft = QuoteLEft
exports.Star1Icon = Star1Icon
