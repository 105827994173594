<template>
	<b-sidebar backdrop lazy title="Tareas" :width="widthSidebar()" v-model="isActive" @hidden="handleHidden">
		<b-overlay class="bg-white p-2" :show="isLoading">
			<b-row class="bg-white">
				<b-col cols="12" md="8">
					<b-tabs v-model="tab" @input="getRows">
						<b-tab title-item-class="items-tab">
							<template #title>
								<span>Pendientes</span>
								<b-badge class="ml-1" variant="danger" v-if="counters.pending != 0">
									{{ counters.pending }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab title-item-class="items-tab">
							<template #title>
								<span>Finalizadas</span>
							</template>
						</b-tab>
						<b-tab title-item-class="items-tab">
							<template #title>
								<span>Anuladas</span>
							</template>
						</b-tab>
					</b-tabs>
				</b-col>
				<b-col cols="12" md="4">
					<b-col class="text-right">
						<b-button
							variant="success"
							@click="createTaskModal.show = !createTaskModal.show"
							v-if="isAdmin"
						>
							<feather-icon class="mr-1" icon="PlusIcon" />
							<span>Agregar Tarea</span>
						</b-button>
					</b-col>
				</b-col>
			</b-row>
			<b-row class="align-items-center mb-2">
				<b-col>
					<b-pagination
						pills
						hide-ellipsis
						hide-goto-end-buttons
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						v-model="pagination.page"
						@change=";(pagination.page = $event), getRows()"
					/>
				</b-col>
				<b-col cols="12" md="4" v-if="isAdmin">
					<div>
						<b-form-group label="Filtrar por usuario" label-align-md="right">
							<v-select
								:options="users"
								:reduce="(option) => option.id"
								label="name"
								v-model="userTasks"
								variant="success"
								:clearable="false"
								placeholder="Seleccionar usuario"
								@input="getRows"
							/>
						</b-form-group>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-table-simple class="w-100 bg-white" sticky-header="60vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th class="text-nowrap">
								Tarea
								<b-badge
									variant="danger"
									v-b-tooltip
									title="Tareas del administrador"
									v-if="isAdmin && adminTasks != 0 && tab == 0"
								>
									{{ adminTasks }}
								</b-badge>
							</b-th>
							<!-- <b-th v-if="isAdmin">Colaborador</b-th> -->
							<b-th>Ejemplares</b-th>
							<!-- aqui -->
							<b-th v-if="isAdmin">Responsable</b-th>
							<!-- <b-th>Creado por</b-th> -->
							<b-th class="text-nowrap" v-if="[1, 2].includes(tab)">
								{{ tab == 1 ? "Finalizado" : "Anulado" }}
							</b-th>
							<b-th class="text-nowrap">Registrado por</b-th>
							<b-th v-if="tab == 0">Estado</b-th>
							<b-th v-if="[0, 1].includes(tab)">Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(row, idx) in rows" :key="idx">
							<b-td>{{ row.name }}</b-td>

							<b-td>
								<div class="specimensCell" v-if="row.specimens && row.specimens.length != 0">
									<b-badge
										class="mr-1"
										variant="primary"
										v-for="specimen in row.specimens"
										:key="specimen.plate"
									>
										{{ specimen.alias || specimen.plate }}
									</b-badge>
								</div>
								<span v-else>---</span>
							</b-td>

							<b-td v-if="isAdmin">{{ row.assign.name }}</b-td>

							<b-td v-if="[1, 2].includes(tab)">
								<feather-icon
									v-if="[1, 2].includes(tab) && row.active_comment != 1"
									icon="MessageSquareIcon"
									size="18"
									class="text-secondary"
									v-b-tooltip.hover
									title="No hay comentario"
								/>
								<feather-icon
									@click="
										;(trackingTaskModal.info = row),
											(trackingTaskModal.show = !trackingTaskModal.show)
									"
									v-else-if="[1, 2].includes(tab)"
									icon="MessageSquareIcon"
									size="18"
									class="text-primary clickable"
									v-b-tooltip.hover
									title="Ver comentario"
								/>
								<br />
								<small class="mr-50 text-nowrap">{{ row.updated_by.date | myGlobalDayWithHour }}</small>
							</b-td>

							<b-td>
								<p class="mb-0">{{ row.created_by.name }}</p>
								<small>{{ row.created_by.date | myGlobalDayWithHour }}</small>
							</b-td>

							<b-td v-if="tab == 0">
								<div class="d-flex flex-column gap-y-1" v-if="[1, 2].includes(row.task_id)">
									<span v-b-tooltip.hover="`Total`">
										<feather-icon icon="GridIcon" />
										: {{ row.check + row.un_check }}
									</span>
									<span v-b-tooltip.hover="`Realizados`">
										<feather-icon class="text-success" icon="CheckCircleIcon" />
										: {{ row.check }}
									</span>
									<span v-b-tooltip.hover="`Sin realizar`">
										<feather-icon class="text-danger" icon="CircleIcon" />
										: {{ row.un_check }}
									</span>
								</div>
								<span v-else>---</span>
							</b-td>

							<b-td
								v-if="[0, 1].includes(tab)"
								class="d-flex justify-content-center align-items-center gap-x-2 cell-btns"
							>
								<!-- row.un_check + row.check == 0 cuando no hay ejemplares asignados -->
								<b-button
									v-b-tooltip.hover
									title="Finalizar"
									class="btn-icon"
									variant="info"
									size="sm"
									@click="
										;(updateTaskModal.info = { ...row, _status: 2 }),
											(updateTaskModal.show = !updateTaskModal.show)
									"
									v-if="tab == 0 && row.un_check == 0"
								>
									<feather-icon icon="CheckIcon" />
								</b-button>
								<b-button
									class="btn-icon"
									variant="warning"
									size="sm"
									@click="
										;(updateTaskImagesModal.info = { ...row, readonly: tab == 1 }),
											(updateTaskImagesModal.show = !updateTaskImagesModal.show)
									"
									v-if="[0, 1].includes(tab) && row.task_id == 1"
									v-b-tooltip.hover
									:title="tab == 0 ? 'Asignar fotos' : 'Ver fotos asignadas'"
								>
									<v-icon name="md-addphotoalternate" />
								</b-button>
								<b-button
									class="btn-icon"
									variant="secondary"
									size="sm"
									@click="
										;(updateTaskWeightsModal.info = { ...row, readonly: tab == 1 }),
											(updateTaskWeightsModal.show = !updateTaskWeightsModal.show)
									"
									v-if="[0, 1].includes(tab) && row.task_id == 2"
									v-b-tooltip.hover
									:title="tab == 0 ? 'Asignar pesos' : 'Ver pesos asignados'"
								>
									<v-icon name="fa-weight" />
								</b-button>
								<b-button
									v-b-tooltip.hover
									title="Cancelar "
									class="btn-icon"
									variant="danger"
									size="sm"
									@click="
										;(updateTaskModal.info = { ...row, _status: 3 }),
											(updateTaskModal.show = !updateTaskModal.show)
									"
									v-if="tab == 0 && isAdmin"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</b-td>
						</b-tr>
						<b-tr v-if="rows.length == 0">
							<b-td colspan="4">No hay registros para mostrar</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-row>
		</b-overlay>
		<CreateTask :show="createTaskModal.show" @closing="createTaskModal.show = false" @refresh="getRows" />
		<UpdateTask
			:show="updateTaskModal.show"
			:info="updateTaskModal.info"
			@closing="updateTaskModal.show = false"
			@refresh="getRows"
		/>
		<UpdateTaskImages
			:show="updateTaskImagesModal.show"
			:info="updateTaskImagesModal.info"
			@closing="updateTaskImagesModal.show = false"
			@refresh="getRows"
		/>
		<UpdateTaskWeights
			:show="updateTaskWeightsModal.show"
			:info="updateTaskWeightsModal.info"
			@closing="updateTaskWeightsModal.show = false"
			@refresh="getRows"
		/>
		<TaskTracking
			:show="trackingTaskModal.show"
			:info="trackingTaskModal.info"
			@closing="trackingTaskModal.show = false"
		/>
	</b-sidebar>
</template>

<script>
import tasksService from "@/services/tasks.service.js"

import CreateTask from "./CreateTask.vue"
import UpdateTask from "./UpdateTask.vue"
import UpdateTaskImages from "./UpdateTaskImages.vue"
import UpdateTaskWeights from "./UpdateTaskWeights.vue"
import TaskTracking from "./TaskTracking.vue"

export default {
	components: { CreateTask, UpdateTask, UpdateTaskImages, UpdateTaskWeights, TaskTracking },
	props: {
		show: Boolean,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		rows: [],
		pagination: { total: 0, page: 1, perPage: 50 },
		counters: { pending: 0, finished: 0, canceled: 0 },
		createTaskModal: { show: false },
		updateTaskModal: { show: false, info: {} },
		updateTaskImagesModal: { show: false, info: {} },
		updateTaskWeightsModal: { show: false, info: {} },
		trackingTaskModal: { show: false, info: {} },
		users: [],
		userTasks: null,
		adminTasks: 0,
	}),
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.tab = 0
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tasksService.getUserTasks({
				status_id: this.tab + 1,
				page: this.pagination.page,
				perpage: this.pagination.perPage,
				user_id: this.userTasks,
			})
			this.pagination.total = data.total
			this.rows = data.data
			await this.getCounters()
			await this.getAdminCounters()
			this.isLoading = false
		},
		async getCounters() {
			const { data } = await tasksService.getUserTaskCounters({
				user_id: this.userTasks,
			})
			this.counters.pending = data.find((c) => c.id == 1).counter
			this.counters.finished = data.find((c) => c.id == 2).counter
			this.counters.canceled = data.find((c) => c.id == 3).counter
		},
		async getAdminCounters() {
			const { data } = await tasksService.getUserTaskCounters({
				user_id: 1,
			})
			this.adminTasks = data.find((c) => c.id == 1).counter
		},
		async getUsers() {
			const { data } = await tasksService.getUsersList()
			this.users = [{ id: null, name: "Todos", last_name: "---" }, ...data]
		},
		widthSidebar() {
			if (this.widthScreen < 600) {
				return "100vw"
			} else if (this.widthScreen < 1200) {
				return "80vw"
			} else {
				return "50vw"
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				// this.userTasks = this.currentUser.id
				this.isAdmin ? (this.userTasks = null) : (this.userTasks = this.currentUser.id)
				this.isActive = val
				this.getRows()
				this.getUsers()
			}
		},
	},
}
</script>

<style lang="scss">
.specimensCell {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 0.5rem;
	max-width: 10rem;
	max-height: 5rem;
	overflow: auto;
}

.items-tab {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: auto !important;
	}
}
.cell-btns {
	height: 81px !important;
	@media screen and (min-width: 900px) {
		height: auto !important;
	}
}
</style>
