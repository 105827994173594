var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","size":"sm","title":"Agregar tarea"},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createTask}},[_vm._v("Guardar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Tarea")]),_c('b-button',{staticClass:"btn-icon float-right",attrs:{"size":"sm","variant":"flat-success"},on:{"click":function($event){_vm.creationModal.show = !_vm.creationModal.show}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}])},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:_vm.creationModal.show,class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","options":_vm.taskOpts,"reduce":function (opt) { return opt.value; }},on:{"input":function($event){_vm.selectedSpecimens = []}},model:{value:(_vm.task),callback:function ($$v) {_vm.task=$$v},expression:"task"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Colaborador"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","options":_vm.userOpts,"reduce":function (opt) { return opt.value; }},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}])})],1),([1, 2].includes(_vm.task))?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSpecimens),expression:"selectedSpecimens"}],staticClass:"d-none",domProps:{"value":(_vm.selectedSpecimens)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedSpecimens=$event.target.value}}}),_c('b-form-group',{attrs:{"label":"Seleccionar ejemplares"}},[_c('v-select',{attrs:{"label":"query","clearable":false,"options":_vm.specimenOpts,"selectable":_vm.checkOptionSelectable},on:{"search":_vm.fetchOpts,"option:selecting":_vm.handleSpecimenSelected,"option:selected":function($event){_vm.selectModel = null}},model:{value:(_vm.selectModel),callback:function ($$v) {_vm.selectModel=$$v},expression:"selectModel"}})],1),_c('b-row',{staticClass:"overflow-auto",staticStyle:{"max-height":"10rem"}},_vm._l((_vm.selectedSpecimens),function(specimen,idx){return _c('b-col',{key:specimen.id,staticClass:"mb-1 text-center",attrs:{"cols":"6"}},[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(specimen.query)+" "),_c('feather-icon',{staticClass:"clickable ml-05",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.removeSelectedSpecimen(idx)}}})],1)],1)}),1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Se debe seleccionar al menos 1 ejemplar")]):_vm._e()]}}],null,false,1161810077)})]:_vm._e()],2)],1),_c('b-modal',{attrs:{"size":"sm","title":"Crear tarea","centered":""},on:{"hidden":_vm.clearCreation},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createTaskBrain}},[_vm._v("Guardar")])]},proxy:true}]),model:{value:(_vm.creationModal.show),callback:function ($$v) {_vm.$set(_vm.creationModal, "show", $$v)},expression:"creationModal.show"}},[_c('b-overlay',{attrs:{"show":_vm.creationModal.loading}},[_c('ValidationObserver',{ref:"newTask"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{class:{ 'border-danger': errors[0] },attrs:{"placeholder":"Ingrese el nombre"},model:{value:(_vm.creationModal.name),callback:function ($$v) {_vm.$set(_vm.creationModal, "name", $$v)},expression:"creationModal.name"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }