import state from "@/store/modules/specimens/state";
import mutations from "@/store/modules/specimens/mutations"
import actions from "@/store/modules/specimens/actions"
import getters from "@/store/modules/specimens/getters"

/** @returns { import('vuex').Module } */
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}