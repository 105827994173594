export default [
  {
    path: "/usuarios-invitados",
    // name: "users-guest",
    component: () => import("@/views/amg/users-guest/UserGuestComponent.vue"),
    meta: {
    },
    children: [
      {
        path: "",
        name: "users-guest-list",
        component: () => import("@/views/amg/users-guest/ListUsersGuest.vue"),
        meta: {
          layout: "vertical",
          pageTitle: 'Colaboradores externos',
          breadcrumb: [
            {
              text: "Menu",
              active: false,
              to: '/'
            },
            {
              text: "Colaboradores externos",
              active: true
            },
          ],
          roles: [1]
        }
      }
    ]
  },
];
