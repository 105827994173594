import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

import amgUsers from "./routes/amg/users"
import amgCommons from "./routes/amg/commons"
import auth from "./routes/auth"
import srEjemplares from "./routes/amg/specimens"
import srAdministrative from "./routes/amg/administrative"
import srSnoods from "./routes/amg/snoods"
import srVideolibrary from "./routes/amg/videolibrary"
import srEncaste from "./routes/amg/encaste"
import srTournaments from "./routes/amg/tournaments"
import srSettings from "./routes/amg/settings"
import srWizard from "./routes/amg/wizard"
import srCompany from "./routes/amg/company"
import srUsersGuest from "./routes/amg/users-guest"
// import srReports from "./routes/amg/reports"
import srOrders from "./routes/amg/orders"
import srManagement from "./routes/amg/management"
import srSquareUpInventory from "./routes/amg/squareUpInventory"
import srPreparacion from "./routes/amg/preparacion"
import srNursery from "./routes/amg/nursery"

//BRain routes
import brainCommons from "./routes/brain/commons"
import brainTournament from "./routes/brain/tournaments"
import brainEncaste from "./routes/brain/encaste"
import brainItems from "./routes/brain/items"
import brainSpecimens from "./routes/brain/specimens"
import brainPreparacion from "./routes/brain/preparacion"
import brainNursery from "./routes/brain/nursery"
import brainOthers from "./routes/brain/others"
import brainAdministrative from "@/router/routes/brain/administrative.js";

import guestService from "@/services/guest.service"

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		// {
		// 	path: "/login",
		// 	name: "login",
		// 	component: () => import(/* webpackChunkName: 'login' */ "@/views/auth/views/Login.vue"),
		// 	meta: {
		// 		layout: "full",
		// 		resource: "Auth",
		// 		redirectIfLoggedIn: true,
		// 	},
		// },
		{
			path: "/",
			redirect: { name: "menu" },
		},
		...auth,
		{ path: "/brain", redirect: { name: "brain-menu" } },
		...amgCommons,
		...amgUsers,
		...srEjemplares,
		// ...srPollos,
		...srAdministrative,
		...srSnoods,
		// ...srDeads,
		...srVideolibrary,
		...srEncaste,
		...srTournaments,
		...srSettings,
		...srWizard,
		...srCompany,
		...srUsersGuest,
		...srSquareUpInventory,
		// ...srReports,
		...srOrders,
		...srManagement,
		...srPreparacion,
		...srNursery,
		// Brain routes
		...brainCommons,
		...brainTournament,
		...brainEncaste,
		...brainItems,
		...brainSpecimens,
		...brainPreparacion,
		...brainNursery,
		...brainOthers,
		...brainAdministrative,
		{
			path: "/error-404",
			name: "error-404",
			component: () => import(/* webpackChunkName: 'error-404' */ "@/views/error/Error404.vue"),
			meta: {
				layout: "full",
				resource: "Auth",
				action: "read",
			},
		},
		{
			path: "*",
			redirect: "error-404",
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading")
	if (appLoading) {
		appLoading.style.display = "none"
	}
})

router.beforeEach(async (to, from, next) => {
	if (to.name == "forget-password") {
		return next()
	}

	if (to.name !== "login" && store.state.auth.currentUser == null) {
		next({ name: "login" })
	}

	if (to.name == "menu") {
		store.commit("app/M_IS_PRELOADING", true, { root: true })
	}

	if (
		store.state.auth &&
		store.state.auth.currentUser &&
		store.state.auth.currentUser.roles.includes(7) &&
		to.name == "detalle-ejemplar"
	) {
		const { data } = await guestService.validateAccess({
			specimen_id: to.params.specimen_id,
			guest_id: store.state.auth.currentUser.id,
		})
		if (data.is_valid) {
			next()
		} else {
			next({ name: "specimens-component" })
		}
	}

	if (store.state.auth.currentUser) {
		const {
			roles,
			privileges: [privilegio],
		} = store.state.auth.currentUser
		const rolesRoutes = to.meta.roles

		if (roles.length > 0) {
			let access = false

			roles.forEach((rol) => {
				rolesRoutes.forEach((rolRoute) => {
					if (rol == rolRoute) {
						access = true
					}
				})
			})

			// console.log("access", access);

			if ((to.name == "orders-list-sr" || to.name == "orders-cart-sr") && privilegio == 1) {
				access = true
			}

			if (!access) {
				// console.log("return menu");
				next({ name: "menu" })
			}
		} else {
			next()
		}
	}

	// else if (to.meta.requiresAuth !== undefined && to.meta?.requiresAuth.length) {
	// 	// next();
	// 	// console.log(to.meta.requiresAuth !== undefined);
	// 	const currentUser = JSON.parse(localStorage.getItem("vuex"))?.user;

	// 	if (currentUser) {
	// 		const { roles } = currentUser;
	// 		const canEnterByRole = to.meta.requiresAuth.some((p) => {
	// 			return roles.includes(p);
	// 		});
	// 		if (canEnterByRole) {
	// 			next();
	// 		}
	// 	}
	// 	next(from.path);
	// } else {
	// }
	next()
})

export default router
