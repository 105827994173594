import axios from "@/axios";

const url = "/api/specimens-user-guest/";

class GuestService {
	async getAvailableSpecimens({ guest_id }) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: { guest_id },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateAvailableSpecimens({ guest_id, specimens }) {
		try {
			const { data } = await axios.post(`${url}store`, {
				guest_id,
				specimens,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async getSpecimensTracking({ guest_id, specimen_id }) {
		try {
			const { data } = await axios.get(`${url}tracking`, {
				params: { guest_id, specimen_id },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async validateAccess({ guest_id, specimen_id }) {
		try {
			const { data } = await axios.get(`${url}valid-view-specimen-dashboard`, {
				params: { guest_id, specimen_id },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new GuestService();
