import Vue from "vue"
import moment from "moment"

Vue.filter("myGlobalDay", function (date) {
	if (date)
		return new Date(date.replace(/-/g, "/")).toLocaleDateString("es-ES", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		})
	return "-"
})
Vue.filter("myGlobalDaywithoutYear", function (date) {
	if (date)
		return new Date(date.replace(/-/g, "/")).toLocaleDateString("es-ES", {
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		})
	return "-"
})
Vue.filter("myGlobalHour", function (date) {
	if (date) return moment(date).format("HH:mm")
	return "-"
})

Vue.filter("myGlobalDayWithHour", function (date) {
	// const datetime = new Date(date.replace(/-/g, "/"));
	// return date ? Intl.DateTimeFormat('es-ES', {
	// 	day: '2-digit',
	// 	hour: "2-digit",
	// 	minute: "2-digit",
	// 	hour12: true,
	// }).format(datetime) : "-";
	return moment(date).format("MM/DD/YYYY HH:mm")
})

Vue.filter("myGlobalWithDoc", function (date) {
	if (date)
		return new Date(date.replace(/-/g, "/"))
			.toLocaleDateString("es-ES", {
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
			})
			.slice(-12)
	return "-"
})
Vue.filter("myGlobalDayWhour", function (date) {
	// if (date) return new Date(date.replace(/-/g, '\/')).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })
	if (date) return moment(date).locale("es").format("MM/DD/YYYY")
	return "-"
})
Vue.filter("myGlobalDayMonth", function (date) {
	if (date) return moment(date).locale("es").format("DD MMMM")
	return "-"
})
Vue.filter("myGlobalDayWMonth", function (date) {
	// if (date) return new Date(date.replace(/-/g, '\/')).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })
	if (date) return moment(date).locale("es").format("DD MMMM")
	return "Sin definir"
})
Vue.filter("myGlobalDayWithDay", function (date) {
	// if (date) return new Date(date.replace(/-/g, '\/')).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })
	if (date)
		return (
			moment(date).locale("es").format("dddd DD MMMM YYYY").toString().charAt(0).toUpperCase() +
			moment(date).locale("es").format("dddd DD MMMM YYYY").toString().slice(1)
		)
	return "-"
})
Vue.filter("myGlobalDayShort", (date) => {
	if (date) return moment(date).format("MM/DD/YYYY")
})

Vue.filter("myGlobalDateTime", (date) => {
	if (date) return moment(date).format("MM/DD/YYYY HH:mm")
})

Vue.filter("reverseArray", function (array) {
	if (array.length) return array.slice().reverse()
})

Vue.filter("currency", (value, id = 2) => {
	const currencies = {
		1: { currency: "USD", code: "en-US" },
		2: { currency: "PEN", code: "es-PE" },
	}
	return !isNaN(parseFloat(value))
		? Intl.NumberFormat(currencies[id].code, {
				style: "currency",
				currency: currencies[id].currency,
		  }).format(parseFloat(value))
		: value
})

Vue.filter("dayWithMonth", (value) => {
	if (value) {
		return moment(value).locale("es").format("DD MMMM")
	}
	return "-"
})

Vue.filter("MonthName", (value) => {
	if (typeof value !== "string") return
	let months = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Setiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	]
	return months[moment(value).locale("es").set("month")]
})

Vue.filter("monthThatPassed", (value) => {
	if (moment().format("YYYY-MM-DD") == value) {
		return "creado hoy"
	}
	let today = moment()
	let start = moment(new Date(value))
	let daysDiff = today.diff(start, "days")
	// return moment(new Date(value)).locale('es').startOf('hour').from(moment().format('YYYY-MM-DD'));
	return `hace ${daysDiff} dias`
})

Vue.filter("daysSince", (value) => {
	if (moment(value).isSame(new Date(), "day")) {
		return "creado hoy"
	}
	let today = moment()
	let start = moment(new Date(value)).format("YYYY-MM-DD")
	let daysDiff = today.diff(start, "days")
	return `${daysDiff} dia(s)`
})

Vue.filter("untilMonthDay", (value) => {
	if (moment().format("YYYY-MM-DD") == value) {
		return "creado hoy"
	}
	let days = 0
	let months = 0
	let today = moment()
	let start = moment(new Date(value))
	months = today.diff(start, "months")
	start.add(months, "months")
	days = today.diff(start, "days") + 1

	let res
	if (months < 1) {
		res = `${days} dias`
	} else {
		res = `${months} meses, ${days} dias`
	}

	return res
})

Vue.filter("untilYearMonth", (value) => {
	if (value == null) return "---"
	if (moment().format("YYYY-MM-DD") == value) {
		return "creado hoy"
	}
	let months = 0
	let years = 0
	let today = moment()
	let start = moment(new Date(value))
	years = today.diff(start, "years")
	start.add(years, "years")
	months = today.diff(start, "months")

	return `${years} años, ${months} meses`
})

Vue.filter("formatoPersonalizado", (value) => {
	if (value == null) return "---"

	// Establecer idioma español
	moment.locale("es")

	return moment(value).format("dddd DD [de] MMMM [del] YYYY")
})
