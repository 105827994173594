var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-add-postura",attrs:{"title":_vm.title,"no-close-on-backdrop":"","cancel-title":"Cancelar","scrollable":"","centered":_vm.is_eggs_found,"size":_vm.modalSize},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancelar")]),(!_vm.loading)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)]}}])},[_c('div',{style:({ minHeight: _vm.is_eggs_found ? 'auto' : 'calc(100vh - 120px) !important' })},[_c('b-row',{staticClass:"header-date-total",staticStyle:{"align-items":"stretch"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Cantidad de posturas")]),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.counterPosturas))])])]),(!_vm.is_eggs_air)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"gradient-primary"},on:{"click":_vm.addNewPostura}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"18"}})],1)],1):_vm._e()],1),_c('ValidationObserver',{ref:"form"},_vm._l((_vm.arrayPosturas),function(postura,index){return _c('b-row',{key:(index + "-postura"),staticClass:"position-relative pr-2 pl-2"},[(!_vm.is_eggs_found)?_c('b-badge',{staticClass:"position-absolute",staticStyle:{"left":"4px","top":"2px"},attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),[_c('b-col',{attrs:{"cols":"12","lg":_vm.colSize}},[_c('b-form-group',{attrs:{"label-for":'madrilla-' + index,"label":"Elige la madrilla:"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayMadrillas,"clearable":false,"id":'madrilla-' + index,"label":"alias","reduce":function (option) { return option; },"filter":_vm.fuseSearch,"selectable":function (option) { return !option.isDisabled; },"disabled":_vm.is_eggs_air},on:{"open":function($event){return _vm.scrollToBotom(index)},"input":function($event){return _vm.selectOnChange(postura, index)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}},{key:"option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}}],null,true),model:{value:(postura.madrilla),callback:function ($$v) {_vm.$set(postura, "madrilla", $$v)},expression:"postura.madrilla"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),(!_vm.same_padrillo && !_vm.withPad)?_c('b-col',{attrs:{"cols":"12","lg":_vm.is_eggs_found ? 12 : 3}},[_c('b-form-group',{attrs:{"label-for":'padrillo-' + index,"label":"Elige el padrillo:","description":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"id":'padrillo' + index,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayPadrillos,"clearable":false,"label":"alias","reduce":function (option) { return option; },"filter":_vm.fuseSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [(alias)?_c('span',{domProps:{"textContent":_vm._s((alias + " / (" + plate + ")"))}}):_c('span',{domProps:{"textContent":_vm._s(("(" + plate + ")"))}})]}},{key:"option",fn:function(opt){return [(opt.selected)?_c('b',[_vm._v(" ** "+_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate || "---")+") "),(opt.counter)?_c('small',[_vm._v("NP: "+_vm._s(opt.counter))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate || "---")+") "),(!opt.enabled)?_c('span',[_vm._v("- Deshabilitado")]):_vm._e()])]}}],null,true),model:{value:(postura.padrillo),callback:function ($$v) {_vm.$set(postura, "padrillo", $$v)},expression:"postura.padrillo"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('small',[_vm._v("Padrillos con (**): Fueron marcados como exedente de inseminacion")])],1)],1):_vm._e()],_c('b-col',{attrs:{"cols":"12","lg":_vm.colSize}},[_c('b-form-group',{attrs:{"id":"date","label":"Fecha","label-for":"date"}},[_c('b-form-datepicker',{attrs:{"id":"date","date-format-options":{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}},model:{value:(postura.postura_date),callback:function ($$v) {_vm.$set(postura, "postura_date", $$v)},expression:"postura.postura_date"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":_vm.colSize}},[_c('b-form-group',{attrs:{"id":"observation","label":"Comentario","label-for":"observation"}},[_c('b-form-textarea',{staticStyle:{"resize":"none"},attrs:{"id":"observation","rows":"3"},model:{value:(postura.observation),callback:function ($$v) {_vm.$set(postura, "observation", $$v)},expression:"postura.observation"}})],1)],1),(!_vm.is_eggs_found)?_c('feather-icon',{staticClass:"text-danger cursor-pointer",staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.deletePostura(postura, index)}}}):_vm._e()],2)}),1),(_vm.is_eggs_found)?_c('div',{staticClass:"ml-2 mr-2"},[_c('p',{staticClass:"alert alert-warning p-1 w-100"},[_vm._v("Esta postura sera creada en estado pendiente.")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }