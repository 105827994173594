import Vue from "vue"
import axios from "axios"
import store from "@/store"
import router from "@/router"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

const ax = axios.create({
	baseURL: process.env.VUE_APP_API_BACK,
	withCredentials: false, // This is the default
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
		"ngrok-skip-browser-warning": "true",
	},
	// timeout: 10000, // 10seg
})

ax.interceptors.request.use(
	(config) => {
		const vuex = JSON.parse(localStorage.getItem("vuex"))
		if (vuex) {
			config.headers.common.Authorization = `Bearer ${vuex.token}`
		}
		return config
		z
	},
	(error) => {
		return Promise.reject(error)
	}
)

// Add a response interceptor
ax.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	(error) => {
		store.commit("app/M_IS_PRELOADING", false)
		let mensajeError = ""

		if (error.response) {
			if (error.response.status === 500) {
				mensajeError = error.response.statusText
			} else if (error.response.status === 404) {
				mensajeError = error.response.data.message
			} else if (error.response.status === 401) {
				mensajeError = error.response.data.message
			} else if (error.response.status === 405) {
				mensajeError = error.response.data.message
			} else if (error.response.status === 414) {
				mensajeError = error.response.data.message
			} else if (error.response.status === 400) {
				mensajeError = error.response.data.message
			}
		} else if (error.request) {
			mensajeError = error.request
		} else if (error.message === "Network Error") {
			mensajeError = "Error de conexión con el servidor."
		}
		// else if (error.code === "ECONNABORTED") {
		// 	// eslint-disable-next-line
		// 	const tiempo = `${parseInt(store.state.app.timeout / 60)}`;
		// 	mensajeError = `Se supero el tiempo de espara máximo (${tiempo} min.)`;
		// }
		mensajeError = mensajeError.toLowerCase()
		mensajeError = mensajeError.charAt(0).toUpperCase() + mensajeError.slice(1)

		// console.log('ERROR', error.response.data.message, mensajeError);

		Vue.$toast(
			{
				component: ToastificationContent,
				props: {
					title: "Notificación",
					icon: "AlertTriangleIcon",
					variant: "danger",
					text: mensajeError,
				},
			},
			{
				position: "bottom-center",
				timeout: 3000,
			}
		)

		if (error.response) {
			if (error.response.status === 401) {
				store.commit("auth/SET_CURRENT_USER", {})
				localStorage.removeItem("vuex")
				router.push({ name: "login" })
			}
		}

		return Promise.reject(error)
	}
)

export default ax
