<template>
	<b-card no-body>
		<b-card-header class="pb-50">
			<h5>Filtros</h5>
		</b-card-header>
		<b-card-body>
			<b-row>
				<b-col cols="12" md="3" class="mb-md-1 mb-2" v-for="filter in filters" :key="filter.id">
					<template v-if="filter.type=='date'">
						<label>{{ filter.label }}</label>
						<b-form-datepicker
							locale="en-US"
							class="w-100"
							@input="onChange"
							:placeholder="filter.placeholder"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="filter.model"
						/>
					</template>
					<template v-else-if="filter.type != 'stars' && !filter.multiselect">
						<label>{{ filter.label }}</label>
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							v-model="filter.model"
							:options="filter.options"
							:label="filter.labelSelect"
							class="w-100"
							:reduce="(val) => val[filter.primaryKey]"
							@input="onChange"
							placeholder="Seleccionar opción"
							:selectable="(val) => val.selectable || val.selectable == undefined"
						/>
					</template>
					<template v-else-if="filter.type == 'stars'">
						<label>{{ filter.label }}</label>
						<div class="d-flex">
							<b-row class="d-flex w-full mb-2">
								<b-col cols="8" class="pl-2">
									<veeno
										pipsy
										:connect="[true, false]"
										:handles="[0]"
										:range="{ min: 0, max: filter.top }"
										:step="filter.step"
										@change="onChange"
										v-model="filter.model"
									/>
								</b-col>

								<b-col class="text-center text-nowrap text-weight-bolder h6">
									{{ filter.model }} / {{ filter.top }}
								</b-col>
							</b-row>
						</div>
					</template>
					<template v-else>
						<label>{{ filter.label }}</label>
						<v-select
							v-model="filter.model"
							:label="filter.labelSelect"
							class="w-100"
							multiple
							reverse
							@input="onChange(filter.model)"
							:clearable="false"
							placeholder="Seleccionar una raza"
							:options="filter.options"
							:reduce="(val) => val[filter.primaryKey]"
						/>
					</template>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import vSelect from "vue-select"
import veeno from "veeno"
import "nouislider/distribute/nouislider.min.css"

export default {
	components: {
		vSelect,
		veeno,
	},
	props: {
		filters: Array,
	},
	methods: {
		onChange(val) {
			this.$emit("onChangeFilter", null)
		},
	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
