export default [
	{
		path: "/nursery",
		name: "nursery-sr",
		component: () => import("@/views/amg/nursery/main.vue"),
		redirect: { name: "nursery-sr-specimens" },
		children: [
			{
				path: "specimens",
				name: "nursery-sr-specimens",
				component: () => import("@/views/amg/nursery/views/specimens.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Enfermeria",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: true,
						},
					],
					roles: [1, 12],
				},
			},
			{
				path: "chicks",
				name: "nursery-sr-chicks",
				component: () => import("@/views/amg/nursery/views/chicks.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Enfermeria",
					breadcrumb: [
						{
							text: "Pollos",
							active: true,
						},
					],
					roles: [1, 12],
				},
			},
		],
	},
]
