import axios from "@/axios";

export class PedigreeService {
	constructor() {
		this.module = "api/tree";
	}

	/**
	 * @param {number} specimen_id The specimen id
	 */
	async getSpecimenPedigree(specimen_id) {
		try {
			const { data: response } = await axios.get(`${this.module}/generate/${specimen_id}`);
			return response;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * @param {number} specimen_id The specimen id
	 */
	async fetchSpecimenForUpdate(specimen_id) {
		try {
			const { data: response } = await axios.get(`api/specimens/get-specimen-for-update/${specimen_id}`);
			return response;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * @param {number} specimen_id The specimen id
	 * @param {Object<string, any>} specimen The specimen object for update
	 */
	async updateSimpleSpecimen(dataset) {
		try {
			const { data: response } = await axios.post(`api/specimens/simple-update`, dataset);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async saveSpecimenParent(specimen) {
		try {
			const { data: response } = await axios.post(`api/tree/save`, specimen);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async changeParent(body) {
		try {
			const { data: response } = await axios.put(`api/tree/update-family-tree-by-child`, body);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

export default new PedigreeService();
