import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const showToast = (
	text,
	title = "Felicidades",
	variant = "success",
	icon = "CheckIcon",
	position = "top-right"
) => {
	Vue.$toast(
		{
			component: ToastificationContent,
			props: {
				title,
				icon,
				text,
				variant,
			},
		},
		{
			position,
		}
	);
};
