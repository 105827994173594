export default [
	{
		path: "/brain/preparacion",
		name: "brain-preparacion-main",
		component: () => import("@/views/brain/preparacion/main.vue"),
		redirect: { name: "preparacion-settings-suplements" },
		children: [
			{
				path: "suplements",
				name: "preparacion-settings-suplements",
				component: () => import("@/views/brain/preparacion/views/Vitamins.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Suplementos",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "qualities",
				name: "preparacion-settings-qualities",
				component: () => import("@/views/brain/preparacion/views/Qualities.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Cualidades",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "seasons",
				name: "preparacion-settings-seasons",
				component: () => import("@/views/brain/preparacion/views/Seasons.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Temporadas",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "defects",
				name: "preparacion-settings-defects",
				component: () => import("@/views/brain/preparacion/views/Defects.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Defectos",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "exercices",
				name: "preparacion-settings-exercices",
				component: () => import("@/views/brain/preparacion/views/Exercices.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Ejercicios",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "reasons",
				name: "preparacion-settings-reasons",
				component: () => import("@/views/brain/preparacion/views/Reasons.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Motivos",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
			{
				path: "blocks",
				name: "preparacion-settings-blocks",
				component: () => import("@/views/brain/preparacion/views/Blocks.vue"),
				meta: {
					pageTitle: "Configuración Preparacion",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Preparacion",
							active: true,
						},
						{
							text: "Bloques",
							active: true,
						},
					],
					roles: [1, 5],
				},
			},
		],
	},
];
