/**
 * @returns {import('vuex').Store}
 */

export const createObject = (type_search = 1) => ({
	type_search,
	search: null,
	condition: 1,
	perpage: 100,
	page: 1,
	order_by: "correlative",
	order: "asc",
	start_dob: null,
	end_dob: null,
	gender_id: null,
	chicks_id: [],
});

export const state = () => ({
	notifications: [],
	count: 0,
	page: 1,
	perpage: 10,
	last_page: 1,
	loadingScroll: false,
	reload_animation: false,
});
