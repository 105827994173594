import axios from "@/axios"

const url = "/api/reasons/"

class ReasonsService {
	async getByModule(module_id = 1, status_id = null) {
		// 1-> muertos, 2-> encaste, 3 -> transacciones
		try {
			const { data } = await axios.get(`${url}${module_id}`, { params: { status_id } })
			return data.reasons
		} catch (err) {
			return { error: err.response.data.error.message }
		}
	}

	async save({ module_id, parent_id, name }) {
		try {
			await axios.post(`${url}save`, { module_id, parent_id, name })
			return { ok: true }
		} catch (error) {
			return { ok: false, error: err.response.data.error.message }
		}
	}

	async update({ reason_id, name }) {
		try {
			await axios.put(`${url}update/${reason_id}`, { name })
			return { ok: true }
		} catch (err) {
			return { ok: false, error: err.response.data.error.message }
		}
	}

	async delete({ reason_id }) {
		try {
			await axios.delete(`${url}delete/${reason_id}`)
			return { ok: true }
		} catch (err) {
			return { ok: false, error: err.response.data.error.message }
		}
	}
}
export default new ReasonsService()
