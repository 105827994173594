<template>
	<transition name="fade">
		<div class="rx-loading" v-show="isShow" :style="{ background: background }">
			<b-spinner label="Spinning" variant="primary" style="margin-top: 4px" v-if="!isFull"></b-spinner>
			<div class="rx-loading--icon text-center" style="width: 100px" v-else>
				<!-- <b-icon icon="stopwatch" font-scale="4" animation="cylon" variant="primary"></b-icon>
        <h5 class="text-primary">Please wait...</h5>  -->
				<div id="loader"></div>

				<!-- <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" stroke="#ff9f43" strokeMiterlimit="10" class="path"></circle>
        </svg> -->
			</div>
			<div class="container-loading" v-if="loadingBtn">
				<button class="btn btn-primary" @click="load">Esperar en segundo plano</button>
			</div>
		</div>
	</transition>
</template>
<script>
import { mapActions, mapState } from "vuex";
import store from "../../store/index";

export default {
	name: "loading",
	data() {
		return {
			isShow: false,
			// background: 'rgba(255, 255, 255, 0.5)',
			background: "",
			isFull: false,
		};
	},
	computed: {
		// ...mapState("app", [
		//         "loadingBg",
		//     ]),
		loadingBtn() {
			return store.state.app.loadingBg;
		},
	},
	methods: {
		...mapActions("header_book", ["loadingBackground"]),
		load() {
			store.commit("header_book/LOADING_BACKGROUND");
			store.commit("app/M_IS_PRELOADING", false);
		},
	},
};
</script>

<style lang="scss">
.container-loading {
	text-align: center;
	margin-top: 3rem;
	width: 300px;
	width: 100%;
	// background: orange;
	position: absolute;
	bottom: 10rem;
	left: 0;
	.btn {
	}
}
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #9370db;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #ba55d3;
	-webkit-animation: spin 3s linear infinite;
	animation: spin 3s linear infinite;
}
#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #ff00ff;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.rx-loading {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	transition: 0.3s all;
	&--icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
	}
	.circular {
		animation: rotate 2s linear infinite;
		height: 100%;
		transform-origin: center center;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	.path {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		animation: dash 1.5s ease-in-out infinite;
		stroke-linecap: round;
		stroke-width: 2px;
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
@keyframes color {
	0%,
	100% {
		stroke: #d62d20;
	}
	40% {
		stroke: #5d9bfd;
	}
	66% {
		stroke: #008744;
	}
	80%,
	90% {
		stroke: #ffa700;
	}
}
.fade-enter-active,
.fade-leave-active {
	opacity: 1;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
