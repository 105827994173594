/** @ts-check */
import { showToast } from "@/helpers/toast";
import types from "./types";
import moment from "moment";



export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	// async fetchPedigree({ commit }, { specimen_id }) {
	// 	try {
	// 		const pedigree = await pedigreeService.getSpecimenPedigree(specimen_id);
	// 		commit(types.FETCH_PEDIGREE, pedigree)
	// 		return pedigree;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// },

	

};
