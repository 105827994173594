<template>
  <div
    :style="images.length &lt; 3 ? '' : 'overflow-y: scroll; height: 238px;'"
  >
    <label :for="source" style="width: 100%">
      <div
        class="uploader"
        style="
          border-radius: 20px;
          background: transparent;
          border-style: dashed;
          border-width: thin;
          cursor: pointer;
        "
        :style="`padding: ${containerPadding}`"
        @dragenter="OnDragEnter"
        @dragleave="OnDragLeave"
        @dragover.prevent
        @drop="onDrop"
        :class="{ dragging: isDragging }"
      >
        <div v-show="!images.length">
          <span class="d-none d-sm-inline">
            <p style="font-size: 26px; font-weight: bold; margin-bottom: 0">
              {{containerTitle}}
            </p>
            <div style="font-size: 12px">O</div>
            <div class="file-input">
              <div class="file-input">
                <feather-icon icon="UploadCloudIcon" size="20" />
                {{containerDescription}}
              </div>
              <input
                type="file"
                :id="source"
                v-on:change="onInputChange"
                multiple="multiple"
              />
            </div>
          </span>
          <feather-icon
            icon="UploadCloudIcon"
            size="30"
            class="d-inline d-sm-none"
          />
        </div>

        <div
          class="images-preview"
          v-show="images.length"
          style="margin-top: 0px"
          :for="source"
        >
          <div
            class="img-wrapper"
            v-for="(image, index) in images"
            :key="index"
            style="
              border-radius: 20px;
              padding: 10px;
              background: #eeefe4;
              width: 120px;
            "
          >
            <feather-icon icon="FolderIcon" class="text-warning" size="25" />
            <div class="details">
              <span class="name" v-text="files[index].name.substr(0, 10)"></span
              ><span
                class="size"
                v-text="getFileSize(files[index].size)"
              ></span>
              <feather-icon
                icon="XIcon"
                class="cursor-pointer text-danger"
                @click="removeFile($event, index)"
                size="18"
                style="position: absolute; top: 5px; right: 7px"
              />
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { Form } from "vform";
export default {
  props: {
    filesArray: Array,
    source: String,
    containerTitle: {
      type: String,
      default: "Arrastra las imagenes aquí"
    },
    containerDescription: {
      type: String,
      default: "Selecciona un archivo"
    },
    containerPadding: {
      type: String,
      default: "15px"
    }
  },
  data() {
    return {
      images: [],
      disable: {
        upload: false,
      },
      errorFile: false,
      form: new Form({
        image: "",
        namedoc: "",
        account: "",
        size: "",
        user_id: "",
        type: "",
        datexp: "",
        folder_name: "",
        folder_id: "",
      }),
      files: [],
      disable: {
        upload: false,
      },
      folder_id: "",
      sendto: "",
      responsable: "",
      result: "",
      isDragging: false,
      dragCount: 0,
    };
  },
  mounted() {
    Array.from(this.filesArray).forEach((file) => this.addImage(file));
  },
  methods: {
    addImage(file) {
      this.files.push(file);
      const img = new Image(),
        reader = new FileReader();
      reader.onload = (e) => this.images.push(e.target.result);
      reader.readAsDataURL(file);
    },
    onInputChange(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
      this.$emit("input", this.files);
    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }
      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    removeFile(e, key) {
      e.preventDefault();
      this.files.splice(key, 1);
      this.images.splice(key, 1);
      this.$emit("input", this.files);
    },
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => this.addImage(file));
      this.$emit("input", this.files);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-control {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 10px;
  padding-bottom: 4px;
  text-align: right;
  button,
  label {
    background: #2196f3;
    border: 2px solid #03a9f4;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
  }
  label {
    padding: 2px 5px;
    margin-right: 10px;
  }
}
.uploader {
  width: 100%;
  background: #d2d4d3;
  padding: 40px 15px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  font-size: 20px;
  position: relative;
  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 3px dashed #2196f3;
    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }
  i {
    font-size: 85px;
  }
  .file-input {
    width: 200px;
    margin: auto;
    position: relative;
    label,
    input {
      color: #fff;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 3px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
      height: 32px;
      font-size: 15px;
    }
    input {
      opacity: 0;
      z-index: -2;
    }
  }
  .images-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .img-wrapper {
      width: 160px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      justify-content: space-between;
      background: #fff;
      align-items: center;
      position: relative;
      img {
        width: 60%;
      }
    }
    .details {
      font-size: 12px;
      background: #fff;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3px 6px;
      .name {
        overflow: hidden;
        height: 18px;
        font-weight: bold;
      }
    }
  }
  .upload-control {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px;
    padding-bottom: 4px;
    text-align: right;
    button,
    label {
      background: #2196f3;
      border: 2px solid #03a9f4;
      border-radius: 3px;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }
    label {
      padding: 2px 5px;
      margin-right: 10px;
    }
  }
}
</style>
