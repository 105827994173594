import moment from "moment"
import store from "@/store"
export const M_SET_TYPE_WEIGHT_OPTIONS = (state, data) => {
	state.S_TYPE_WEIGHT_OPTIONS = data
}
export const M_SET_SPECIMEN_TRACKING_WEIGHTS = (state, data) => {
	state.S_SPECIMEN_TRACKING_WEIGHTS = data
}

export const M_ADD_MASSIVELY_WEIGHTS = (state, data) => {
	const moreKeysWeight = {
		kg_weight: null,
		pounds_weight: 0,
		type_weight: data.training_id ? 2 : null,
		date_weight: moment().format("YYYY-MM-DD"),
	}
	data = { ...data, ...moreKeysWeight }
	state.S_MASSIVELY_WEIGHTS.unshift(data)
	if (data.photo) {
		store.commit("customLightBox/M_ADD_ARRAY_IMAGES", data)
	}
}

export const M_CLEAN_MASSIVELY_WEIGHTS = (state, data) => {
	state.S_MASSIVELY_WEIGHTS = []
}
export const M_GLOBAL_CHANGES_WEIGHT = (state, { key, value }) => {
	state.S_MASSIVELY_WEIGHTS.forEach((weight) => {
		weight[key] = value
	})
}
export const M_REMOVE_SPECIMEN_WEIGHT = (state, data) => {
	state.S_MASSIVELY_WEIGHTS = state.S_MASSIVELY_WEIGHTS.filter((weight) => weight.specimen_id !== data)
	// console.log(state.S_MASSIVELY_WEIGHTS)
}

export const M_SET_IS_TRAINING = (state, data) => {
	state.S_IS_TRAINING = data
}
