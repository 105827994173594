<template>
	<b-modal centered size="lg" :title="title" :hide-footer="info.readonly" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple small sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Peso</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="pending in pendings" :key="pending.id">
						<b-td class="px-4">
							<SpecimenPlate :specimen="pending.specimen" />
						</b-td>
						<b-td>
							<b-row>
								<b-col>
									<b-input-group prepend="Kg">
										<b-form-input
											v-model="pending.weight"
											type="number"
											:disabled="info.readonly"
										/>
									</b-input-group>
								</b-col>
								<b-col>
									<b-input-group prepend="Lb">
										<b-form-input :value="toPounds(pending.weight)" disabled />
									</b-input-group>
								</b-col>
							</b-row>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="info" @click="save(true)" :disabled="isLoading || !canFinalize">Finalizar</b-button>
			<b-button variant="success" @click="save()" :disabled="isLoading || !hasChanges">Guardar</b-button>
		</template>
		<UpdateTask
			:show="updateTaskModal.show"
			:info="updateTaskModal.info"
			@closing="updateTaskModal.show = false"
			@refresh="$emit('refresh'), handleHidden()"
		/>
	</b-modal>
</template>

<script>
import tasksService from "@/services/tasks.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import UpdateTask from "./UpdateTask.vue"

export default {
	components: { SpecimenPlate, UpdateTask },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		pendings: [],
		cleanPendings: [],
		updateTaskModal: { show: false, info: {} },
	}),
	computed: {
		canFinalize() {
			for (let idx = 0; idx < this.pendings.length; idx++) {
				const p = this.pendings[idx]
				// no sea null o ''
				if (!!!p.weight) return false
			}
			return true
		},
		hasChanges() {
			let prevWeight = this.cleanPendings.reduce((acc, cur) => acc + Number(cur.weight), 0)
			let curWeight = this.pendings.reduce((acc, cur) => acc + Number(cur.weight), 0)
			if (prevWeight != curWeight) {
				return true
			}
			return false
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.title = null
			this.pendings = []
			this.cleanPendings = []
			this.updateTaskModal = { show: false, info: {} }
			this.$emit("closing")
		},
		toPounds(num) {
			return (num * 2.2046).toFixed(2)
		},
		async getAssignedSpecimens() {
			this.isLoading = true
			const data = await tasksService.getPendingsByTask({ task_id: this.info.id })
			this.pendings = data.map((s) => ({
				...s,
				weight: s.kg_weight || null,
			}))
			this.cleanPendings = this.pendings.map((p) => ({ ...p }))
			this.isLoading = false
		},
		async save(finalize = false) {
			if (!finalize) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se guardaran los pesos seleccionados`,
				})
				if (!isConfirmed) return
			}
			this.isLoading = true
			let data = []
			for (let idx = 0; idx < this.pendings.length; idx++) {
				const pending = this.pendings[idx]
				// verificar si hay modificacion
				let isOld = false
				let weight = null
				if (pending.kg_weight == pending.weight) {
					isOld = true
				} else {
					weight = pending.weight
				}

				data.push({
					id: pending.specimen.id,
					new: !isOld,
					tasks_users_specimen_id: pending.tasks_users_specimen_id,
					weight: weight,
				})
			}
			await tasksService.storePendingsData({
				task_user_id: this.info.id,
				type_action: 2,
				specimens: data,
			})
			this.isLoading = false
			!finalize &&
				this.showToast(
					"success",
					"top-right",
					"Tareas",
					"CheckIcon",
					`Se guardaron los pesos de forma correcta`
				)
			this.$emit("refresh")
			if (finalize) {
				this.updateTaskModal.info = { ...this.info, _status: 2 }
				this.updateTaskModal.show = !this.updateTaskModal.show
			} else {
				this.handleHidden()
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `${this.info.readonly ? "Ver" : "Asignar"} pesos / Tarea de ${this.info.assign.name}`
				this.getAssignedSpecimens()
			}
		},
	},
}
</script>
