
import { state } from "@/store/modules/encaste/posturas/state";
import mutations from "@/store/modules/encaste/posturas/mutations"
import actions from "@/store/modules/encaste/posturas/actions"
import getters from "@/store/modules/encaste/posturas/getters"

/** @returns { import('vuex').Module } */
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}