export default [
	{
		path: "/brain/administrative",
		name: "brain-administrative-main",
		redirect: { name: "brain-administrative-petty-cash" },
		component: () => import("@/views/brain/administrative/main.vue"),
		children: [
			{
				path: "petty-cash",
				name: "brain-administrative-petty-cash",
				component: () => import("@/views/brain/administrative/views/petty-cash/PettyCash.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Caja chica",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "categories",
				name: "brain-administrative-categories",
				component: () => import("@/views/brain/administrative/views/categories/Categories.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Categorias",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "sub-categories",
				name: "brain-administrative-sub-categories",
				component: () => import("@/views/brain/administrative/views/sub-categories/SubCategories.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Sub Categorias",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "weight-units",
				name: "brain-administrative-weight-units",
				component: () => import("@/views/brain/administrative/views/weight-units/WeightUnits.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Unidad de medidas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},

			{
				path: "providers",
				name: "brain-administrative-suppliers",
				component: () => import("@/views/brain/administrative/views/providers/Providers.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Proveedores",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},

			{
				path: "brands",
				name: "brain-administrative-brands",
				component: () => import("@/views/brain/administrative/views/branch/Brands.vue"),
				meta: {
					pageTitle: "Administrativo",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Administrativo",
							active: false,
						},
						{
							text: "Marcas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},



			
			// {
			// 	path: "videos",
			// 	name: "brain-others-videos",
			// 	component: () => import("@/views/brain/others/views/videos/SettingsVideos.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Videos",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
			// {
			// 	path: "categories",
			// 	name: "brain-others-categories",
			// 	component: () => import("@/views/brain/others/views/categories/components/MgtCategoryList.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Categorias",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
			// {
			// 	path: "assistance",
			// 	name: "brain-others-assistance",
			// 	component: () => import("@/views/amg/header-bookmarks/attendance/views/AttendanceMain.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Assistance",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
			// {
			// 	path: "tasks",
			// 	name: "brain-others-tasks",
			// 	component: () => import("@/views/brain/others/views/Tasks.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Tareas",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
			// {
			// 	path: "razones",
			// 	name: "brain-others-reasons",
			// 	component: () => import("@/views/brain/others/views/reasons/ReasonsList.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Motivos",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
			// {
			// 	path: "decrest",
			// 	name: "brain-others-decrest",
			// 	component: () => import("@/views/brain/others/views/Decrest.vue"),
			// 	meta: {
			// 		pageTitle: "Otras configuraciones",
			// 		breadcrumb: [
			// 			{
			// 				text: "Cerebro",
			// 				active: false,
			// 				to: "/brain",
			// 			},
			// 			{
			// 				text: "Otros",
			// 				active: true,
			// 			},
			// 			{
			// 				text: "Descreste",
			// 				active: true,
			// 			},
			// 		],
			// 		roles: [1, 2],
			// 	},
			// },
		],
	},
]
