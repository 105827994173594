import types from '@/store/modules/pedigree/types';

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.FETCH_PEDIGREE]: (state, payload) => {
		state.pedigree = payload;
	},

	[types.FETCH_SPECIMEN_FOR_UPDATE]: (state, payload) => {
		state.specimenUpdate = payload;
	}
};
