
import { state } from "@/store/modules/inventory/specimen/state";
import mutations from "@/store/modules/inventory/specimen/mutations"
import actions from "@/store/modules/inventory/specimen/actions"
import getters from "@/store/modules/inventory/specimen/getters"

/** @returns { import('vuex').Module } */
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}