import types from "@/store/modules/orders/types";
import { createObject } from "./state";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.ADD_ITEM_TO_CART]: (state, { item }) => {
		// state.items = items
		// const itemsStorage = JSON.parse(localStorage.getItem("cart-items-order")) || [];
		// localStorage
		// console.log("item", item);
		const existeItem = state.cart.find((it) => it.id == item.id);
		let items = state.cart.map((it) => {
			return {
				...it,
				counter: it.id == item.id ? it.counter + 1 : it.counter,
			};
		});

		if (!existeItem) {
			items.push({ ...item, counter: 1 });
		}
		state.cart = items;
		resetLocalStorage(items);
	},

	[types.INIT_LOCAL_STORAGE]: (state, payload) => {
		const itemsStorage = JSON.parse(localStorage.getItem("cart-items-order")) || [];
		state.cart = itemsStorage;
	},
	[types.DECREMENT_ITEM_TO_CART]: (state, { item }) => {
		let items = state.cart
			.map((it) => {
				return {
					...it,
					counter: it.id == item.id ? it.counter - 1 : it.counter,
				};
			})
			.filter((it) => it.counter != 0);
		state.cart = items;
		resetLocalStorage(items);
	},
	[types.REMOVE_TOTAL_ITEMS_TO_CART]: (state) => {
		// console.log('REMOVE');
		state.cart = [];
		resetLocalStorage([]);
	},
	[types.REMOVE_ITEM_TO_ARRAY]: (state, { id }) => {
		state.cart = state.cart.filter((it) => it.id != id);
		resetLocalStorage(state.cart.filter((it) => it.id != id));
	},
	[types.GET_ITEMS]: (state, { from, to, total, data, current_page }) => {
		state.items = [];
		state.start_page = from;
		state.to_page = to;
		state.total_data = total;
		state.items = data;
		state.jsonSearchItems.page = current_page;
	},

	[types.SET_SEARCH_ITEMS]: (state, { key, value }) => {
		state.jsonSearchItems[key] = value;
	},
	[types.SET_PRICE_CART]: (state, { key, value }) => {
		state.cart[key].price = value;
	},

	[types.SET_CATEGORY_SELECT]: (state, payload) => {
		state.category_select = payload;
	},

	[types.SET_ARRAY_CATEGORY]: (state, payload) => {
		state.arrayCategory = [
			{
				description: null,
				id: null,
				label: "Sin categoria",
				level: "1",
				parent_id: null,
				total: "0",
			},
			...payload,
		];
	},

	[types.RELOAD_CATEGORIES]: (state, payload) => {
		state.reloadCategories = payload;
	},

	[types.FILTER_CATEGORY_ARRAY]: (state, id) => {
		state.arrayCategory = state.arrayCategory.filter(it => it.id != id);
	},
};

const resetLocalStorage = (items) => {
	localStorage.removeItem("cart-items-order");
	localStorage.setItem("cart-items-order", JSON.stringify(items));
};
