import axios from "@/axios";

class FertilidadService {
	async getSpecimenGroups({ search }) {
		const data = await axios.get("/api/insemination", { params: { search } });
		return data.data;
	}
	async getCounterPending() {
		const data = await axios.get("/api/insemination/get-counter-pending");
		return data.data;
	}
	//
	async insertSupervisorSelection({ padrillos, start_date }) {
		try {
			const { data } = await axios.post("/api/insemination/insert-masive-insemination-padrillo", {
				padrillos,
				start_date,
			});
			return data;
		} catch (error) {
			console.log("ER", error.response.data);
			return error.response.data;
		}
	}

	async getPosturesForEncastador() {
		try {
			const { data } = await axios.get("/api/insemination/get-postures-to-insemination-check");
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async insertPosturaFromEncastador({ padrillo_id, madrillas, status_id, insemination_id, leftover_madrillas }) {
		try {
			const { data } = await axios.post("/api/insemination/store", {
				padrillo_id,
				madrillas,
				status_id,
				insemination_id,
				leftover_madrillas
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getReportInseminationByDate({ start_date, end_date, status_id, page, perpage }) {
		try {
			const { data } = await axios.get(`/api/insemination/get-report-insemination-by-date`, {
				params: {
					start_date,
					end_date,
					status_id,
					page,
					perpage,
				},
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getAvailableMadrillasForPadrillo({ padrillo_id }) {
		try {
			const { data } = await axios.get(`/api/insemination/get-madrillas-by-padrillo-for-insemination`, {
				params: {
					padrillo_id,
				},
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getPosturesToInsemination({ search }) {
		try {
			const { data } = await axios.get(`/api/insemination/get-postures-to-insemination`, {
				params: {
					search,
				},
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getMadrillasInseminatedByDateAndPadrillo({ padrillo_id, date }) {
		try {
			const { data } = await axios.get(
				`/api/insemination/get-madrillas-inseminated-by-date-and-padrillo/${padrillo_id}`,
				{
					params: {
						date,
					},
				}
			);
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getStatus() {
		try {
			const { data } = await axios.get(`/api/insemination/get-status`);
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getHistoryByMadrilla({ madrilla_id, start_date, end_date, page, perpage }) {
		try {
			const { data } = await axios.get(`/api/insemination/get-history-by-madrilla/${madrilla_id}`, {
				params: {
					start_date,
					end_date,
					page,
					perpage,
				},
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}

	async getHistoryByPadrillo({ padrillo_id, start_date, end_date, page, perpage }) {
		try {
			const { data } = await axios.get(`/api/insemination/get-history-by-padrillo/${padrillo_id}`, {
				params: {
					start_date,
					end_date,
					page,
					perpage,
				},
			});
			return data;
		} catch (error) {
			return error.response;
		}
	}
}

export default new FertilidadService();
