import tournamentService from "@/services/tournament/default.service"

export default {
	namespaced: true,
	state: {
		S_PENDING_COUNTER: 0,
		S_TOURNAMENTS_COUNTERS: {
			actual: 0,
			next: 0,
			played: 0,
			canceled: 0,
			week: 0
		}
	},
	getters: {
		G_PENDING_COUNTER(state) {
			return state.S_PENDING_COUNTER
		},
		G_TOURNAMENTS_COUNTERS(state){
			return state.S_TOURNAMENTS_COUNTERS
		}
	},
	mutations: {
		M_UPDATE_PENDING_COUNTER(state, payload) {
			state.S_PENDING_COUNTER = payload
		},

		M_UPDATE_TOURNAMENTS_COUNTERS(state, payload) {
			state.S_TOURNAMENTS_COUNTERS = payload
		},
	},
	actions: {
		async A_TOURNAMENTS_COUNTERS({ commit }, { start, end, weekFrom, weekTo }) {
			try {
				const { data } = await tournamentService.getCounters({
					start_temporary_date: start,
					end_temporary_date: end,
					start_week: weekFrom,
					end_week: weekTo,
				})
				let counters = {
					actual : data.find((c) => c.id == 1).counter,
					next : data.find((c) => c.id == 5).counter,
					played : data.find((c) => c.id == 3).counter,
					canceled : data.find((c) => c.id == 4).counter,
					week : data.find((c) => c.id == 6).counter
				}
				commit('M_UPDATE_TOURNAMENTS_COUNTERS', counters)
			} catch (error) {
				throw error;
			}
		},

		async A_PENDING_EVENTS_COUNTER({ commit }) {
			try {
				const { data: pendingCount } = await tournamentService.getPendingEventsCounter()
				let pendings = pendingCount[0].count
				commit('M_UPDATE_PENDING_COUNTER', pendings)
			} catch (error) {
				throw error;
			}
		},
	},
}
