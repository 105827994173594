// const getBaseUrl = document.head.querySelector('meta[name="api-base-url"]').content;
export default {
    namespaced: true,
    state: {
        images: [],
        imagesVideoteca: [],
        openModal: false,
        indexImage: null,
        text: {
            title: null,
            url: null
        },
        upload: false,
        uploadImages: [],
        backgroundUpload: false,
        toggleOpenGallery: false,
        uploadFinished: false,
        videoteca: false,
        isUpdatingFeature: false,
        id_specimen: null,
        urlBackendNode: process.env.VUE_APP_URL_NODE
    },
    getters: {
        getIsUpdatingFeature: state => state.isUpdatingFeature,
    },
    mutations: {
        // 'SET_AMOUNT_LOTE_NOTIFICATIONS'(state, payload) {
        //     state.amountLoteNotifications = payload
        // },
        'SET_IMAGES' (state, payload) {
            state.images = payload
        },
        'SET_VIDEOTECA'(state, payload) {
            state.videoteca = payload
        },
        'SET_IMAGES_VIDEOTECA' (state, payload) {
            state.imagesVideoteca = payload;
        },
        'TOGGLE_MODAL' (state, payload) {
            state.openModal = !state.openModal
        },
        'SET_INDEX_IMAGE' (state, payload) {
            state.indexImage = payload
        },
        'SET_TEXT'(state, payload) {
            state.text = payload
        },
        'SET_UPLOAD_IMAGES'(state, payload) {
            state.uploadImages = payload
        },
        'DELETE_UPLOAD_FILE'(state, payload) {
            state.uploadImages = state.uploadImages.filter((file, index) => index != payload)
        },
        'TOGGLE_BG-UPLOAD' (state, payload) {
            state.backgroundUpload = payload;
        },
        'LOADING_BACKGROUND' (state, payload) {
            state.backgroundUpload = true
            state.toggleOpenGallery = false
        },
        'INIT_TOGGLE_UPLOAD' (state, payload) {
            state.upload = payload;
        },
        'TOGGLE_MODAL_GALLERY' (state, payload) {
            state.toggleOpenGallery = payload
        },
        'TOGGLE_UPLOAD_GALLERY'(state, payload) {
            state.uploadFinished = payload
        },
        'TOGGLE_IS_UPDATING_FEATURE'(state, payload) {
            state.isUpdatingFeature = payload
        },
        'SET_ID_SPECIMEN'(state, payload) {
            state.id_specimen = payload;
        }
    },
    actions: {
        // getLoteNotifications({ commit }, payload) {
        //     axios.get('/api/lote/counter').then(response=>{
        //         commit('SET_AMOUNT_LOTE_NOTIFICATIONS', response.data);
        //     })
        // },
        setVideoteca({commit}, payload) {
            commit('SET_VIDEOTECA', payload)
        },
        setImages({ commit }, payload){
            commit('SET_IMAGES', payload)
        },
        setImagesVideoteca({commit}, payload) {
            commit('SET_IMAGES_VIDEOTECA', payload)
        },
        toggleModal({commit}) {
            commit('TOGGLE_MODAL')
        },
        setIndexImage({commit}, payload) {
            commit('SET_INDEX_IMAGE', payload)
        },
        setTextImage({commit}, payload){
            commit('SET_TEXT', payload)
        },
        setUploadImages({commit}, payload) {
            commit('SET_UPLOAD_IMAGES', payload)
        },
        deleteUploadFile({commit}, payload) {
            commit('DELETE_UPLOAD_FILE', payload)
        },
        toggleBgUpload({commit}, payload) {
            commit('TOGGLE_BG-UPLOAD', payload)
        },

        loadingBackground({commit}, payload) {
            console.log('load');
            commit('LOADING_BACKGROUND', payload)
        },
        initToggleUpload({commit}, payload) {
            commit('INIT_TOGGLE_UPLOAD', payload)
        },
        toggleModalGallery({commit}, payload) {
            commit('TOGGLE_MODAL_GALLERY', payload)
        },
        toggleUploadGallery({commit}, payload) {
            commit('TOGGLE_UPLOAD_GALLERY', payload)
        },
        setIdSpecimen({commit}, payload) {
            commit('SET_ID_SPECIMEN', payload)
        }

    },
}
