import utilitiesService from "@/services/utilities.service";
import moment from "moment";

export default {
	namespaced: true,
	state: {
		seasons: [],
		disabled: false,
	},
	getters: {},
	mutations: {},
	actions: {
		async getPeriods(store) {
			let { data } = await utilitiesService.getSeasons();
			let seasons = data.filter((item) => item.end != null);
			const { end } = seasons[0];
			// console.log('DATE', moment().format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'));
			if (moment().format("YYYY-MM-DD") > moment(end).format("YYYY-MM-DD")) {
				store.state.disabled = true;
			} else {
				store.state.disabled = false;
			}
			store.state.seasons = seasons;
		},
	},
};
