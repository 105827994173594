export default [
	{
		path: "/ejemplares",
		name: "specimens-component",
		component: () => import("@/views/amg/specimens/SpecimensComponent.vue"),
		redirect: { name: "ejemplares-sr" },
		meta: {
			layout: "vertical",
			pageTitle: "Ejemplares",
			breadcrumb: [
				{
					text: "Ejemplares",
					active: true,
				},
			],
			roles: [1, 4, 6],
		},

		children: [
			{
				path: "",
				name: "ejemplares-sr",
				component: () => import("@/views/amg/specimens/SpecimensMainPage.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Ejemplares",
					roles: [1, 4, 6, 7],
				},
			},
			{
				path: "crear",
				name: "create-specimen",
				component: () => import("@/views/amg/specimens/CreateSpecimen.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Agregar ejemplar",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: false,
							to: "/ejemplares",
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "crear-varios",
				name: "create-many-specimens",
				component: () => import("@/views/amg/specimens/CreateMany.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Agregar varios ejemplares",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: false,
							to: "/ejemplares",
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "editar/:specimen_id",
				name: "editar-ejemplar",
				component: () => import("@/views/amg/specimens/CreateSpecimen.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Editar ejemplar",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: false,
							to: "/ejemplares",
						},
						{
							text: "Editar",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
			{
				path: "detalle/:specimen_id",
				name: "detalle-ejemplar",
				component: () => import("@/views/amg/specimens/dashboard/Main.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Detalle ejemplar",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: false,
							to: "/ejemplares",
						},
						{
							text: "Detalle",
							active: true,
						},
					],
					roles: [1, 4, 6, 7],
				},
			},
			{
				path: "cambiar-estaddos",
				name: "changeStatusMassively",
				component: () => import("@/views/amg/specimens/specimens-changes-status-massively/MainStatus.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Cambiar estado de ejemplares",
					breadcrumb: [
						{
							text: "Ejemplares",
							active: false,
							to: "/ejemplares",
						},
						{
							text: "Estados",
							active: true,
						},
					],
					roles: [1, 4, 6],
				},
			},
		],
	},
];
