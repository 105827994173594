const inventorySpecimenMutations = {
	TOGGLE_CHECK_SPECIMEN_MATCH: 'TOGGLE_CHECK_SPECIMEN_MATCH',
	GET_INVENTORIES: 'GET_INVENTORIES',
	SELECT_INVENTORY: 'SELECT_INVENTORY',
	ADD_PLATE: 'ADD_PLATE',
	UPDATE_INVENTORY: 'UPDATE_INVENTORY',
	GET_MATCH: 'GET_MATCH',
	SET_FILTERS: 'SET_FILTERS',
	GET_UN_MATCH: 'GET_UN_MATCH',
	TOGGLE_CHECK_UNMATCH: 'TOGGLE_CHECK_UNMATCH',
	GET_COUNTERS_INVENTORY: 'GET_COUNTERS_INVENTORY',
	REMOVE_PLATE_OR_CINTILLO: 'REMOVE_PLATE_OR_CINTILLO',
	UPDATE_INDEX_TAB: 'UPDATE_INDEX_TAB',
	UPDATE_INDEX_INVENTORY: 'UPDATE_INDEX_INVENTORY',
	GET_DETAILS: 'GET_DETAILS',
	TOGGLE_RELOAD: 'TOGGLE_RELOAD',
	CLEAN_DATA: 'CLEAN_DATA',
	ACTIVE_CHILDREN_INVENTORIES: 'ACTIVE_CHILDREN_INVENTORIES',
	UPDATE_COMMENT: 'UPDATE_COMMENT',
	CLEAN_SEARCH: 'CLEAN_SEARCH'
};

export default Object.freeze({
	...inventorySpecimenMutations,
});
