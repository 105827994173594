const notificationsMutations = {
	GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
	SET_COUNT_NOTIFICATIONS: 'SET_COUNT_NOTIFICATIONS',
	SET_PAGE: 'SET_PAGE',
	SET_LAST_PAGE: 'SET_LAST_PAGE',
	SET_LOADING_SCROLL: 'SET_LOADING_SCROLL',
	RELOAD_ANIMATION: 'RELOAD_ANIMATION',
};

export default Object.freeze({
	...notificationsMutations,
});
