/** @ts-check */
import { showToast } from "@/helpers/toast";
import types from "./types";
import A1 from "../../../assets/ringtone/notification.mp3";
import * as Tone from "tone";

import notificationsService from "@/services/notifications.service";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async getNotifications({ commit }, { page = 1, perpage = 10 }) {
		const {
			message,
			notifications: { data, last_page, current_page },
		} = await notificationsService.getNotifications({
			page,
			perpage,
		});

		commit(types.GET_NOTIFICATIONS, data);
		commit(types.SET_LAST_PAGE, last_page);
	},

	async getCount({ commit }, data) {
		const { message, count } = await notificationsService.getCount();
		commit(types.SET_COUNT_NOTIFICATIONS, count);
	},

	startRingtone({ commit }, data) {
		const player = new Tone.Player(A1).toDestination();
		Tone.loaded().then(() => {
			player.start();
		});
	},

	reloadAnimation({ commit }, data) {
		commit(types.RELOAD_ANIMATION, data);

		setTimeout(() => {
			commit(types.RELOAD_ANIMATION, false);
		}, 1000);
	},
};
