import axios from "@/axios";

class TournamentService {
	constructor() {
		this.module = "/api/tournament";
	}

	/**
	 * @param {number|string} url
	 */
	module_uri(url) {
		return `${this.module}/${url}`;
	}

	/**
	 * @param {number} id The tournament id
	 */
	async getTournament(id) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get/${id}`));
			return response;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * @param {number} id The tournament id
	 * @param {Object<string, any>} tournament The tournament object for update
	 */
	async updateTournament(id, tournament) {
		try {
			const { data: response } = await axios.post(this.module_uri(`update`), {
				id,
				tournament,
			});
			return response;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * @param {number} id The tournament id
	 * @param {number} user_id The id of the user who deletes it
	 */
	async deleteTournament(id, user_id) {
		try {
			const { data: response } = await axios.post(this.module_uri(`delete`), {
				tournament_id: id,
				deleted_by: user_id,
			});
			console.trace(response);
			return response;
		} catch (error) {
			throw new Error(error);
		}
	}

	/**
	 * @param {number} id The tournament id
	 */
	async getTournamentContacts(id) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-tournament-contacts/${id}`));
			return response;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * @param {number} id The tournament id
	 */
	async getTournamentBanks(id) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-tournament-banks/${id}`));
			return response;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * @param {number} id The tournament id
	 */
	async getTournamentStagesForSelect(id) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-tournament-stages-list/${id}`));
			return response;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * @param {string} year The year of filtered
	 * @return { Object<string, number> }
	 */
	async getTournamentPrizesByYear(year) {
		try {
			const { data: response } = await axios.post(this.module_uri(`get-tournament-prizes-by-year`), { year });
			return response;
		} catch (error) {
			throw error;
		}
	}

	async getAllTournamentStages() {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-tournaments-stages`));
			return response;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * @param {number} id The tournament id
	 * @returns
	 */
	async getTournamentDates(id) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-tournament-dates/${id}`));
			return response;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * @param {number} file_id The tournament file id
	 * @param {number} user_id The id of the user who deletes it
	 * @return { any }
	 */
	async deleteTournamentFile(file_id, user_id) {
		try {
			const isOk = await axios.post(this.module_uri("delete-tournament-file"), {
				tournament_file_id: file_id,
				deleted_by: user_id,
			});
			return isOk;
		} catch (error) {
			throw error;
		}
	}

	/**
	 *
	 * @param {FormData} files Files to save
	 * @return {any}
	 */
	async saveTournamentFiles(files) {
		try {
			const { data: response } = await axios.post(this.module_uri("save-tournament-files"), files);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async savePlace(body) {
		const data = await axios.post(`/api/tournament/save-place`, body);
		return data;
	}
	async getPlaces() {
		const data = await axios.get(`/api/tournament/get-places`);
		return data;
	}

	async saveTournament(body) {
		const data = await axios.post(`/api/tournament/save`, body);
		return data;
	}
	async savePrizeToTournamentPlace(body) {
		const data = await axios.post(`/api/tournament/save-prize-to-tournament-place`, body);
		return data;
	}
	async updatePrizeToTournamentPlace(body) {
		const data = await axios.post(`/api/tournament/update-prize-to-tournament-place`, body);
		return data;
	}
	async saveTournamentStageSpecimenResult(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage-specimen-result`, body);
		return data;
	}
	async saveTournamentStage(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage`, body);
		return data;
	}
	async getTournamentStages(body) {
		const data = await axios.post(`/api/tournament/get-stages-by-tournament`, body);
		return data;
	}
	async savePreparador(body) {
		const data = await axios.post(`/api/tournament/save-preparador`, body);
		return data;
	}
	async getPreparadores() {
		const data = await axios.get(`/api/tournament/get-preparadores`);
		return data;
	}
	async deleteTournamentStage(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-stage`, body);
		return data;
	}

	async deleteTournamentStageDate(body) {
		try {
			const response = await axios.post(`/api/tournament/delete-tournament-stage-date`, body);
			return response
		} catch (error) {
			throw error;
		}
	}

	async setTournamentStageClasificado(body) {
		const data = await axios.post(`/api/tournament/set-tournament-stage-clasificado`, body);
		return data;
	}
	async createTournamentStage(body) {
		const data = await axios.post(`/api/tournament/create-tournament-stage`, body);
		return data;
	}
	async addRoostersToTournamentStage(body) {
		const data = await axios.post(`/api/tournament/add-roosters-to-tournament-stage`, body);
		return data;
	}
	async getSpecimensUsedByFront(param) {
		const data = await axios.get(`/api/tournament/get-specimens-used-by-front/${param}`);
		return data;
	}
	async getRazorsUsedByTournament() {
		const data = await axios.get(`/api/tournament/get-razors-used-by-tournament`);
		return data;
	}
	async updateTournamentFlyer(body) {
		const data = await axios.post(`/api/tournament/update-tournament-flyer`, body);
		return data;
	}
	async getTournamentAwards(body) {
		const data = await axios.post(`/api/tournament/get-tournament-awards`, body);
		return data;
	}
	async deleteTournamentAward(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-award`, body);
		return data;
	}
	async getTournamentFiles(body) {
		const data = await axios.post(`/api/tournament/get-tournament-files`, body);
		return data;
	}

	async getTournamentStageDates(body) {
		const data = await axios.post(`/api/tournament/get-tournament-stage-dates`, body);
		return data;
	}
	async saveTournamentStageDates(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage-dates`, body);
		return data;
	}
	async updateTournamentStageDate(body) {
		const data = await axios.post(`/api/tournament/update-tournament-stage-date`, body);
		return data;
	}
	async saveTournamentFront(body) {
		const data = await axios.post(`/api/tournament/save-tournament-front`, body);
		return data;
	}
	async getFront(body) {
		const data = await axios.post(`/api/tournament/get-front`, body);
		return data;
	}
	async getTournamentDate(body) {
		const data = await axios.post(`/api/tournament/get-tournament-date`, body);
		return data;
	}
	async deleteTournamentFront(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-front`, body);
		return data;
	}
	async classifyDate(body) {
		const data = await axios.post(`/api/tournament/classify-date`, body);
		return data;
	}
	async setDateToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-date-to-tournament-date`, body);
		return data;
	}
	async setColiseumToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-coliseum-to-tournament-date`, body);
		return data;
	}
	async setCityToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-city-to-tournament-date`, body);
		return data;
	}
	async deleteTournamentDate(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-date`, body);
		return data;
	}
	async setTournamentDateInformation(body) {
		const data = await axios.post(`/api/tournament/set-tournament-date-information`, body);
		return data;
	}
	async editTournamentFrontSpecimen(body) {
		const data = await axios.post(`/api/tournament/edit-tournament-front-specimen`, body);
		return data;
	}
	async deleteTournamentFrontSpecimen(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-front-specimen`, body);
		return data;
	}
	async getTournamentRecognitions(body) {
		const data = await axios.post(`/api/tournament/get-tournament-recognitions`, body);
		return data;
	}

	/**
	 * @param {number} id
	 * @param {number} user_id
	 */
	async deleteTournamentFlyer(id, user_id) {
		try {
			await axios.post(this.module_uri("delete-tournament-flyer"), {
				id,
				user_id,
			});
		} catch (error) {
			throw error;
		}
	}

	async saveTournamentFlyers(body) {
		const { data } = await axios.post(`/api/tournament/save-tournament-flyers`, body);
		return data;
	}
	async saveMaterialAward(body) {
		const { data } = await axios.post(`/api/tournament/save-material-award`, body);
		return data;
	}
	async updateTournamentFront(body) {
		const data = await axios.post(`/api/tournament/update-tournament-front`, body);
		return data;
	}
	async getAwardsAndRecognitions(type) {
		const { data } = await axios.get(`/api/tournament/get-awards-and-recognitions/${type}`);
		return data;
	}

	async saveTournamentResponsable(body) {
		const { data } = await axios.post(`/api/tournament/save-tournament-responsable`, body);
		return data;
	}

	async getTournamentResponsables() {
		const { data } = await axios.get(`/api/tournament/get-tournament-responsables`);
		return data;
	}

	async getSumRoosterQuantityByStatus() {
		const { data: response } = await axios.get(this.module_uri(`get-sum-rooster-quantity-by-status`));
		return response;
	}

	/**
	 * @param {string|number} year The year of filtered
	 * @return { Object<string, any }
	 */
	async getTourmanentCounter(year) {
		try {
			const { data: response } = await axios.get(this.module_uri(`get-counter?yearIn=${year}`));
			return response;
		} catch (error) {
			throw error;
		}
	}

	async changeStatusTournament(body) {
		const { data } = await axios.post(`/api/tournament/change-status-tournament`, body);
		return data;
	}
	async updateFrontData(body) {
		const { data } = await axios.post(`/api/tournament/update-front-data`, body);
		return data;
	}
	async saveTypeFight(body) {
		const { data } = await axios.post(`/api/tournament/save-type-fight`, body);
		return data;
	}
	async getCalendarTournament(body) {
		const { data } = await axios.post(`/api/tournament/get-calendar`, body);
		return data;
	}

	/**
	 * @param {Object<string, any>}
	 * @return {Object}
	 */
	async addBankAccountsTournament({ tournament_id, accounts }) {
		try {
			const { data: response } = await axios.post(this.module_uri(`add-bank-accounts-tournament`), {
				tournament_id: tournament_id,
				bank_accounts: accounts,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}

	async getCalendarDateFronts(body) {
		const { data } = await axios.post(`/api/tournament/get-fronts-calendar`, body);
		return data;
	}

	/**
	 * @param {number} id Account id
	 * @return {Object<any, any>}
	 */
	async deleteBankAccountTournament(id) {
		try {
			const { data: response } = await axios.post(this.module_uri("delete-bank-account-tournament"), {
				account_id_tournament: id,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
	async updateBankAccountTournament(body) {
		const { data } = await axios.post(`/api/tournament/update-bank-account-tournament`, body);
		return data;
	}
	async addTournamentFrontParticipants(body) {
		const { data } = await axios.post(`/api/tournament/add-tournament-front-participants`, body);
		return data;
	}
	async deleteParticipantTournamentFront(body) {
		const { data } = await axios.post(`/api/tournament/delete-participant-tournament-front`, body);
		return data;
	}
	async getParticipants() {
		const { data } = await axios.get(`/api/tournament/get-participants`);
		return data;
	}
	async registerParticipants(body) {
		const { data } = await axios.post(`/api/tournament/register-participants`, body);
		return data;
	}
	async registerOthersMotive(body) {
		const { data } = await axios.post(`/api/tournament/register-others-motives`, body);
		return data;
	}
	async registerTravelExpenses(body) {
		const { data } = await axios.post(`/api/tournament/register-travel-expenses`, body);
		return data;
	}
	async getTravelExpensesByStageDate(id) {
		const { data } = await axios.get(`/api/tournament/get-travel-expenses-by-stage-date/${id}`);
		return data;
	}

	async getOthersMotive() {
		const { data } = await axios.get(`/api/tournament/get-others-motives`);
		return data;
	}
	// No usar, oslo para generar colores
	async generateColors(body) {
		const { data } = await axios.get(`/api/tournament/generate-colors`);
		return data;
	}
	async generateColors(body) {
		const { data } = await axios.get(`/api/tournament/generate-colors`);
		return data;
	}

	async calendarFutureDates(body) {
		const { data } = await axios.post(`/api/tournament/calendar-future-dates`, body);
		return data;
	}

	async getTournaments({ yearIn, statusIn }) {
		const { data } = await axios.post(`/api/tournament/get-tournaments`, {
			yearIn,
			statusIn,
		});
		return data;
	}

	async deleteTournament({ tournament_id, deleted_by }) {
		const { data } = await axios.post(`/api/tournament/delete`, {
			tournament_id,
			deleted_by,
		});
		return data;
	}

	async getTournamentInProgress() {
		const { data } = await axios.post(`${this.module}/get-tournament-in-progress`);
		return data;
	}

	//
}

export default new TournamentService();
