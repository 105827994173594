export default [
	{
		path: "/ordenes",
		name: "orders-sr",
		component: () => import("@/views/amg/orders/OrdersMain.vue"),
		children: [
			{
				path: "",
				name: "orders-list-sr",
				component: () => import("@/views/amg/orders/components/OrderList.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Compras",
					breadcrumb: [
						{
							text: "Listado",
							active: true,
						},
					],
					roles: [1,2]
				},
			},
			{
				path: "carrito",
				name: "orders-cart-sr",
				component: () => import("@/views/amg/orders/components/CreateOrder.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Compra",
					breadcrumb: [
						{
							text: "Compras",
							active: false,
							to: "/ordenes",
						},
						{
							text: "Carrito",
							active: true,
						},
					],
					roles: [1,2]
				},
			},
		],
	},
];
