import Vue from 'vue';

const url = '/api/utilities/'

class UtilitiesService {

    async getUsersByRole(rolId) {
        try {
            return await Vue.axios.get(`${url}get-users-by-role`, {
                params: { role_id: rolId }
            });
        } catch (err) {
            console.error(err)
        }
    };

    async getUnits() {
		try {
			const { data } = await axios.get(`${url}get-um`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

    async getCurrency() {
		try {
			const { data } = await axios.get(`${url}get-currency`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async getSeasons() {
		try {
			const { data } = await axios.get(`/api/season`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new UtilitiesService()

