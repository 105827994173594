import store from "@/store";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async getNotifications({ commit }, jsonSearchItems) {
	},
};
