<template>
  <!-- isGuilty se refiere si es culpable de la infertilidad -->
  <div
    :class="['d-flex flex-column justify-content-end p-1', isGuilty ? 'shadow' : 'shadow-black']"
    style="border-radius: 5px; height: 100%"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="text-left">
        <!-- SECCION DEL PADRILLO -->
        <span>
          {{ isPadrillo ? postura.padrillo_alias : postura.madrilla_alias }}
        </span>

        <br />
        <div @click="linkSpecimen(isPadrillo, postura)">
          <span
            :id="`tooltip2-target-${isPadrillo ? 'padrillo' : 'madrilla'}-${index}`"
            class="badge text-white clickable"
            style="min-width: 60px; max-width: 80%; background: #474b4e"
            >{{ isPadrillo ? postura.placa_padrillo : postura.placa_madrilla }}</span
          >
          <!-- <b-tooltip
            :target="`tooltip2-target-${
              isPadrillo ? 'padrillo' : 'madrilla'
            }-${index}`"
            triggers="hover"
            placement="bottom"
          >
            Detalle ejemplar
          </b-tooltip> -->
        </div>
        <br />
        <span
          class="badge text-white"
          style="min-width: 60px; max-width: 80%; background: #ff9800; margin-top: 7px"
          :id="`tooltip-target-${isPadrillo ? 'padrillo' : 'madrilla'}-${index}`"
          >{{ isPadrillo ? postura.posturas_quantity_padrillo : postura.posturas_quantity_madrilla }}</span
        >
        <br />
        <!-- <b-tooltip
          :target="`tooltip-target-${
            isPadrillo ? 'padrillo' : 'madrilla'
          }-${index}`"
          triggers="hover"
          placement="bottom"
        >
          Cantidad de posturas en la temporada
          {{ postura.start_of_season }} - {{ postura.end_of_season }}
        </b-tooltip> -->
        <span
          v-if="isPadrillo"
          class="badge text-white"
          style="width: 60px; background: #0097a7; margin-top: 7px"
          :id="`tooltip-inprocess-padrillo-${index}`"
          >{{ postura.posturas_in_process_padrillo }}</span
        >
        <!-- <b-tooltip
          :target="`tooltip-inprocess-padrillo-${index}`"
          triggers="hover"
          placement="bottom"
        >
          Cantidad de posturas en proceso
        </b-tooltip> -->
      </div>
      <!-- FOTO DEL EJEMPLAR -->
      <b-avatar
        size="40"
        button
        :src="isPadrillo ? postura.padrillo_thumb : postura.thumb"
        icon="twitter"
        @click="
          viewImage(
            isPadrillo ? postura.padrillo_photo : postura.photo,
            isPadrillo ? postura.placa_padrillo : postura.placa_madrilla,
            isPadrillo ? postura.padrillo_alias : postura.madrilla_alias,
            isPadrillo ? postura.padrillo_id : postura.madrilla_id
          )
        "
      />
    </div>

    <!-- <switch-component
        v-model="postura.madrilla_status_post"
        :color="postura.madrilla_status_post ? '#007BFF' : '#DC3545'"
        v-if="postura.status == 'EN PROCESO' && (isAdmin || isEncastador)"
        @toggle="openAddReasonModal(postura, 2)"
        :class="!postura.madrilla_status_post ? 'disabled-switch' : ''"
      ></switch-component> -->

    <span :id="`turn-off-madrilla-tooltip${index}`" style="width: fit-content">
      <b-form-checkbox
        v-if="posturaIsInProcess && postura.status == 'EN PROCESO' && (isAdmin || isEncastador) && isMadrilla"
        v-model="postura.madrilla_status_post"
        class="custom-control-primary mt-1 text-left"
        name="check-button-madrilla"
        switch
        :disabled="!postura.madrilla_status_post"
        @input="onChangeSwitch"
      />
    </span>
    <b-tooltip :target="`turn-off-madrilla-tooltip${index}`" placement="bottomleft"> Apagar madrilla </b-tooltip>
    <span :id="`turn-off-padrillo-tooltip${index}`" style="width: fit-content">
      <b-form-checkbox
        v-if="posturaIsInProcess && postura.status == 'EN PROCESO' && (isAdmin || isEncastador) && isPadrillo"
        v-model="postura.padrillo_status_post"
        class="custom-control-primary mt-1 text-left"
        name="check-button-padrillo"
        switch
        :disabled="!postura.padrillo_status_post"
        @input="onChangeSwitch"
      />
    </span>
    <b-tooltip :target="`turn-off-padrillo-tooltip${index}`" placement="bottomleft"> Apagar padrillo </b-tooltip>
    <custom-light-box
      :items="images"
      :index="indexShow"
      :effect="'fade'"
      @close="indexShow = null"
      @on-click-info="openInfo"
    />
  </div>

  <!-- </b-card> -->
</template>

<script>
import moment from "moment";
export default {
  props: {
    postura: Object,
    index: Number,
    isPadrillo: Boolean,
    isMadrilla: Boolean,
    posturaIsInProcess: Boolean,
    isGuilty: Boolean,
  },
  data() {
    return {
      data_specimen: {
        type: "",
        url: "",
        plaque: "",
        alias: "",
        id: "",
        size: 300000000,
      },
      //       last_left: false,
      // last_right: false,
      modal_image: false,
      images: [],
      indexShow: null,
    };
  },
  methods: {
    moment() {
      return moment();
    },
    onChangeSwitch() {
      this.$emit("onChangeSwitch");
    },
    seeimage(type, url, plaque, alias, id) {
      this.modal_image = true;
      this.data_specimen.type = type;
      this.data_specimen.url = url;
      this.data_specimen.plaque = plaque;
      this.data_specimen.alias = alias;
      this.data_specimen.id = id;
    },
    viewImage(url, plaque, alias, id) {
      this.images = [
        {
          src: url,
          title: alias + "  |  " + plaque,
          id: id,
        },
      ];
      this.indexShow = 0;
    },
    openInfo(item) {
      this.$router.push({
        name: "detalle-ejemplar",
        params: { specimen_id: item.id },
      });
    },
    linkSpecimen(isPadrillo, postura) {
      const id = isPadrillo ? postura.padrillo_id : postura.madrilla_id;
      window.open(`/ejemplares/detalle/${id}`, "_blank");
    },
  },
};
</script>

<style scoped>
.shadow-black {
  -webkit-box-shadow: 5px 5px 17px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 17px -4px rgba(0, 0, 0, 0.2);
}
.shadow-red {
  -webkit-box-shadow: 0px 1px 13px 0px #ea5455;
  box-shadow: 0px 1px 13px 0px #ea5455;
}
</style>
