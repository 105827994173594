export default [
	{
		path: "/inventario",
		name: "inventory-sr",
		redirect: "square-up-inventory-placas",
		component: () => import("@/views/amg/inventory/InventoryMain.vue"),
		meta: {
			pageTitle: "Inventario",
			breadcrumb: [
				{
					text: "Inventario",
					active: true,
				},
			],
		},
		children: [
			{
				path: "/inventario/ejemplares",
				name: "inventory-specimens-sr",
				component: () => import("@/views/amg/inventory/specimens/InventorySpecimensMain.vue"),
				meta: {
					pageTitle: "Inventario",
					breadcrumb: [
						{
							text: "Cuadrar",
							active: true,
						},
					],
					roles: [1,2],
				},
				children: [
					{
						path: "/",
						name: "inventory-specimens-list-sr",
						component: () => import("@/views/amg/inventory/specimens/views/ViewInventorySpecimens.vue"),
						meta: {
							pageTitle: "Inventario",
							breadcrumb: [
								{
									text: "Inventario",
									active: true,
								},
								{
									text: "Ejemplares",
									active: false,
									to: "/inventario/ejemplares",
								},
							],
							roles: [1,2],
							module: 1,
						},
					},
					{
						path: "/inventario/ejemplares/:inventory_id",
						name: "inventory-specimens-by-id-sr",
						component: () => import("@/views/amg/inventory/specimens/views/ViewInventorySpecimensById.vue"),
						meta: {
							pageTitle: "Inventario",
							breadcrumb: [
								{
									text: "Inventario",
									active: true,
								},
								{
									text: "Ejemplares",
									active: false,
									to: "/inventario/ejemplares",
								},
								{
									text: "Bloques",
									active: true,
								},
							],
							roles: [1,2],
							module: 1,
						},
					},
					{
						path: "/inventario/ejemplares/parciales/:inventory_id",
						name: "inventory-specimens-by-id-parciales-sr",
						component: () =>
							import("@/views/amg/inventory/specimens/views/ViewInventorySpecimensChild.vue"),
						meta: {
							pageTitle: "Inventario",
							breadcrumb: [
								{
									text: "Inventario",
									active: true,
								},
								{
									text: "Ejemplares",
									active: false,
									to: "/inventario/ejemplares",
								},
								{
									text: "Detalles",
									active: true,
								},
							],
							roles: [1,2],
							module: 1,
						},
					},
				],
			},
			{
				path: "/inventario/pollos",
				name: "inventory-chicks-sr",
				component: () => import("@/views/amg/inventory/chicks/InventoryChicksMain.vue"),
				meta: {
					pageTitle: "Inventario",
					breadcrumb: [
						{
							text: "Cuadrar",
							active: true,
						},
					],
					roles: [1,2],
				},
				children: [
					{
						path: "/",
						name: "inventory-chicks-list-sr",
						component: () => import("@/views/amg/inventory/chicks/views/ViewInventoryChicks.vue"),
						meta: {
							pageTitle: "Inventario",
							breadcrumb: [
								{
									text: "Inventario",
									active: true,
								},
								{
									text: "Pollos",
									active: false,
									to: "/inventario/pollos",
								},
							],
							roles: [1,2],
							module: 2,
						},
					},
					{
						path: "/inventario/pollos/:inventory_id",
						name: "inventory-chicks-by-id-sr",
						component: () => import("@/views/amg/inventory/specimens/views/ViewInventorySpecimensById.vue"),
						meta: {
							pageTitle: "Inventario",
							breadcrumb: [
								{
									text: "Inventario",
									active: true,
								},
								{
									text: "Pollos",
									active: false,
									to: "/inventario/pollos",
								},
								{
									text: "Detalles",
									active: true,
								},
							],
							roles: [1,2],
							module: 2,
						},
					},
				],
			},
		],
	},
];
