import axios from "@/axios";

const url = "/api/videos-speed/";

class ConfigVideosService {
	async index(status) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: {
					status,
				},
			});
			return data;
		} catch (err) {
			return { error: err.response.data.error.message };
		}
	}

	async updateSpeedVideoStatus(id) {
		try {
			const { data } = await axios.put(`${url}update-speed-video-status/${id}`, {});
			return data;
		} catch (err) {
			return { error: err.response.data.error.message };
		}
	}

	async updateSpeedVideoDefault(id) {
		try {
			const { data } = await axios.put(`${url}update-speed-video-default/${id}`, {});
			return data;
		} catch (err) {
			return { error: err.response.data.error.message };
		}
	}

	//
}
export default new ConfigVideosService();
