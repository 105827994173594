
import { state } from "@/store/modules/headers/state";
import mutations from "@/store/modules/headers/mutations"
import actions from "@/store/modules/headers/actions"
import getters from "@/store/modules/headers/getters"

/** @returns { import('vuex').Module } */
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}