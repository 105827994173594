import types from "@/store/modules/encaste/posturas/types";
import { createObject } from "./state";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	// [types.FETCH_PEDIGREE]: (state, payload) => {
	// 	state.pedigree = payload;
	// },

	[types.SET_YEAR_MAIN]: (state, payload) => {
		console.log({ payload });
		// state.year = isNaN(payload * 1) ? null : payload * 1;
		state.year = payload;
	},
	[types.SET_SEASON_MAIN]: (state, { start_date, end_date }) => {
		// console.log({payload});
		// state.year = isNaN(payload * 1) ? null : payload * 1;
		// state.year = payload;
		state.start_date = start_date;
		state.end_date = end_date;
	},
};
