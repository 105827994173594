import types from "@/store/modules/notifications/types";
import { createObject } from "./state";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.GET_NOTIFICATIONS]: (state, payload) => {
		state.notifications = [...state.notifications, ...payload];
	},

	[types.SET_COUNT_NOTIFICATIONS]: (state, payload) => {
		state.count = payload;
	},

	[types.SET_PAGE]: (state, payload) => {
		state.page = payload;
	},

	[types.SET_LAST_PAGE]: (state, payload) => {
		state.last_page = payload;
	},

	[types.SET_LOADING_SCROLL]: (state, payload) => {
		state.loadingScroll = payload;
	},

	[types.RELOAD_ANIMATION]: (state, payload) => {
		state.reload_animation = payload;

		
	},
};
