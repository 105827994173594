import axios from "@/axios";
const url = "/api/inventory";

class InventoryService {
	async index({ module, id = null }) {
		try {
			const { data } = await axios.get(`${url}/${module}`, {
				params: {
					id,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getDetails({ module, id, page, perpage, search, type = 1, status_id }) {
		try {
			const { data } = await axios.get(`${url}/get-details/${id}`, {
				params: {
					module,
					page,
					perpage,
					search,
					type,
					status_id,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async store({ module, value, start_date, parent_id, alias, comment }) {
		try {
			const { data } = await axios.post(`${url}/store`, {
				module,
				value,
				start_date,
				parent_id,
				alias,
				comment,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async update({ id, value, start_date, status_id, module, comment, end_date, alias }) {
		try {
			const { data } = await axios.put(`${url}/update/${id}`, {
				value,
				start_date,
				status_id,
				module,
				comment,
				end_date,
				alias
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getMatch({ id, module, page, perpage, search }) {
		try {
			const { data } = await axios.get(`${url}/get-match/${id}`, {
				params: {
					module,
					page,
					perpage,
					search,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getUnMatch({ id, module, page, perpage, search, type }) {
		try {
			const { data } = await axios.get(`${url}/get-un-match/${id}`, {
				params: {
					module,
					page,
					perpage,
					search,
					type,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getCountersInventory({ id, module }) {
		try {
			const { data } = await axios.get(`${url}/get-counters/${id}`, {
				params: {
					module,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async removePlateOrCintillo({ id, value }) {
		try {
			const { data } = await axios.post(`${url}/remove-plate-or-cintillo/${id}`, {
				value,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getEntity({ module, search, id }) {
		try {
			const { data } = await axios.get(`${url}/search/get-entity`, {
				params: {
					module,
					search,
					id,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updateValue({ id, add, deleted }) {
		try {
			const { data } = await axios.put(`${url}/update-value/${id}`, {
				deleted,
				add,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updatePlateSpecimenByOrigin({ id, specimens }) {
		//plate, origin_id
		try {
			const { data } = await axios.put(`${url}/update-plate-specimen-by-origin/${id}`, {
				specimens,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getChilds({ id }) {
		//plate, origin_id
		try {
			const { data } = await axios.get(`${url}/get-childs/${id}`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updateComment({ id, comment }) {
		//plate, origin_id
		try {
			const { data } = await axios.put(`${url}/update-comment/${id}`, {
				comment,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async searchPlateOriginal({ plate }) {
		//plate, origin_id
		try {
			const { data } = await axios.get(`${url}/original/search-plate-original`,{
				params: {plate}
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	//remove-plate-or-cintillo
}
export default new InventoryService();
