const chicksMutations = {
	SET_SEARCH_CHICK: 'SET_SEARCH_CHICK',
	REMOVE_SEARCH_CHICK: 'REMOVE_SEARCH_CHICK',
	GET_CHICKS: 'GET_CHICKS',
	SET_JSON_SEARCH_CHICKS: 'SET_JSON_SEARCH_CHICKS',
	SET_YEAR_MAIN: 'SET_YEAR_MAIN',
	RESET_CHICKS: 'RESET_CHICKS',
	GET_CHICKS_RECURSIVE: 'GET_CHICKS_RECURSIVE',
	SET_JSON_SEARCH_CHICKS_RECURSIVE: 'SET_JSON_SEARCH_CHICKS_RECURSIVE',
	SET_CHICKS: 'SET_CHICKS',
	SET_CHICKS_RECURSIVE: 'SET_CHICKS_RECURSIVE',
	RESET_STATUS_MASSIVELY: 'RESET_STATUS_MASSIVELY',
	RESET_STATUS_ITEM_DEAD: 'RESET_STATUS_ITEM_DEAD',
	ACTIVE_STATUS_ITEM_DEAD: 'ACTIVE_STATUS_ITEM_DEAD',
	TOGGLE_RELOAD_CHICKS: 'TOGGLE_RELOAD_CHICKS',
	TOGGLE_RELOAD_CHICKS_RECURSIVE: 'TOGGLE_RELOAD_CHICKS_RECURSIVE',
	TOGGLE_RELOAD_COUNTERS: 'TOGGLE_RELOAD_COUNTERS',
	SET_TOTAL_CHICKS: 'SET_TOTAL_CHICKS',
	DECREMENT_TOTAL_CHICKS: 'DECREMENT_TOTAL_CHICKS',
	SET_SEASON_MAIN: 'SET_SEASON_MAIN'
};

export default Object.freeze({
	...chicksMutations,
});
