/**
 * @returns {import('vuex').Store}
 */

export const state = () => ({
	inventory: {
		value: [],
		start_date: null,
		code: null, // Validar,
		alias: null,
		dad_code: null
	},
	inventories: [],
	match: [],
	unmatch: [],
	spare: [],
	missing: [],
	page: 1,
	perpage: 50,
	to_page: 1,
	total_data: 1,
	start_page: 1,
	search: null,
	counters: [
		// {
		// 	name: "Coinciden",
		// 	counter: 0,
		// 	visible: true,
		// },
		{
			name: "Encontrados",
			counter: 0,
			visible: false,
		},
		{
			name: "No encontrados",
			counter: 0,
			visible: false,
		},
		{
			name: "Restantes",
			counter: 0,
			visible: false,
		},
	],
	// countersUnMatch: [
	// 	{
	// 		name: "Existen",
	// 		counter: 0,
	// 	},
	// 	{
	// 		name: "No existen",
	// 		counter: 0,
	// 	},
	// ],
	indexInventory: 0,
	// indexUnMatch: 0,
	reload: false,
	countersMatch: [
	],
});
