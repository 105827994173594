import { $themeBreakpoints } from "@themeConfig";

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        loading: false,
        loadingBg: false,
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state;
            if (windowWidth >= $themeBreakpoints.xl) return "xl";
            if (windowWidth >= $themeBreakpoints.lg) return "lg";
            if (windowWidth >= $themeBreakpoints.md) return "md";
            if (windowWidth >= $themeBreakpoints.sm) return "sm";
            return "xs";
        },
        loading: state => state.loading
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val;
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay =
                val !== undefined ? val : !state.shallShowOverlay;
        },
        TOGGLE_LOADING(state, val) {
            // console.log('val', val);
            state.loading = val;
        },
        M_IS_PRELOADING(state, val) {
            state.loading = val;
        },
        M_IS_PRELOADING_BG(state, val) {
            state.loadingBg = val;
        }
    },
    actions: {
        toggleLoading({ commit }, val) {
            // console.log('val', val);
            commit("TOGGLE_LOADING", val);
        }
    }
};
