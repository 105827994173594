import moment from "moment";
export const setEvidencesBase64 = async (deads) => {
	// async SET_EVIDENCES_BASE_64(state, data) {
	for (let i = 0; i < deads.length; i++) {
		for (let k = 0; k < deads[i].evidences.length; k++) {
			console.log("image", deads[i].evidences[k]);
			await getBase64(deads[i].evidences[k]).then((data) => {
				let obj = {
					base: data,
					name: deads[i].evidences[k].name,
					size: deads[i].evidences[k].size,
				};
				deads[i].customFormData.push(obj);
			});
		}
	}
	return deads;
};

//Death Status
export const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

export const fixedMoney = (money) => {
	return Number(money)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const justNumbers = (number) => {
	return number.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
};

export const generateYears = (withAll = true) => {
	let years = [];
	for (let i = -4; i < 1; i++) {
		let obj = {
			year: moment().add(`${i}`, "Y").format("YYYY"),
			label: moment().add(`${i}`, "Y").format("YYYY"),
		};
		years.push(obj);
	}
	if (withAll) {
		years.push({ year: null, label: "Todos" });
	}
	return years.sort().reverse();
};

export const genYears = (depth = 4, from = new Date().getFullYear()) => {
	const currentYear = from;
	let prevYear = currentYear - depth;
	const yearsSet = [];
	while (prevYear <= currentYear) {
		yearsSet.push({
			year: String(prevYear),
			label: String(prevYear),
		});
		prevYear++;
	}
	yearsSet.push({ year: null, label: "Todos" });

	return yearsSet.reverse();
};
 // No usar para las temporadas
export const makeDatesInFn = (selectedYear) => {
	let dateFn = selectedYear ? `${parseInt(selectedYear) + 1}-03-01` : null;
	dateFn = dateFn !== null ? moment(dateFn).subtract(1, "days").format("YYYY-MM-DD") : null;
	
	return {
		dateIn: selectedYear ? `${selectedYear}-03-01` : null,
		dateFn,
	};
};
