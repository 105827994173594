export default [
    {
        path: "/colores",
        name: "colores-sr",
        component: () => import("@/views/amg/snoods/colours/ColourList.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de colores',
            breadcrumb: [
                {
                    text: "Colores",
                    active: true
                }
            ],
            roles: [1]
        }
    },
    {
        path: "/patas",
        name: "patas-sr",
        component: () => import("@/views/amg/snoods/chickenlegs/ChickenlegList.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de patas',
            breadcrumb: [
                {
                    text: "Patas",
                    active: true
                }
            ],
            roles: [1]
        }
    },
    {
        path: "/combinaciones",
        name: "combinaciones-sr",
        component: () => import("@/views/amg/snoods/combinations/CombinationList.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de combinaciones',
            breadcrumb: [
                {
                    text: "Combinaciones",
                    active: true
                }
            ],
            roles: [1]
        }
    }
];
