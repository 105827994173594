<template>
    <p>
        Cargando <span>{{ this.loadString }}</span>
    </p>
</template>

<script>
export default {
    name: "CargandoAnimation",
    data() {
        return {
            load: 0,
            intervalId: "",
            loadString: ""
        };
    },
    mounted() {
        this.intervalId = setInterval(() => {
            // console.log('ADD', this.load, this.loadString);

            this.loadString = this.createString(this.load);

            this.load = this.load + 1;

            // console.log('ADD: ', this.load, this.loadString);

            if (this.load == 5) {
                this.load = 1;
                this.loadString = ".";
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        createString(int) {
            let st = "";
            for (let i = 0; i < int; i++) {
                st = st + ".";
            }
            return st;
        }
    }
};
</script>

<style lang="scss" scoped>
p {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    span {
        // color: #7367f0;
        // color: red;
        animation-name: colorsSpan;
        animation-duration: 2s;
    }
}

@keyframes colorsSpan {
    from {
        color: red;
    }
    to {
        color: yellow;
    }
}
</style>
