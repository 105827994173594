export default [
	{
		path: "/menu",
		name: "menu",
		component: () => import("@/views/amg/Menu.vue"),
		meta: {
			layout: "horizontal",
			roles: [1, 2, 3, 4, 5, 6, 7],
		},
	},
	{
		path: "/brain/asistencia",
		name: "attendance",
		component: () => import("@/views/amg/header-bookmarks/attendance/views/AttendanceMain.vue"),
		meta: {
			layout: "vertical",
			pageTitle: 'Asistencia',
			roles: [1],
			breadcrumb: [
				{
					text: "Menu cerebro",
					active: false,
					to: '/brain'
				},
				{
					text: "Asistencia",
					active: true,
				},
			],
			roles: [1,2]
		},
	},
];
