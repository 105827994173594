import axios from "@/axios"

const url = "/api/deads/"

class DeadsService {
	// Patas
	async insertDeadPata({ cintillos }) {
		const data = await axios.post(`${url}pata`, {
			cintillos,
		})
		return data
	}

	async getLotes() {
		const { data } = await axios.get(`${url}pata/get-lotes-available`)
		return data
	}

	async getCintillos({ lote_id }) {
		const { data } = await axios.get(`${url}pata/get-cintillos-available/${lote_id}`)
		return data
	}

	async indexPatas({ page, perpage, orderBy, order, search }) {
		const { data } = await axios.get(`${url}pata`, {
			params: { page, perpage, orderBy, order, search },
		})
		return data
	}

	async getPosturaDetails({ postura_id }) {
		const { data } = await axios.get(`${url}pata/get-deads-by-postura/${postura_id}`)
		return data
	}

	async updatePosturaCintillos({ cintillos }) {
		const { data } = await axios.put(`${url}pata`, { cintillos })
		return data
	}

	async resurectPata({ egg_lote }) {
		const { data } = await axios.delete(`${url}pata/resurrect/${egg_lote}`)
		return data
	}

	// Alas
	async insertDeadAla({ chicks }) {
		const data = await axios.post(`${url}ala`, {
			chicks,
		})
		return data
	}

	async getChicks({ page, perpage, search }) {
		const { data } = await axios.get(`${url}ala/chicks`, {
			params: { page, perpage, search },
		})
		return data
	}

	async indexAlas({ page, perpage, orderBy, order, search, type_search }) {
		const { data } = await axios.get(`${url}ala`, {
			params: { page, perpage, orderBy, order, search, type_search },
		})
		return data
	}

	async getAlaDetails({ postura_id }) {
		const { data } = await axios.get(`${url}ala/get-deads-by-postura/${postura_id}`)
		return data
	}

	async updatePosturaAla({ chicks }) {
		const { data } = await axios.put(`${url}ala`, { chicks })
		return data
	}

	async resurectAla({ ala_id }) {
		const { data } = await axios.delete(`${url}ala/resurrect/${ala_id}`)
		return data
	}

	// ejemplares
	async getSpecimens({ search }) {
		const { data } = await axios.get(`${url}specimen/get-specimens`, {
			params: { search },
		})
		return data
	}

	async insertDeadSpecimens({ specimens }) {
		const data = await axios.post(`${url}specimen`, {
			specimens,
		})
		return data
	}

	async indexSpecimens({ page, perpage, orderBy, order, search }) {
		const { data } = await axios.get(`${url}specimen`, {
			params: { page, perpage, orderBy, order, search },
		})
		return data
	}

	async getSpecimenEvidences({ specimen_id }) {
		const { data } = await axios.get(`${url}specimen/details-evidences/${specimen_id}`)
		return data
	}
}
export default new DeadsService()
