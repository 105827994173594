import specimenService from "@/services/specimens.service";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async fetchSpecimenById({ commit }, { id }) {
		try {
			const specimen = await specimenService.fetchSpecimenById(id);
			commit(types.FETCH_SPECIMEN, specimen);
		} catch (error) {
			throw error;
		}
	},
};
