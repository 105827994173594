export default [
    {
        path: "/wizard-renewed",
        // name: "wizard-renewed",
        component: () => import("@/views/amg/wizard-renewed/WizardRenewedComponent.vue"),
        meta: {
            layout: "full",
            //pageTitle: 'Settings',
            // breadcrumb: [
            //     {
            //         text: "Settings",
            //         active: true
            //     }
            // ]
        },
        children: [
            {
                path: "",
                component: () =>
                    import("@/views/amg/wizard-renewed/components/WelcomeWizard.vue"),
                name: "wizard-renewedinit",
                meta: {
                    layout: "full",
                    // pageTitle: "Lista de torneos",
                    // breadcrumb: [
                    //     {
                    //         text: "Torneos",
                    //         active: true
                    //     }
                    // ]
                }
            },
            {
                path: "init-company",
                component: () =>
                    import("@/views/amg/wizard-renewed/components/InitCompanyWizard.vue"),
                name: "wizard-init-company",
                meta: {
                    layout: "full",
                    // pageTitle: "Lista de torneos",
                    // breadcrumb: [
                    //     {
                    //         text: "Torneos",
                    //         active: true
                    //     }
                    // ]
                }
            }
        ]
    }
];
