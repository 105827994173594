/* STATES */
import specimensService from "@/services/specimens.service";
import rivalsService from "@/services/rivals.service";
import {
	getRivals,
	updateFeatureImage,
	getImagesBySpecimens,
} from "@/views/amg/header-bookmarks/service.header-book.js";

const state = {
	S_EDIT_SPECIMEN: {
		plate: false,
		alias: false,
		category: false,
		status: false,
		color: false,
		weight: false,
		additional_plate: false,
		gender: false,
		origin: false,
		breeder_id: false,
		reason: false,
		preparador: false,
		alias: false,
		currency: false,
		price: false,
		plate: false,
		definition: false,
		dob: false,
		observation: false,
		breeder: false,
		observations: false,
		padrillo: false,
		madrilla: false,
	},

	S_PADRILLOS: [],
	S_MADRILLAS: [],
	S_CATEGORIES_SPECIMENS: [],
	S_COLORS_SPECIMENS: [],
	S_ORIGINS_SPECIMENS: [],
	S_BREEDERS: [],
	S_PHOTOS: [],
	S_GENDERS_SPECIMENS: [
		{ id: 1, description: "MACHO" },
		{ id: 2, description: "HEMBRA" },
	],
	S_OPTIONS_MACHO: [
		{ id: 1, description: "PADRILLO" },
		{ id: 3, description: "GALLO" },
		{ id: 5, description: "POLLON" },
	],
	S_OPTIONS_HEMBRA: [
		{ id: 2, description: "MADRILLA" },
		{ id: 4, description: "GALLINA" },
		{ id: 6, description: "POLLA" },
	],
	S_IMAGE_TO_UP: {},
	S_TRACKING_FIELDS: [],
	S_STATUS_SPECIMENS: [],
	counts: 0,
};

/* GETTERS */
const getters = {
	G_ORIGINS_SPECIMENS_FILTER(state) {
		return state.S_ORIGINS_SPECIMENS.filter((origin) => origin.id !== 4);
	},
};

/* MUTATIONS */
const mutations = {
	M_SET_EDIT_SPECIMEN(state, data) {
		state.S_EDIT_SPECIMEN[data] = !state.S_EDIT_SPECIMEN[data];
	},
	M_SET_CATEGORIES_SPECIMENS(state, data) {
		state.S_CATEGORIES_SPECIMENS = data;
	},
	M_SET_COLORS_SPECIMENS(state, data) {
		state.S_COLORS_SPECIMENS = data;
	},
	M_SET_ORIGINS_SPECIMENS(state, data) {
		state.S_ORIGINS_SPECIMENS = data;
	},
	M_SET_BREEDERS(state, data) {
		state.S_BREEDERS = data;
	},
	M_SET_PHOTOS(state, data) {
		state.S_PHOTOS = data;
	},
	M_SET_TRACKING_FIELDS(state, data) {
		state.S_TRACKING_FIELDS = data;
	},
	M_DELETE_TRACKING_FIELD(state, index) {
		state.S_TRACKING_FIELDS.splice(index, 1);
	},
	M_SET_OPTIONS_MACHO(state, data) {
		state.S_OPTIONS_MACHO = data;
	},

	M_SET_PADRILLOS(state, data) {
		state.S_PADRILLOS = data;
	},
	M_SET_MADRILLAS(state, data) {
		state.S_MADRILLAS = data;
	},
	FETCH_SPECIMEN_STATUS(state, payload) {
		state.S_STATUS_SPECIMENS = payload;
	},
};

/* ACTIONS */
const actions = {
	async A_GET_CATEGORIES_SPECIMENS({ state, commit }, { specimen_id = null }) {
		const { data } = await specimensService.getCategoriesSpecimens(specimen_id);

		commit("M_SET_CATEGORIES_SPECIMENS", data);
	},
	async A_GET_COLORS_SPECIMENS({ state, commit }, body = null) {
		const { colores } = await specimensService.getColors({
			gender_specimen: body,
			perpage: 1000,
			page: 1,
			search: null,
		});
		// console.log("body", colores.data);
		commit("M_SET_COLORS_SPECIMENS", colores.data);
	},
	async A_GET_ORIGINS_SPECIMENS({ state, commit }) {
		if (!state.S_ORIGINS_SPECIMENS.length) {
			const { data } = await specimensService.getOriginsSpecimen();
			commit("M_SET_ORIGINS_SPECIMENS", data);
		}
	},
	async A_GET_BREEDERS({ state, commit }) {
		const data = await rivalsService.getRivals();
		commit("M_SET_BREEDERS", data);
	},
	async A_UPDATE_PRINCIPAL_PHOTO(state, data) {
		await updateFeatureImage(data);
	},
	async A_ADD_IMAGE_BY_ID({}, data) {
		try {
			const response = await specimensService.addImage(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_GET_IMAGES_SPECIMEN({ commit }, body) {
		let { data } = await getImagesBySpecimens({ id: body, typeFiles: 1 });
		data = data.map((item) => {
			return {
				feature: item.feature,
				photo: item.url,
				photo_id: item.id,
				thumb: item.preload,
			};
		});
		commit("M_SET_PHOTOS", data);
	},
	async A_UPDATE_CATEGORY_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateCategorySpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_ORIGIN_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateOriginSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_COLOR_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateColorSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_BREEDER_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateBreederSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_ALIAS_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateAliasSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},

	async A_UPDATE_SPECIMEN_FIELS(
		{ commit },
		{ data, specimen, specimenDataSecondary, field, user, type_tracking: { type_tracking, type_field, key_value } }
	) {
		try {
			// this.M_SET_EDIT_SPECIMEN(field);
			const trackingSpecimen = {
				specimen_id: specimen.id,
				created_by: user,
				inital_created_by: specimen.created_by,
				inital_created_at: specimen.created_at,
				type_tracking,
				type_field,
				field_value: specimen[key_value],
				old_value: specimenDataSecondary[key_value],
				field,
			};

			// if (key_value == "price") {
			// 	const currency = this.specimenData.currency === 1 ? "$ " : "S/.";
			// 	const oldCurrency = this.specimenDataSecondary.currency === 1 ? "$ " : "S/.";
			// 	params.field_value = `${currency} ${this.specimenData[key_value]}`;
			// 	params.old_value = `${oldCurrency} ${this.specimenDataSecondary[key_value]}`;
			// }
			console.log("ADD", trackingSpecimen);

			const response = await specimensService.updateSpecimenFields({
				specimen: data,
				trackingSpecimen,
			});
			console.log("ADD", response);
			return response;
		} catch (error) {
			throw error;
		}
	},

	async A_UPDATE_ADDITIONAL_PLATE_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateAdditionalPlateSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_PLATE_SPECIMEN({}, { plate, specimen_id }) {
		try {
			const response = await specimensService.updatePlate({ plate, specimen_id });
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_PREPARADOR_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updatePreaparadorSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_PRICE_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updatePriceSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_OBSERVATIONS_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateObservationsSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_DOB_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateDobSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_PADRILLO_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updatePadrilloSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_UPDATE_MADRILLA_SPECIMEN({}, data) {
		try {
			const response = await specimensService.updateMadrillaSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_INSERT_TRACKING_FIELDS_SPECIMEN({}, data) {
		try {
			const response = await specimensService.insertTrakingFieldsSpecimen(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_GET_TRACKING_FIELDS_SPECIMEN({ commit }, data) {
		try {
			const response = await specimensService.getTrakingFieldsSpecimen(data);
			commit("M_SET_TRACKING_FIELDS", response);
		} catch (error) {
			throw error;
		}
	},
	async A_DELETE_TRACKING_FIELD_SPECIMEN({ commit }, { params, index }) {
		return "no deberia eliminarse";
	},
	async A_GET_SPECIMENS_BY_CATEGORY({}, data) {
		try {
			const response = await specimensService.getSpecimensByCategory(data);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async A_SAVE_SPECIMEN({}, data) {
		try {
			const response = await specimensService.store(data);
			return response;
		} catch (error) {
			throw error;
		}
	},

	async fetchSpecimenStatus({ commit }) {
		try {
			const status = await specimensService.getAllStatusSpecimens();
			commit("FETCH_SPECIMEN_STATUS", status);
		} catch (error) {
			throw error;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
