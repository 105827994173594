/**
 * @returns {import('vuex').Store}
 */

export const createObject = (type_search = 1) => ({
	type_search,
	search: null,
	condition: 1,
	perpage: 100,
	page: 1,
	order_by: "correlative",
	order: "asc",
	start_dob: null,
	end_dob: null,
	gender_id: null,
	chicks_id: [],
});

export const state = () => ({
	searchChicks: [],
	chicks: [],
	chicks_recursive: [], // chicks brothers
	jsonSearchChicks: createObject(),
	jsonSearchChicksRecursive: createObject(),
	start_page: 1,
	to_page: 1,
	total_data: 1,
	year: null,
	reload_chicks: false,
	reload_chicks_recursive: false,
	reload_counters: false,
	start_date: null,
	end_date: null
});
