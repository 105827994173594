//get-specimens-by-name
import axios from "@/axios";

const getSpecimensToFight = async (body) => {
	const data = await axios.post(`/api/specimens/get-specimens-to-fight`, body);
	return data;
};
const getSpecimensByName = async (body) => {
	const data = await axios.post(`/api/specimens/by-name/get-specimens-by-name`, body);
	return data;
};
const getChikcsByName = async ({ name, lives = 0 }) => {
	// 0 envia todos, 1 solo los vivos
	const data = await axios.post(`/api/specimens/by-name/get-chicks-by-name`, { name, lives });
	return data;
};
const addImage = async (body, config) => {
	const data = await axios.post(`/api/specimens/add-image`, body, config);
	return data;
};
const getImagesBySpecimens = async (body) => {
	const data = await axios.post(`/api/specimens/get-images-by-specimens`, body);
	return data;
};
const updateFeatureImage = async (body) => {
	const data = await axios.put(`/api/specimens/update-feature-image`, body);
	return data;
};
const deleteImageGallery = async ({ specimen_id, id }) => {
	const data = await axios.delete(`/api/specimens/delete-image-gallery`, {
		params: {
			specimen_id,
			id,
		},
	});
	return data;
};
const getRivals = async () => {
	try {
		const {data} = await axios.get(`/api/rivals`);
		return data.rivals;
	} catch (error) {
		return error;
	}
};
const getCareador = async () => {
	const data = await axios.post(`/api/get-careador`);
	return data;
};
const getAmarrador = async () => {
	const data = await axios.post(`/api/get-amarrador`);
	return data;
};

const getUsersByRole = async (rolId) => {
	const { data } = await axios.get(`/api/utilities/get-users-by-role`, {
		params: { role_id: rolId },
	});
	return data;
};

const deleteVideoTopeViId = async (body) => {
	const { data } = await axios.post(`/api/delete-video`, body);
	return data;
};

const getNotificationsVideo = async (body) => {
	const { data } = await axios.post(`/api/notifications/video-cloudflare`, body);
	return data;
};

const updateStatusByID = async (body) => {
	const { data } = await axios.post(`/api/notifications/update-status-by-id`, body);
	return data;
};

const getSpecimensToGuest = async (body) => {
	const data = await axios.post(`/api/specimens/get-specimens-to-guest`, body);
	return data;
};

export {
	getSpecimensByName,
	addImage,
	getImagesBySpecimens,
	updateFeatureImage,
	deleteImageGallery,
	getRivals,
	getCareador,
	getAmarrador,
	getUsersByRole,
	deleteVideoTopeViId,
	getNotificationsVideo,
	updateStatusByID,
	getChikcsByName,
	getSpecimensToFight,
	getSpecimensToGuest
};
