import axios from "@/axios";
const url = "/api/sticky/";

class StickyNotesService {
    async getNotes(params) {
        try {
            let res = await axios.get(`${url}`);
            return res.data
        } catch (err) {
            console.error(err)
        }
    }
    async getNote(params) {
        try {
            let res = await axios.get(`${url}show/${params.id}`);
            return res.data
        } catch (err) {
            console.error(err)
        }
    }
    async createNote(params) {
        try {
            let res = await axios.post(`${url}store`, params)
            return res.data
        } catch (err) {
            console.error(err)
        }
    };
    async updateNote(params) {
        try {
            let res = await axios.put(`${url}update/${params.id}`, params)
            return res.data
        } catch (err) {
            console.error(err)
        }
    };
    async deleteNote(params) {
        try {
            let res = await axios.put(`${url}destroy/${params.id}`)
            return res.data
        } catch (err) {
            console.error(err)
        }
    };
}
export default new StickyNotesService();