export const currentUser = (state) => {
	if (state.currentUser) {
		return { ...state.currentUser, user_id: state.currentUser.id };
	}
	return null;
};

export const roles = (state) => {
	if (state.currentUser) return state.currentUser.roles;
	return [];
};

export const isAdmin = (state, getters) => {
	const isDueñoInRoles = getters.roles.filter((rol) => rol == 1);
	if (isDueñoInRoles.length > 0) return true;
	return false;
};

export const isEncastador = (state, getters) => {
	const isEncastadorInRoles = getters.roles.filter((rol) => rol == 6);
	if (isEncastadorInRoles.length > 0) return true;
	return false;
};

export const isInvitado = (state, getters) => {
	const isInvitadoInRoles = getters.roles.filter((rol) => rol == 7);
	if (isInvitadoInRoles.length > 0) return true;
	return false;
};
