import Echo from "laravel-echo";
window.Pusher = require("pusher-js");
import notificationsSocket from "./notifications";

if (process.env.VUE_APP_RUN_PUSHER) {
	// window.Echo = new Echo({
	// 	broadcaster: "pusher",
	// 	key: process.env.VUE_APP_PUSHER_KEY,
	// 	cluster: "mt1",
	// 	wsHost: window.location.hostname,
	// 	wsPort: 6001,
	// 	wssPort: 6001,
	// 	forceTLS: false,
	// 	disableStats: true,
	// 	enabledTransports: ["ws", "wss"],
	// });

	// const channelSR = window.Echo.channel("smartrooster");

	// // Notifications Socket
	// notificationsSocket(channelSR, ".newMessage");
}
