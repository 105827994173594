<template>
	<b-modal centered hide-footer size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-textarea class="text-dark" disabled :value="lastComment.description" v-if="lastComment.description" />
		</b-overlay>
	</b-modal>
</template>

<script>
import tasksService from "@/services/tasks.service.js"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
	}),
	computed: {
		lastComment() {
			if (!this.rows.length) return {}
			// primer comentario que no sea de estado pendiente
			return this.rows.find((row) => row.status_id != 1)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.title = null
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tasksService.getUserTaskTracking({ task_id: this.info.id })
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				// this.title = `Tracking de tarea de ${this.info.assign.name}`
				this.title = `Comentario de ${this.info.assign.name}`
				this.getRows()
			}
		},
	},
}
</script>
