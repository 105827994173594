import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import encaste from "./modules/encaste";
import header_book from "../views/amg/header-bookmarks/store.header-book";
// import squareUpInventory from "./modules/squareUpInventory";
import statusSpecimens from "./modules/statusSpecimens";
import customLightBox from "./modules/customLightBox";
import createEditSpecimen from "./modules/createEditSpecimen";
import weightSpecimen from "./modules/weight-specimen";
import photosSpecimen from "./modules/photos-specimen";
import video from "./modules/video";
import tournaments from "@/store/modules/tournament"
import specimens from "@/store/modules/specimens"
import pedigree from "@/store/modules/pedigree"
import encasteChicks from "@/store/modules/encaste/chicks"
import encastePosturas from "@/store/modules/encaste/posturas"
import notifications from "@/store/modules/notifications"
import orders from "@/store/modules/orders"
import inventorySpecimens from "@/store/modules/inventory/specimen"
import headers from "@/store/modules/headers"
import utilities from "@/store/modules/utilities"
import encasteInsemination from "@/store/modules/encaste/insemination"
import commons from "@/store/modules/commons"
import counters from "./modules/tournament/counters";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		auth,
		encaste,
		header_book,
		statusSpecimens,
		video,
		createEditSpecimen,
		tournaments,
		specimens,
		weightSpecimen,
		photosSpecimen,
		customLightBox,
		pedigree,
		encasteChicks,
		encastePosturas,
		encasteInsemination,
		notifications,
		orders,
		inventorySpecimens,
		headers,
		utilities,
		commons,
		counters
	},
	strict: process.env.DEV,
});

export default store;