<template>
	<b-modal no-close-on-backdrop centered size="sm" title="Agregar tarea" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group class="mb-2">
					<template #label>
						<span>Tarea</span>
						<b-button
							class="btn-icon float-right"
							size="sm"
							variant="flat-success"
							@click="creationModal.show = !creationModal.show"
						>
							<feather-icon icon="PlusIcon" />
						</b-button>
					</template>
					<ValidationProvider v-slot="{ errors }" rules="required">
						<v-select
							label="text"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="taskOpts"
							:reduce="(opt) => opt.value"
							v-model="task"
							:key="creationModal.show"
							@input="selectedSpecimens = []"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Colaborador">
					<ValidationProvider v-slot="{ errors }" rules="required">
						<v-select
							label="text"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="userOpts"
							:reduce="(opt) => opt.value"
							v-model="user"
						/>
					</ValidationProvider>
				</b-form-group>
				<template v-if="[1, 2].includes(task)">
					<ValidationProvider v-slot="{ errors }" rules="required">
						<input class="d-none" v-model="selectedSpecimens" />
						<b-form-group label="Seleccionar ejemplares">
							<v-select
								label="query"
								:clearable="false"
								:options="specimenOpts"
								:selectable="checkOptionSelectable"
								v-model="selectModel"
								@search="fetchOpts"
								@option:selecting="handleSpecimenSelected"
								@option:selected="selectModel = null"
							/>
						</b-form-group>
						<b-row class="overflow-auto" style="max-height: 10rem">
							<b-col
								cols="6"
								class="mb-1 text-center"
								v-for="(specimen, idx) in selectedSpecimens"
								:key="specimen.id"
							>
								<b-badge variant="primary">
									{{ specimen.query }}
									<feather-icon
										class="clickable ml-05"
										icon="XIcon"
										@click="removeSelectedSpecimen(idx)"
									/>
								</b-badge>
							</b-col>
						</b-row>
						<small class="text-danger" v-if="errors[0]">Se debe seleccionar al menos 1 ejemplar</small>
					</ValidationProvider>
				</template>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="createTask">Guardar</b-button>
		</template>
		<b-modal size="sm" title="Crear tarea" centered v-model="creationModal.show" @hidden="clearCreation">
			<b-overlay :show="creationModal.loading">
				<ValidationObserver ref="newTask">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group label="Nombre">
							<b-form-input
								:class="{ 'border-danger': errors[0] }"
								placeholder="Ingrese el nombre"
								v-model="creationModal.name"
							/>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="success" @click="createTaskBrain">Guardar</b-button>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import useTasks from "./useTasks"
import tasksService from "@/services/tasks.service.js"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		user: null,
		task: null,
		comment: null,
		creationModal: {
			show: false,
			loading: false,
			name: null,
		},
		//
		selectModel: null,
		specimenOpts: [],
		selectedSpecimens: [],
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.user = null
			this.task = null
			this.comment = null
			this.selectedSpecimens = []
			this.$emit("closing")
		},
		clearCreation() {
			this.creationModal = {
				show: false,
				loading: false,
				name: null,
			}
		},
		async getOpts() {
			const users = await tasksService.getUsersList()
			const tasks = await tasksService.getTasksBrain()
			this.userOpts = users.data.map((u) => ({
				value: u.id,
				text: `${u.name} ${u.last_name}`,
			}))
			this.taskOpts = tasks.data.map((t) => ({
				value: t.id,
				text: t.name,
			}))
		},
		async createTask() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se asignara una nueva tarea a ${this.userOpts.find((u) => u.value == this.user).text}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await tasksService.insertUserTask({
				task_id: this.task,
				user_id: this.user,
				comment: this.comment,
				// si la tarea es foto o peso, mandar lista de ejemplares objetivo
				specimens: [1, 2].includes(this.task) ? this.selectedSpecimens.map((s) => s.id) : [],
			})
			this.isLoading = false
			this.showToast("success", "top-right", "Tareas", "CheckIcon", `Se creo la tarea de forma correcta`)
			this.$emit("refresh")
			this.counterTrigger = Symbol()
			this.handleHidden()
		},
		async createTaskBrain() {
			if (!(await this.$refs.newTask.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se creara una nueva tarea`,
			})
			if (!isConfirmed) return
			this.creationModal.loading = true
			const { data } = await tasksService.insertTaskBrain({ name: this.creationModal.name })
			await this.getOpts()
			this.task = data.id
			this.creationModal.loading = false
			this.clearCreation()
		},
		//
		fetchOpts(search, loading) {
			this.findCintillos(search, loading, this.setOpts)
		},
		findCintillos: _.debounce(async (search, loading, setter) => {
			loading(true)
			const data = await tasksService.getSpecimens({ search })
			setter(data)
			loading(false)
		}, 500),
		setOpts(data) {
			this.specimenOpts = data.map((s) => {
				let query = ""
				if (s.alias) query += s.alias
				else query += "---"
				query += ` / ${s.plate}`
				return { ...s, query }
			})
		},
		handleSpecimenSelected(specimen) {
			this.selectedSpecimens.push(specimen)
		},
		checkOptionSelectable(opt) {
			const existingIds = this.selectedSpecimens.map((s) => s.id)
			return !existingIds.includes(opt.id)
		},
		removeSelectedSpecimen(idx) {
			this.selectedSpecimens.splice(idx, 1)
		},
	},
	created() {
		this.getOpts()
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
	setup() {
		const { counterTrigger } = useTasks()
		return { counterTrigger }
	},
}
</script>
