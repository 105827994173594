import axios from "@/axios";

const url = "/api/rivals";

class RivalsService {
	async getRivals() {
		const { data } = await axios.get(`${url}`);
		return data.rivals;
	}
}
export default new RivalsService();
