import axios from "@/axios";

const getDiseasesToStatus = async body => {
    const data = await axios.post(`/api/diseases/get-toggle`, body);
    return data;
};
const getDiseases = async () => {
    const { data } = await axios.get(`/api/diseases/get-diseases`);
    return data;
};

const saveDisease = async body => {
    const data = await axios.post(`/api/diseases/save-disease`, body);
    return data;
};

const updateToggleStatus = async body => {
    const data = await axios.put("/api/diseases/toggle-status", body);
    return data;
};

const deleteDiseaseById = async body => {
    const data = await axios.put("/api/diseases/delete-disease", body);
    return data;
};
const updateDiseaseById = async body => {
    const data = await axios.put("/api/diseases/update-disease", body);
    return data;
};
const saveDiseaseEspecimenTracking = async body => {
    const data = await axios.post(
        "/api/diseases/save-disease-especimen-tracking",
        body
    );
    return data;
};

//

export {
    saveDisease,
    getDiseasesToStatus,
    updateToggleStatus,
    deleteDiseaseById,
    updateDiseaseById,
    getDiseases,
    saveDiseaseEspecimenTracking
};
