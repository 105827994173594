const tournamentState = {
	tournament: {
		flyers: [],
		awards: [],
		organizers: [],
		dates_to_play: {
			count: 0,
			fronts: 0,
			specimens: 0,
			items: [],
		},
		dates_played: {
			count: 0,
			fronts: 0,
			specimens: 0,
			items: [],
		},
	},
	tournamentDate: {},
	coliseum: [],
	contacts: [],
	banks: [],
	stages: [],
	files: [],
	failure: "",
	fetchTournamentDatePending: true,
	totalPrizeAwardInYear: 0,
}

/**
 * @returns {import('vuex').Store}
 */
export const state = () => tournamentState
