/** @ts-check */
import { showToast } from "@/helpers/toast";
import types from "./types";
import moment from "moment";

import encasteChicksService from "@/services/encaste/chicks.service";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async getChicks({ commit }, jsonSearchChicks) {
		
	},

};
