import axios from "@/axios";
const url = "/api/eggs-found/";

class EggsFoundService {
	async save(params) {
		try {
			let res = await axios.post(`${url}store`, params);
			return res.data;
		} catch (err) {
			console.error(err);
		}
	}

	async getCounters(params) {
		try {
			let res = await axios.get(`${url}counters/get-by-status`);
			return res.data;
		} catch (err) {
			console.error(err);
		}
	}
	async getAll({ perpage, page, q, search, start_date, end_date, order = 'asc' }) {
		try {
			let { data } = await axios.get(`${url}${q}`, {
				params: {
					perpage,
					page,
					search,
					start_date,
					end_date,
					order
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	async getMadrillas(params) {
		try {
			let query = new URLSearchParams({
				search: params.q,
			});
			let res = await axios.get(`api/specimens/get-madrillas-active?${query.toString()}`);
			return res.data;
		} catch (err) {
			console.error(err);
		}
	}
	async reviewRegister(params) {
		try {
			let res = await axios.put(`${url}update/${params.id}`, params);
			return res.data;
		} catch (err) {
			console.error(err);
		}
	}

	async getTracking({ id }) {
		try {
			let { data } = await axios.get(`${url}get-tracking/${id}`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async validPlateSpecimen({ plate }) {
		try {
			let { data } = await axios.post(`${url}valid-plate-madrilla`, {
				plate,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateMassively({ eggs }) {
		try {
			let { data } = await axios.post(`${url}update-massively`, {
				eggs,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	//
	async getAccepted({ start_date, end_date, perpage, page, order = 'desc' }) {
		try {
			let { data } = await axios.get(`${url}accepted/get`, {
				params: { start_date, end_date, perpage, page, order },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getAcceptedRegistered({ date }) {
		try {
			let { data } = await axios.get(`${url}accepted/registered`, {
				params: { date },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getAcceptedPostura({ date }) {
		try {
			let { data } = await axios.get(`${url}accepted/posturas`, {
				params: { date },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getAcceptedAire({ date }) {
		try {
			let { data } = await axios.get(`${url}accepted/airs`, {
				params: { date },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new EggsFoundService();
