import SpecimenService from "@/services/specimens.service"
import store from "@/store"

export const A_GET_TYPE_WEIGHT_OPTIONS = async ({ commit }) => {
	try {
		const response = await SpecimenService.getTypeWeights()
		commit("M_SET_TYPE_WEIGHT_OPTIONS", response)
	} catch (error) {
		commit("M_SET_TYPE_WEIGHT_OPTIONS", {})
		throw error
	}
}
export const A_GET_SPECIMEN_TRACKING_WEIGHTS = async ({ commit }, body) => {
	try {
		const { data } = await SpecimenService.getSpecimenWeightTracking(body)
		data.forEach((item) => {
			item.isEditing = false
			item.tempKgWeight = 0
			item.tempPoundsWeight = 0
		})
		commit("M_SET_SPECIMEN_TRACKING_WEIGHTS", data)
	} catch (error) {
		commit("M_SET_SPECIMEN_TRACKING_WEIGHTS", [])
		throw error
	}
}
export const A_SAVE_SPECIMEN_WEIGHT = async ({}, body) => {
	try {
		await SpecimenService.saveSpecimenWeight(body)
	} catch (error) {
		throw error
	}
}
export const A_SAVE_MASSIVELY_WEIGHTS = async ({ state }) => {
	try {
		const params = {
			specimens_weights: state.S_MASSIVELY_WEIGHTS,
			created_by: store.getters["auth/currentUser"].user_id,
			is_training: state.S_IS_TRAINING ? 1 : 0,
		}
		await SpecimenService.saveMassivelyWeights(params)
	} catch (error) {
		throw error
	}
}
export const A_UPDATE_SPECIMEN_WEIGHT = async ({}, body) => {
	try {
		await SpecimenService.updateSpecimenWeightTracking(body)
	} catch (error) {
		throw error
	}
}
export const A_ADD_TYPE_WEIGHT = async ({ dispatch }, body) => {
	try {
		await SpecimenService.addTypeWeight(body)
		dispatch("A_GET_TYPE_WEIGHT_OPTIONS")
	} catch (error) {
		throw error
	}
}
