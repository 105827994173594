/**
 * @returns {import('vuex').Store}
 */


export const state = () => ({
	recommended: {
		counter: 0
	},
	pending: 0
});
