import axios from "@/axios";

const url = "/api/encaste/chicks";

class EncasteChicksService {
	async getChicks({
		start_date,
		end_date,
		type_search,
		search,
		condition,
		perpage,
		page,
		order_by,
		order,
		start_dob,
		end_dob,
		gender_id,
		chicks_id = [],
	}) {
		try {
			const { data } = await axios.get(`${url}/get-chicks`, {
				params: {
					start_date,
					end_date,
					type_search,
					search,
					condition,
					perpage,
					page,
					order_by,
					order,
					start_dob,
					end_dob,
					gender_id,
					chicks_id,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateChickGenderAndStatus({ gender, chick_id, status }) {
		try {
			const { data } = await axios.post(`/api/chick/update`, { gender, chick_id, status });
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async loopAssignPlate({ chicks, retired_corral }) {
		try {
			const { data } = await axios.put(`${url}/loop-assign-plate`, { chicks, retired_corral });
			return data;
		} catch (err) {
			console.error("Error", err.response);
		}
	}
	//

	async getCounterByCondition({ start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/get-counter-by-condition`, {
				params: {
					start_date,
					end_date,
				},
			});
			return data;
		} catch (err) {
			console.error("Error", err.response);
		}
	}

	async updateDobChick({ chick_id, dob }) {
		try {
			const { data } = await axios.put(`${url}/update-dob-chick`, {
				chick_id,
				dob,
			});
			return data;
		} catch (err) {
			console.error("Error", err.response);
		}
	}

	async getNotepadData({ module_id, sub_module_id }) {
		try {
			const { data } = await axios.get(`${url}/get-notepad-items-search`, {
				params: {
					module_id,
					sub_module_id,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async deadChicksMassively({ evidences, chicks, status_id, is_masive }) {
		try {
			const { data } = await axios.put(`${url}/dead-chicks`, {
				evidences,
				chicks,
				status_id,
				is_masive,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async getChicksAlive({ search }) {
		try {
			const { data } = await axios.get(`${url}/get-chicks-alive`, {
				params: {
					search,
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateGenderMassively({ chicks }) {
		try {
			const { data } = await axios.put(`${url}/update-chicks-gender-masive`, {
				chicks,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async toResurrectMassively({ chicks }) {
		try {
			const { data } = await axios.post(`${url}/change-status-to-active`, {
				chicks,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async loopAssignPlate({ chicks, retired_corral }) {
		try {
			const { data } = await axios.put(`${url}/loop-assign-plate`, {
				chicks,
				retired_corral,
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new EncasteChicksService();
