import isAuthenticatedGuard from "@/router/auth-guard";
export default [
	{
		path: "/brain/menu",
		name: "brain-menu",
		// beforeEnter: isAuthenticatedGuard,
		component: () => import(/* webpackChuckName: "menu-brain" */ "@/views/brain/MenuBrain"),
		meta: {
			layout: "horizontal",
			requiresAuth: [1, 2],
			roles: [1,2]
		},
	},
];
