export default [
  {
    path: "/administrativo/dashboard",
    name: "administrativo-dashboard-sr",
    component: () => import("@/views/amg/administrative/Dashboard.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: "Dashboard",
          active: true
        }
      ]
    }
  },
  {
    path: "/administrativo/gastos",
    name: "administrativo-gastos-sr",
    component: () => import("@/views/amg/administrative/Expense.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Lista de gastos',
      breadcrumb: [
        {
          text: "Gastos",
          active: true
        }
      ]
    }
  },
  {
    path: "/administrativo/gastos/crear",
    name: "administrativo-agregar-gastos-sr",
    component: () => import("@/views/amg/administrative/CreateTransaction.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Agregar gasto',
      breadcrumb: [
        {
          text: "Gastos",
          active: false,
          to: "/administrativo/gastos"
        },
        {
          text: "Agregar",
          active: true
        }
      ]
    },
    props: (route) => ({
      type: 1,
      ...route.params
    })
  },
  {
    path: "/administrativo/gastos/detalle/:transaction_id",
    name: "administrativo-detalle-gastos-sr",
    component: () => import("@/views/amg/administrative/CreateTransaction.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Detalle de gasto',
      breadcrumb: [
        {
          text: "Gastos",
          active: false,
          to: "/administrativo/gastos"
        },
        {
          text: "Detalle",
          active: true
        }
      ]
    },
    props: (route) => ({
      type: 1,
      ...route.params
    })
  },
  {
    path: "/administrativo/depositos",
    name: "administrativo-depositos-sr",
    component: () => import("@/views/amg/administrative/Deposits.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Lista de depósitos',
      breadcrumb: [
        {
          text: "Depósitos",
          active: true
        }
      ]
    },
    props: (route) => ({
      type: 2,
      ...route.params
    })
  },
  {
    path: "/administrativo/depositos/crear",
    name: "administrativo-agregar-depositos-sr",
    component: () => import("@/views/amg/administrative/CreateTransaction.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Agregar depósito',
      breadcrumb: [
        {
          text: "Depósitos",
          active: false,
          to: "/administrativo/depositos"
        },
        {
          text: "Agregar",
          active: true
        }
      ]
    },
    props: (route) => ({
      type: 2,
      ...route.params
    })
  },
  {
    path: "/administrativo/depositos/detalle/:transaction_id",
    name: "administrativo-detalle-depositos-sr",
    component: () => import("@/views/amg/administrative/CreateTransaction.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Detalle del depósito',
      breadcrumb: [
        {
          text: "Depósitos",
          active: false,
          to: "/administrativo/depositos"
        },
        {
          text: "Detalle",
          active: true
        }
      ]
    },
    props: (route) => ({
      type: 2,
      ...route.params
    })
  },
  {
    path: "/administrativo/empleados",
    name: "administrativo-empleados-sr",
    component: () => import("@/views/amg/administrative/Employees.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Lista de empleados',
      breadcrumb: [
        {
          text: "Empleados",
          active: true
        }
      ]
    }
  },
  {
    path: "/administrativo/empleados/crear",
    name: "administrativo-empleados-crear-sr",
    component: () => import("@/views/amg/administrative/CreateEmployee.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Agregar empleado',
      breadcrumb: [
        {
          text: "Empleados",
          active: false,
          to: "/administrativo/empleados"
        },
        {
          text: "Agregar",
          active: true
        }
      ]
    }
  },
  {
    path: "/administrativo/empleados/editar/:employee_id",
    name: "administrativo-empleados-editar-sr",
    component: () => import("@/views/amg/administrative/CreateEmployee.vue"),
    meta: {
      layout: "vertical",
      pageTitle: 'Editar empleado',
      breadcrumb: [
        {
          text: "Empleados",
          active: false,
          to: "/administrativo/empleados"
        },
        {
          text: "Editar",
          active: true
        }
      ]
    }
  }
];
