<template>
	<b-modal
		body-class="p-0 m-0"
		scrollable
		hide-footer
		width="90%"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-card class="mb-0">
			<b-form-group class="mb-1 w-full" label="Comentario">
				<b-textarea v-model="comment" style="resize: none" />
			</b-form-group>
			<div class="d-flex justify-content-end" style="gap: 2rem">
				<b-button v-if="type == 1" class="" variant="success" size="sm" @click="reviewRegiser()"
					>Aprobar</b-button
				>
				<b-button v-if="type == 2" class="" variant="danger" size="sm" @click="reviewRegiser(false)"
					>Rechazar</b-button
				>
			</div>
		</b-card>
	</b-modal>
</template>

<script>
import { ref, watch, computed, reactive } from "@vue/composition-api";

import eggsFoundService from "@/services/header/eggs-found.service";

export default {
	props: { show: Boolean, id: Number, type: Number, title: String },
	setup(props, ctx) {
		// handle sidebar model
		let isActive = ref(false);
		watch(
			() => props.show,
			(val) => {
				if (val) {
					isActive.value = true;
				}
			}
		);
		function handleHidden() {
			isActive.value = false;
			ctx.emit("closing");
		}

		// review register
		let comment = ref("");
		async function reviewRegiser(approved = true) {
			this.isPreloading();
			let body = {
				id: props.id,
				status_id: approved ? 2 : 3,
				comment: comment.value,
			};
			let data = await eggsFoundService.reviewRegister(body);
			comment.value = null;
			this.isPreloading(false);
			if (!data.success) {
				console.error(data);
				return;
			}
			this.showToast("success", "top-right", "Mensaje", "CheckIcon", "Registro Guardado");
			ctx.emit("refresh");
			handleHidden();
		}

		return {
			isActive,
			handleHidden,
			comment,
			reviewRegiser,
		};
	},
};
</script>
