/**
 * @returns {import('vuex').Store}
 */

export const createObjectSearchItems = () => ({
	category_id: null,
	page: 1,
	perpage: 50,
	search: null,
});

export const state = () => ({
	orders: [],
	items: [
		// {
		// 	id: 1,
		// 	title: "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
		// 	price: 109.95,
		// 	description:
		// 		"Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
		// 	category: "men's clothing",
		// 	image: "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
		// 	category: "Alimentos",
		// 	subcategory: "Cereales",
		// 	measure: "Kg",
		// 	price: "120",
		// 	currency: "S/.",
		// 	counter: 0,
		// },
	],
	cart: [],
	start_page: 1,
	to_page: 1,
	total_data: 1,
	current_page: 1,
	total_data: 1,
	jsonSearchItems: createObjectSearchItems(),
	category_select: null,
	arrayCategory: [],
	reloadCategories: false,
});
