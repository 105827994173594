import axios from "@/axios";
const url = "/api/items";

class ItemService {
	async createItem(body) {
		try {
			const { data } = await axios.post(`${url}/store`, body);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async getTotalItem({ perpage, page, category_id, search }) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: { perpage, page, category_id, search },
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async deleteItem({ id }) {
		try {
			const { data } = await axios.delete(`${url}/destroy/${id}`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateItem({ body, id }) {
		try {
			const { data } = await axios.post(`${url}/update/${id}`, body);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async getTrackingPrices({ id }) {
		try {
			const { data } = await axios.get(`${url}/get-tracking-price/${id}`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new ItemService();
