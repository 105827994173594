import types from '@/store/modules/tournament/types';

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.FILL_TOURNAMENT]: (state, payload) => {
		state.tournament = payload;
	},

	[types.FETCH_COLISEUM]: (state, payload) => {
		state.coliseum = payload;
	},

	[types.FETCH_TOURNAMENT_CONTACTS]: (state, payload) => {
		state.contacts = payload;
	},

	[types.FETCH_TOURNAMENT_BANKS]: (state, payload) => {
		state.banks = payload;
	},

	[types.FAILURE_FETCH_TOURNAMENT]: (state, payload) => {
		state.failure = payload;
	},

	[types.DELETE_BANK_ACCOUNT]: (state, payload) => {
		state.banks = state.banks.filter(bank => bank.account_id_tournament !== payload);
		state.tournament.banks -= 1;
	},

	[types.ADD_BANK_ACCOUNT]: (state, payload) => {
		state.tournament.banks += parseInt(payload);
	},

	[types.DELETE_CONTACT]: (state, payload) => {
		state.contacts = state.contacts.filter(bank => bank.id !== payload);
		state.tournament.contacts -= 1;
	},

	[types.ADD_CONTACT]: (state, payload) => {
		state.tournament.contacts += parseInt(payload);
	},

	[types.DELETE_ORGANIZERS]: (state, payload) => {
		state.tournament.organizers = state.tournament.organizers.filter(organizer => organizer.id !== payload);
	},

	[types.FETCH_TOURNAMENT_DATE]: (state, payload) => {
		state.tournamentDate = payload;
	},

	[types.FETCH_TOURNAMENT_DATE_PENDING]: (state, payload) => {
		state.fetchTournamentDatePending = payload
	},

	[types.ALL_THE_AWARD_OF_THE_YEAR]: (state, payload) => {
		state.totalPrizeAwardInYear = payload;
	},

	[types.FETCH_TOURNAMENT_STAGE]: (state, payload) => {
		state.stages = payload;
	},

	[types.FETCH_TOURNAMENT_FILES]: (state, payload) => {
		state.files = payload;
	},

	[types.DELETE_TOURNAMENT_FILE]: (state, payload) => {
		// state.files = state.files.filter(e => e.id !== payload);
		state.tournament.files -= 1;
	},

	[types.ADD_TOURNAMENT_FILES]: (state, payload) => {
		state.tournament.files += parseInt(payload)
	}
};
