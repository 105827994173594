import types from "@/store/modules/encaste/chicks/types";
import { createObject } from "./state";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	// [types.FETCH_PEDIGREE]: (state, payload) => {
	// 	state.pedigree = payload;
	// },
	[types.SET_SEARCH_CHICK]: (state, payload) => {
		state.searchChicks = payload;
	},

	[types.REMOVE_SEARCH_CHICK]: (state, payload) => {
		state.searchChicks = state.searchChicks.filter((chick) => chick.id !== payload.id);
	},

	[types.GET_CHICKS]: (state, { data, from, last_page, to, total }) => {
		state.chicks = data;
		state.start_page = from;
		state.to_page = to;
		state.total_data = total;
	},

	[types.SET_JSON_SEARCH_CHICKS]: (state, payload) => {
		state.jsonSearchChicks = payload;
	},

	[types.SET_YEAR_MAIN]: (state, payload) => {
		console.log('year', payload);
		state.year = payload;
	},

	[types.RESET_CHICKS]: (state, payload) => {
		state.jsonSearchChicks = createObject();
	},

	[types.GET_CHICKS_RECURSIVE]: (state, { data }) => {
		// console.log('DATA', data);
		state.chicks_recursive = data;
	},

	[types.SET_JSON_SEARCH_CHICKS_RECURSIVE]: (state, payload) => {
		state.jsonSearchChicksRecursive = payload;
	},

	[types.SET_CHICKS]: (state, payload) => {
		state.chicks = payload;
	},

	[types.SET_CHICKS_RECURSIVE]: (state, payload) => {
		state.chicks_recursive = payload;
	},

	[types.RESET_STATUS_MASSIVELY]: (state, payload) => {
		// state.chicks = ;
		for (let i = 0; i < state.chicks.length; i++) {
			state.chicks[i].status_dead_massively = false;
		}

		for (let i = 0; i < state.chicks_recursive.length; i++) {
			state.chicks_recursive[i].status_dead_massively = false;
		}
	},

	[types.RESET_STATUS_ITEM_DEAD]: (state, { correlative }) => {
		state.chicks = state.chicks.map((chick) => {
			return {
				...chick,
				status_dead_massively: chick.correlative === correlative ? false : chick.status_dead_massively,
			};
		});
	},

	[types.ACTIVE_STATUS_ITEM_DEAD]: (state, { correlative }) => {
		state.chicks = state.chicks.map((chick) => {
			return {
				...chick,
				status_dead_massively: chick.correlative === correlative ? true : chick.status_dead_massively,
			};
		});
	},

	[types.TOGGLE_RELOAD_CHICKS]: (state, { reload }) => {
		state.reload_chicks = reload;
	},

	[types.TOGGLE_RELOAD_CHICKS_RECURSIVE]: (state, { reload }) => {
		state.reload_chicks_recursive = reload;
	},

	[types.TOGGLE_RELOAD_COUNTERS]: (state, { reload }) => {
		state.reload_counters = reload;
	},

	[types.SET_TOTAL_CHICKS]: (state, { total }) => {
		state.total_data = total;
	},
	[types.DECREMENT_TOTAL_CHICKS]: (state, payload) => {
		state.total_data = state.total_data - 1;
		state.to_page = state.to_page - 1
	},
	[types.SET_SEASON_MAIN]: (state, { start_date, end_date }) => {
		// console.log({payload});
		// state.year = isNaN(payload * 1) ? null : payload * 1;
		// state.year = payload;
		state.start_date = start_date;
		state.end_date = end_date;
	},
};
