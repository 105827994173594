import store from "@/store";

export const M_SET_SPECIMEN_TRACKING_PHOTOS = (state, data) => {
  state.S_SPECIMEN_TRACKING_PHOTOS = data;
};

export const M_ADD_MASSIVELY_PHOTOS = (state, data) => {
  const moreKeysPhotos = {
    new_photo: null,
    file: null,
    new_photo_id: null,
    success_save: false,
    feature: true,
  };
  data = { ...data, ...moreKeysPhotos };
  state.S_MASSIVELY_PHOTOS.unshift(data);
  if (data.photo) {
    store.commit("customLightBox/M_ADD_ARRAY_IMAGES", data);
  }
};

export const M_CLEAN_MASSIVELY_PHOTOS = (state) => {
  state.S_MASSIVELY_PHOTOS = [];
};

export const M_REMOVE_SPECIMEN_PHOTO = (state, data) => {
  state.S_MASSIVELY_PHOTOS = state.S_MASSIVELY_PHOTOS.filter(
    (photos) => photos.specimen_id !== data
  );
};
