/** @ts-check */

import inventoryService from "@/services/inventory.service";
import store from "@/store";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async getInventories({ commit }, { module }) {
		try {
			commit("app/M_IS_PRELOADING", true, { root: true });
			// store.dispatch("app/M_IS_PRELOADING", true);
			// commit("app/M_IS_PRELOADING", true);
			const { data } = await inventoryService.index({ module });

			commit("GET_INVENTORIES", {
				data,
				module
			});
			commit("app/M_IS_PRELOADING", false, { root: true });
			// commit('')
		} catch (error) {
			throw error;
		}
	},

	async storeInventory({ commit }, { value, start_date, module, parent_id, alias, comment }) {
		const { data } = await inventoryService.store({ module, start_date, value, parent_id, alias, comment });
	},

	async updateInventory({ commit }, { value, start_date, status_id, id }) {
		// console.log('data', message);
		// this.showToast("success", "top-right", "Corrales", "SuccessIcon", message);
	},

	async getMatch({ commit }, { id, module, page, perpage, search }) {
		commit("app/M_IS_PRELOADING", true, { root: true });
		const {
			data: { data, from, last_page, to, total },
		} = await inventoryService.getMatch({
			id,
			module,
			page,
			perpage,
			search,
		});

		commit(types.GET_MATCH, {
			data,
			from,
			last_page,
			to,
			total,
		});
		commit("app/M_IS_PRELOADING", false, { root: true });
	},

	async getUnMatch({ commit }, { id, module, page, perpage, search, type }) {
		commit("app/M_IS_PRELOADING", true, { root: true });

		const {
			data: { data, from, last_page, to, total },
		} = await inventoryService.getUnMatch({
			id,
			module,
			page,
			perpage,
			search,
			type,
		});

		commit(types.GET_UN_MATCH, {
			data:
				type == 2
					? data.map((it) => ({
							plate: it,
							check: false,
							id: null,
							alias: null,
							status_id: 2,
							status_description: null,
							origin_id: null,
							category_id: null,
							gender_id: null,
							addi_plate: null,
							color_id: null,
							dob: null,
							check: false,
							description: null,
							date_status: null,
							client_id: null,
							disease_id: null,
							user_id: null,
							weight: null,
							pounds_weight: null,
							reason_id: null,
							evidences: [],
							customFormData: [],
							price: null,
					  }))
					: data,
			from,
			last_page,
			to,
			total,
		});
		commit("app/M_IS_PRELOADING", false, { root: true });
	},

	async getCounterInventory({ commit }, { id, module, is_change = true, matchIndex = 0 }) {
		commit("app/M_IS_PRELOADING", true, { root: true });

		// this.$store.commit("app/M_IS_PRELOADING", true);

		const {
			data: [{ counters, code, value, status_id, alias, dad_code }],
		} = await inventoryService.index({ module, id });

		commit(types.GET_COUNTERS_INVENTORY, {
			data: { ...counters, is_change, module, matchIndex, code, value, status_id, id, alias, dad_code },
		});

		commit("app/M_IS_PRELOADING", false, { root: true });
	},

	async removePlateOrCintillo({ commit }, { id, value }) {
		const { data } = await inventoryService.updateValue({
			add: [],
			deleted: [value],
			id,
		});

		return data;
	},

	async addOrRemoveValuesInventory({ commit }, { id, deleted = [], add = [] }) {
		const data = await inventoryService.updateValue({
			add,
			deleted,
			id,
		});

		return data;
	},

	async getDetails({ commit }, { module, id, page, perpage, search, type, status_id }) {
		try {
			commit("app/M_IS_PRELOADING", true, { root: true });

			if (type == 1) {
				const {
					data: {
						data: { data, from, last_page, to, total },
						counters,
					},
				} = await inventoryService.getDetails({ id, module, page, perpage, search, type, status_id });
				// console.log('counters', counter);
				commit(types.GET_MATCH, {
					data,
					from,
					last_page,
					to,
					total,
					type,
					counters,
				});
			} else {
				const {
					data: { data, from, last_page, to, total },
				} = await inventoryService.getDetails({ id, module, page, perpage, search, type, status_id });
				// console.log('counters', counter);
				commit(types.GET_MATCH, {
					data,
					from,
					last_page,
					to,
					total,
					type,
				});
			}

			commit("app/M_IS_PRELOADING", false, { root: true });
		} catch (error) {
			throw error;
		}
	},
};
