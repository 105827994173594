export default [
	{
		path: "/torneos",
		name: "tournaments",
		component: () => import("@/views/amg/tournaments/v2/main.vue"),
		redirect: { name: "tournaments-dashboard" },
		children: [
			{
				path: "canvan",
				name: "tournaments-canvan",
				component: () => import("@/views/amg/tournaments/v2/views/Canvan.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Torneos",
					breadcrumb: [
						{
							text: "Canvan",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "events",
				name: "tournaments-events",
				component: () => import("@/views/amg/tournaments/v2/views/Events.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Torneos",
					breadcrumb: [
						{
							text: "Fechas",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "dashboard",
				name: "tournaments-dashboard",
				component: () => import("@/views/amg/tournaments/v2/views/Dashboard.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Torneos",
					breadcrumb: [
						{
							text: "Dashboard",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
		],
	},
	{
		path: "/torneos/calendar",
		name: "tournaments-calendar",
		component: () => import("@/views/amg/tournaments/v2/views/Calendar.vue"),
		meta: {
			layout: "vertical",
			pageTitle: "Torneos",
			breadcrumb: [
				{
					text: "Calendario",
					active: true,
				},
			],
			roles: [1, 5, 7],
		},
	},
]
