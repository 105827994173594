import _ from "lodash";
/** @returns { Object<string, import('vuex').Getter> } */
export default {
	getTournamentCounterBy: (state) => (by) => {
		const total = state.tournament[by].length;
		return {
			total,
		};
	},
	/** @return {Record<string, any>} */
	mapFlyersToGallery({ tournament }) {
		if (tournament.flyers.length) {
			return tournament.flyers.map((flyer) => ({
				src: flyer.flyer,
				description: tournament.name + " - " + tournament.city,
			}));
		}

		return [];
	},

	/**
	 * @param { import('vuex').Getter }
	 * @returns
	 */
	totalOfAllRoosterPlayed({ tournament }) {
		const result = { victories: 0, draws: 0, defeats: 0 };
		if (_.isEmpty(tournament.dates_played.items)) {
			return result;
		}
		
		const dates = _.flatten(Object.values(tournament.dates_played.items))
		const totalCount = dates.length;

		for (let i = 0; i < totalCount; i++) {
			result.victories += parseInt(dates[i].triunfos);
			result.draws += parseInt(dates[i].empates);
			result.defeats += parseInt(dates[i].perdidos);
		}

		return result;
	},

	/**
	 * @param { import('vuex').Getter }
	 * @returns
	 */
	tournamentStagesForSelect(state) {
		return state.stages.map((stage) => ({
			value: stage.id,
			label: stage.name,
		}));
	},

	isFetchTournamentDatePending(state) {
		return state.fetchTournamentDatePending;
	},
};
