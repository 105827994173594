import axios from "@/axios";

const url = 'api/clients/'

class ClientsService {
  async getClients() {
    try {
      return await axios.get(`${url}get-clients`);
    } catch (err) {
      console.error(err)
    }
  }
  async saveOuputSpecimenClient(body) {
    try {
      return await axios.post(`${url}save-ouput-specimen-client`,
        body
      );
    } catch (err) {
      console.error(err)
    }
  };
}
export default new ClientsService()
