/* STATES */

const state = {
  S_ARRAY_IMAGES: [],
  S_INDEX_IMAGES: null,
};

/* GETTERS */
const getters = {};

/* MUTATIONS */
const mutations = {
  M_ADD_ARRAY_IMAGES(state, data) {
    state.S_ARRAY_IMAGES.push({
      title: data.alias ? data.alias : "sin alias" + "  |  " + data.plate,
      src: data.photo,
      specimen_id: data.specimen_id,
    });
  },
  M_SET_INDEX_IMAGES(state, data) {
    if (data) {
      let index = state.S_ARRAY_IMAGES.findIndex(
        (image) => image.specimen_id === data.specimen_id
      );
      state.S_INDEX_IMAGES = index;
      return;
    }
    state.S_INDEX_IMAGES = null;
  },
  M_CLEAN_ARRAY_IMAGES(state) {
    state.S_ARRAY_IMAGES = [];
  },
};

/* ACTIONS */
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
