<template>
	<div>
		<template v-if="currentUser.id && (isAdmin || isSupervisor)">
			<b-sidebar
				scrollable
				hide-footer
				size="xmd"
				width="90%"
				title="Huevos Encontrados"
				@hidden="handleHidden"
				v-model="isActive"
				body-class="bg-white p-2"
			>
				<div class="card flex-row d-flex justify-content-end align-items-center mb-1 p-2">
					<b-button
						class="d-flex align-items-center justify-content-center"
						variant="primary"
						size=""
						@click="showCreateRegister = true"
					>
						<FeatherIcon icon="PlusIcon" size="18" class="mr-50" />
						Agregar
					</b-button>
				</div>

				<ListEggsFound :show="showCreateRegister" />
				<CreateRegisterModal
					:show="showCreateRegister"
					@closing="showCreateRegister = false"
					@refresh="getTableData"
				/>
				<ApproveRegisterModal
					:show="infoApproveRegister.show"
					:id="infoApproveRegister.id"
					:type="infoApproveRegister.type"
					@closing="clearReviewRegister"
					@refresh="getTableData"
					:title="infoApproveRegister.title"
				/>
			</b-sidebar>

			<b-modal @hide="hideModalTracking" hide-footer v-model="modalTracking.show" :title="modalTracking.title">
				<div>
					<b-table-simple sticky-header class="position-relative">
						<b-thead>
							<b-tr class="text-center">
								<template>
									<b-th class="p-1">Comentario</b-th>
									<b-th class="p-1">USUARIO</b-th>
									<b-th class="p-1">FECHA</b-th>
								</template>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="(tracking, index) in modalTracking.data" :key="index">
								<b-td class="text-center">
									{{ tracking.comment ? tracking.comment : "---" }}
								</b-td>
								<b-td class="text-center">
									{{ tracking.user_name }}
								</b-td>
								<b-td class="text-center">
									{{ tracking.created_at | myGlobalDayShort }}
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</b-modal>
			<b-sidebar v-model="sidebarFilters.show" :title="sidebarFilters.title" body-class="p-2 bg-white">
				<b-form-group label="Inicio" class="select-date">
					<flat-pickr
						v-model="date_in"
						class="form-control bg-white"
						placeholder="Seleccionar fecha"
						:config="{ maxDate: date_fn }"
					/>
				</b-form-group>
				<b-form-group label="Fin" class="select-date">
					<flat-pickr
						v-model="date_fn"
						class="form-control bg-white"
						placeholder="Seleccionar fecha"
						:config="{ minDate: date_in }"
					/>
				</b-form-group>
				<div>
					<button @click="getTableData" class="btn btn-primary w-100">Buscar</button>
				</div>
				<div>
					<button @click="refreshFilters" class="mt-1 btn btn-info w-100">
						<v-icon name="gi-broom"></v-icon>
						Limpiar
					</button>
				</div>
			</b-sidebar>
		</template>

		<CreateRegisterModal v-else :show="isActive" @closing="handleHidden" @refresh="getTableData" />
	</div>
</template>

<script>
import { ref, watch, computed, reactive } from "@vue/composition-api"
import moment from "moment"
import store from "@/store"

import eggsFoundService from "@/services/header/eggs-found.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CreateRegisterModal from "./CreateRegisterModal.vue"
import ApproveRegisterModal from "./ApproveRegisterModal.vue"
import ListEggsFound from "./ListEggsFound.vue"

export default {
	components: { CreateRegisterModal, ApproveRegisterModal, SpecimenPlate, ListEggsFound },
	props: { show: Boolean },
	setup(props, ctx) {
		// handle sidebar model
		let isActive = ref(false)
		watch(
			() => props.show,
			(val) => {
				if (val) {
					isActive.value = true
					getCounters()
					getTableData()
				}
			}
		)
		function handleHidden() {
			isActive.value = false
			ctx.emit("closing")
		}

		// counters
		let counters = reactive({
			pending: 0,
			accepted: 0,
			rejected: 0,
		})
		async function getCounters() {
			let data = await eggsFoundService.getCounters()
			counters.pending = data.data.find((counter) => counter.id == 1)
			counters.accepted = data.data.find((counter) => counter.id == 2)
			counters.rejected = data.data.find((counter) => counter.id == 3)

			store.commit("headers/SET_VALUES_EGGS_FOUND", {
				eggsFound: {
					...store.state.headers.eggsFound,
					counter: counters.pending.count,
				},
			})
		}

		// table handling
		let date_fn = ref(null)
		let date_in = ref(null)
		let curTab = ref(0)
		let tLoading = ref(false)
		let tData = ref([])
		let pagination = reactive({
			startPage: 1,
			toPage: 2,
			totalRows: 1,
			curPage: 1,
			perPage: 10,
		})
		let sidebarFilters = ref({
			show: false,
			title: "FILTROS",
		})
		let tSearch = ref("")
		let isSupervisor = computed(() => store.state.auth.currentUser.privileges.includes(1))
		let isAdmin = computed(() => store.state.auth.currentUser.roles.includes(1))
		let tDataHead = computed(() => {
			let row = tData.value[0]
			if (!!!row) return {}
			let heads = Object.keys(row)
			// remove rows
			heads = heads.filter((key) => {
				if (["id", "created_at", "postura_code", "updated_at"].includes(key)) return false
				return true
			})
			// add rows
			if (curTab.value == 0 ? isSupervisor.value || isAdmin.value : true) {
				heads.push("acciones")
			}

			if (curTab.value == 1) {
				heads.splice(heads.length - 2, 0, "postura")
			}

			// change rows label
			heads = heads.map((key) => {
				switch (key) {
					case "quantity":
						return "cantidad"
					case "created":
						return "Creado por"
					case "comment":
						return "comentario"
				}
				return key
			})

			return heads
		})

		async function getTableData() {
			tLoading.value = true
			let { data } = await eggsFoundService.getAll({
				page: pagination.curPage,
				perpage: pagination.perPage,
				q: curTab.value + 1,
				search: tSearch.value,
				start_date: date_in.value,
				end_date: date_fn.value,
			})
			pagination.totalRows = data.total
			pagination.startPage = data.from
			pagination.toPage = data.to
			tData.value = data.data.map((row) => {
				let rMadrilla = row.madrilla
				return {
					id: row.id,
					madrilla: { ...rMadrilla, image: rMadrilla.url },
					quantity: row.quantity,
					comment: row.comment,
					postura_code: row.postura_code,
					created: row.created,
					updated_at: row.updated_at,
				}
			})
			await getCounters()
			tLoading.value = false
		}

		watch(
			() => pagination.curPage,
			(val) => getTableData()
		)

		function formatDate(date) {
			return moment(date).format("MM/DD/YY")
		}

		// modals
		let showCreateRegister = ref(false)
		let infoApproveRegister = reactive({
			show: false,
			id: null,
			type: 1,
			title: null,
		})

		let showSidebarList = ref(false)

		let modalTracking = reactive({
			show: false,
			title: null,
			data: [],
		})

		function reviewRegister(id, type, madrilla) {
			infoApproveRegister.id = id
			infoApproveRegister.show = !infoApproveRegister.show
			infoApproveRegister.type = type
			infoApproveRegister.title = `Madrilla / ${madrilla.alias} (${madrilla.plate})`
		}

		function clearReviewRegister(id) {
			infoApproveRegister.id = null
			infoApproveRegister.show = false
		}

		async function openModal(item) {
			this.isPreloading()
			const { data } = await eggsFoundService.getTracking({
				id: item.id,
			})
			modalTracking.show = true
			modalTracking.data = data
			modalTracking.title = `Madrilla / ${item.madrilla.alias}(${item.madrilla.plate})`
			this.isPreloading(false)
		}
		function hideModalTracking() {
			modalTracking.show = false
			modalTracking.data = []
		}

		async function refreshFilters() {
			date_in.value = null
			date_fn.value = null
			await getTableData()
		}

		return {
			isActive,
			handleHidden,
			counters,
			curTab,
			tLoading,
			tData,
			tDataHead,
			pagination,
			tSearch,
			formatDate,
			getTableData,
			showCreateRegister,
			infoApproveRegister,
			reviewRegister,
			clearReviewRegister,
			modalTracking,
			openModal,
			hideModalTracking,
			date_in,
			date_fn,
			sidebarFilters,
			refreshFilters,
			showSidebarList,
		}
	},
}
</script>

<style lang="scss" scoped>
.width-input-group {
	width: 100%;
	@media screen and (min-width: 600px) {
		// width: 40%;
	}

	@media screen and (min-width: 1100px) {
		// width: 20%;
	}
}
.nav-item {
	background: red;
	width: 100% !important;
}

.fa-thumbs-down {
	transform: scaleX(-1);
}
.select-date {
	width: 100%;
	@media screen and (min-width: 700px) {
		width: auto;
	}
}
</style>
