import axios from "@/axios"

const base = "/api/task"

class TasksService {
	async getTasksBrain() {
		const { data } = await axios.get(`${base}`, {})
		return data
	}
	async insertTaskBrain({ name }) {
		const { data } = await axios.post(`${base}`, { name })
		return data
	}
	async updateTaskBrain({ task_id, name }) {
		const { data } = await axios.put(`${base}/${task_id}`, { name })
		return data
	}
	async deleteTaskBrain({ task_id }) {
		const { data } = await axios.delete(`${base}/${task_id}`, {})
		return data
	}
	async getUserTasks({ status_id, page, perpage, user_id }) {
		const { data } = await axios.get(`${base}/get-tasks-users`, {
			params: { status_id, page, perpage, user_id },
		})
		return data
	}
	async insertUserTask({ task_id, user_id, comment, specimens }) {
		const { data } = await axios.post(`${base}/insert-task-user`, { task_id, user_id, comment, specimens })
		return data
	}
	async updateUserTask({ task_id, status_id, comment }) {
		const { data } = await axios.put(`${base}/update-status-task/${task_id}`, {
			status_id,
			comment,
		})
		return data
	}
	async getUserTaskTracking({ task_id }) {
		const { data } = await axios.get(`${base}/get-tracking/${task_id}`, {})
		return data
	}
	async getUserTaskCounters({ user_id }) {
		const { data } = await axios.get(`${base}/counters`, {
			params: {
				user_id,
			},
		})
		return data
	}
	async getUsersList() {
		const { data } = await axios.get(`${base}/users`, {})
		return data
	}

	async getSpecimens({ search }) {
		const { data } = await axios.get(`${base}/get-specimens`, {
			params: { search },
		})
		return data
	}

	async getPendingsByTask({ task_id }) {
		const { data } = await axios.get(`${base}/get-details/${task_id}`)
		return data
	}
	async storePendingsData({ task_user_id, type_action, specimens }) {
		const { data } = await axios.post(`${base}/store-details`, {
			task_user_id,
			type_action,
			specimens,
		})
		return data
	}
}

export default new TasksService()
