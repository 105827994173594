import axios from "@/axios"
const base = "/api/specimens/decrest"

class DecrestService {
	async getDecrestedConfig() {
		let { data } = await axios.get(`${base}/conditions`)
		return data
	}

	async setDecrestedConfig({ decrest_conditions,origin }) {
		let { data } = await axios.put(`${base}/conditions`, { decrest_conditions,origin })
		return data
	}

	async getDecrested({ date_in, date_fn, search, status_id, page, perpage, orderby, order }) {
		let { data } = await axios.get(`${base}`, {
			params: { date_in, date_fn, search, status_id, page, perpage, orderby, order },
		})
		return data
	}

	async isAvailableForDecrest(specimen_id) {
		let { data } = await axios.get(`${base}/${specimen_id}/decrest-available`)
		return data
	}

	async setDecrested({ specimens }) {
		let { data } = await axios.put(`${base}/update-decrest`, { specimens })
		return data
	}

	async getDecrestedCounters() {
		let { data } = await axios.get(`${base}/counters`)
		return data
	}

	async saveDecresteds({ id }) {
		let { data } = await axios.put(`${base}/update-decrest`, { id })
		return data
	}
}

export default new DecrestService()
