/** @ts-check */
import { showToast } from "@/helpers/toast";
import tournamentService from "@/services/tournament.service";
import coliseumService from "@/views/amg/coliseums/coliseum.service";
import contactService from "@/views/amg/contacts/contact.service";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async fetchTournament({ commit, dispatch }, { id }) {
		try {
			const tournament = await tournamentService.getTournament(id);
			commit(types.FILL_TOURNAMENT, tournament);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async updateTournament({ commit, dispatch }, { id, tournament }) {
		try {
			dispatch("fetchPending", true);
			await tournamentService.updateTournament(id, tournament);
			dispatch("fetchTournament", { id });
			showToast("El torneo ha sido actualizado con éxito!");
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async deleteFlyers({ commit }, { id, user_id }) {
		try {
			await tournamentService.deleteTournamentFlyer(id, user_id);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 */
	async fetchColiseum({ commit }) {
		try {
			const {
				data: { data },
			} = await coliseumService.getColiseumsToSelect({
				status: 0,
				campo: null,
				pageIn: 1,
				perPageIn: 1000,
			});

			const coliseum = data.map((item) => ({
				value: item.id_coliseum,
				label: item.name,
				city: item.city,
			}));

			commit(types.FETCH_COLISEUM, coliseum);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, number>} { id } Tournament id
	 */
	async fetchTournamentBanks({ commit }, { id }) {
		try {
			const banks = await tournamentService.getTournamentBanks(id);
			commit(types.FETCH_TOURNAMENT_BANKS, banks);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, number>}  { id } Account id
	 */
	async deleteBankAccountTournament({ commit }, { id }) {
		try {
			await tournamentService.deleteBankAccountTournament(id);
			commit(types.DELETE_BANK_ACCOUNT, id);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, number>}
	 */
	async addBankAccountsTournament({ commit, dispatch }, { tournament_id, accounts }) {
		try {
			await tournamentService.addBankAccountsTournament({ tournament_id, accounts });
			commit(types.ADD_BANK_ACCOUNT, accounts.length);
			dispatch("fetchTournamentBanks", { id: tournament_id });
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, number>} { id } Tournament id
	 */
	async fetchTournamentContacts({ commit }, { id }) {
		try {
			const contacts = await tournamentService.getTournamentContacts(id);
			commit(types.FETCH_TOURNAMENT_CONTACTS, contacts);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, number>}  { id } Account id
	 */
	async deleteContactFromTournament(
		{ commit, dispatch, state },
		{ id, tournament_contact_id, tournament_id, user_id }
	) {
		try {
			const sameOrganizer = state.tournament.organizers.find((organizer) => organizer.id === id);
			await contactService.deleteContactFromTournament({
				deleted_by: user_id,
				tournament_contact_id: tournament_contact_id,
				id: sameOrganizer ? id : null,
				tournament_id: tournament_id,
			});
			commit(types.DELETE_CONTACT, id);

			if (sameOrganizer) {
				commit(types.DELETE_ORGANIZERS, id);
			}
			dispatch("fetchTournamentContacts", { id: tournament_id });
			showToast("El ha sido eliminado del torneo con éxito!");
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async addContactsToTournament({ commit, dispatch }, { tournament_id, contacts, user_id }) {
		try {
			const contactsFiltered = contacts.filter((e) => Boolean(e.id));
			await contactService.addContactsToTournament({
				tournament_id: tournament_id,
				contacts: contactsFiltered,
				created_by: user_id,
			});
			commit(types.ADD_CONTACT, contactsFiltered.length);
			dispatch("fetchTournamentContacts", { id: tournament_id });
			showToast("Los contactos han sido registrados con éxito!");
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async fetchTournamentDate({ commit, dispatch }, { date_id }) {
		try {
			dispatch("fetchPending", true);
			commit(types.FETCH_TOURNAMENT_DATE_PENDING, true);

			const { data: response } = await tournamentService.getTournamentDate({
				tournament_date_id: date_id,
			});

			commit(types.FETCH_TOURNAMENT_DATE, response);
			commit(types.FETCH_TOURNAMENT_DATE_PENDING, false);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async fetchTournamentStages({ commit, dispatch }) {
		try {
			const response = await tournamentService.getAllTournamentStages();
			commit(types.FETCH_TOURNAMENT_STAGE, response);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async fetchTournamentFiles({ commit, dispatch }) {
		try {
			const response = await tournamentService.getAllTournamentStages();
			commit(types.FETCH_TOURNAMENT_FILES, response);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async addTournamentFiles({ commit, dispatch }, { files, tournament_id, user_id }) {
		try {
			const filesFormatted = new FormData();
			files.forEach((file) => {
				filesFormatted.append("images[]", file, file.name);
			});
			filesFormatted.append("tournament_id", tournament_id);
			filesFormatted.append("created_by", user_id);
			await tournamentService.saveTournamentFiles(filesFormatted);
			commit(types.ADD_TOURNAMENT_FILES, files.length);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async deleteTournamentFile({ commit, dispatch }, { file_id, user_id }) {
		try {
			await tournamentService.deleteTournamentFile(file_id, user_id);
			commit(types.DELETE_TOURNAMENT_FILE, file_id);
			dispatch("fetchPending", false);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	sumTotalAwardOfTheYear({ commit, dispatch }, { total }) {
		commit(types.ALL_THE_AWARD_OF_THE_YEAR, total);
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {Object<string, any>}
	 */
	async deleteFrontSpecimen({ commit, dispatch }) {},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {boolean} state
	 */
	fetchPending({ commit }, state) {
		commit("app/M_IS_PRELOADING", state, { root: true });
	},
};
