import axios from "@/axios"

const url = "/api/videos"
class VideosService {
	async saveVideoTope({ videoForm, config }) {
		const data = await axios.post(`${url}/upload-video`, videoForm, config)
		return data
	}

	async getVideoById(body) {
		const data = await axios.post(`${url}/get-by-id`, body)
		return data
	}

	async updateVideoTope({ videoForm, config }) {
		const data = await axios.post(`${url}/update-video-topes`, videoForm, config)
		return data
	}

	async updateDateVideoTope({ videoForm, config, id }) {
		const data = await axios.post(`/api/confrontation/update/${id}`, videoForm, config)
		return data
	}

	async getAmarrador() {
		const { data } = await axios.post("/api/get-amarrador")
		return data
	}

	async getCareador() {
		const { data } = await axios.post("/api/get-careador")
		return data
	}

	async getGallos() {
		const { data } = await axios.get("/api/specimens/get-gallos")
		return data
	}

	async getTournaments() {
		try {
			const { status, data } = await axios.get(`${url}/get-tournaments`)
			return { status, data: data.tournaments }
		} catch (error) {
			const { status, response } = error
			console.log(response.data.message)
			return { status, message: response.data.message }
		}
	}

	async getFightResultsByTournamentId({ tournament_id }) {
		try {
			console.log({ tournament_id })
			const { status, data } = await axios.get(`${url}/get-fight-results-by-tournament-id/${tournament_id}`)
			return { status, data: data.results }
		} catch (error) {
			const { status, response } = error
			console.log(response.data.message)
			return { status, message: response.data.message }
		}
	}

	async saveVideoTournament({ videoForm, config }) {
		// const data = await axios.post(`api/videos/upload-video`, videoForm, config);
		// return data;
	}

	async updateVideoTournament({ videoForm, config }) {
		const data = await axios.post(
			`${process.env.VUE_APP_URL_NODE}/api/v1/video/update-upload-tournaments`,
			videoForm,
			config
		)
		return data
	}

	async counterVideosSpecimen({ specimen_id }) {
		try {
			const { data } = await axios.get(`${url}/counter-videos-category`, { params: { specimen_id } })
			return data
		} catch (error) {
			return error
		}
	}

	async setChunks({ videoForm, config }) {
		try {
			const { data } = await axios.post(`${url}/set-chunks`, videoForm, config)
			return data
		} catch (error) {
			return 500
		}
	}

	async validStatusVideo({ id }) {
		try {
			const { data } = await axios.get(`${url}/valid-status-video/${id}`)
			return data
		} catch (error) {
			return error
		}
	}


	//

	//valid-status-video
}

export default new VideosService()
