export default [
	{
		path: "/preparacion",
		name: "preparacion-sr",
		component: () => import("@/views/amg/preparacion/main.vue"),
		redirect: { name: "preparacion-sr-actives" },
		children: [
			{
				path: "actives",
				name: "preparacion-sr-actives",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Activos",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "finished",
				name: "preparacion-sr-finished",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Pre-listos",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "played",
				name: "preparacion-sr-played",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Jugados",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "canceled",
				name: "preparacion-sr-canceled",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Cancelados",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "inactive",
				name: "preparacion-sr-inactive",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Inactivos",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
			{
				path: "trip",
				name: "preparacion-sr-trip",
				component: () => import("@/views/amg/preparacion/views/Actives.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Preparación",
					breadcrumb: [
						{
							text: "Trip",
							active: true,
						},
					],
					roles: [1, 5, 7],
				},
			},
		],
	},
]
