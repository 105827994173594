<template>
	<b-modal centered size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group label="Comentario">
				<b-form-textarea v-model="comment" />
			</b-form-group>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="info" @click="toFinished" v-if="isFinishing">Finalizar</b-button>
			<b-button variant="danger" @click="toCanceled" v-else>Anular</b-button>
		</template>
	</b-modal>
</template>

<script>
import useTasks from "./useTasks"
import tasksService from "@/services/tasks.service.js"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		comment: null,
	}),
	computed: {
		isFinishing() {
			return this.info._status == 2
		},
		isCanceling() {
			return this.info._status == 3
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.title = null
			this.comment = null
			this.$emit("closing")
		},
		async toFinished() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se finalizara la tarea ${this.info.name}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await tasksService.updateUserTask({
				task_id: this.info.id,
				status_id: this.info._status,
				comment: this.comment,
			})
			this.isLoading = false
			this.showToast("success", "top-right", "Tareas", "CheckIcon", `Se finalizó la tarea de forma correcta`)
			this.$emit("refresh")

			this.counterTrigger = Symbol()
			this.handleHidden()
		},
		async toCanceled() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se cancelara la tarea de ${this.info.assign.name}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await tasksService.updateUserTask({
				task_id: this.info.id,
				status_id: this.info._status,
				comment: this.comment,
			})
			this.isLoading = false
			this.showToast("success", "top-right", "Tareas", "CheckIcon", `Se canceló la tarea de forma correcta`)
			this.$emit("refresh")
			this.counterTrigger = Symbol()
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `${this.isFinishing ? "Finalizar" : "Cancelar"} tarea de ${this.info.assign.name}`
			}
		},
	},
	setup() {
		const { counterTrigger } = useTasks()
		return { counterTrigger }
	},
}
</script>
