export default [
    {
        path: "/brain/items",
		name: "brain-items-sr",
		component: () => import("@/views/brain/items/ItemsMain.vue"),
		children: [
			{   
				path: "list",
				name: "brain-items-list-sr",
				component: () => import("@/views/brain/items/components/ItemListBrain.vue"),
				meta: {
					layout: "vertical",
					pageTitle: "Items",
					breadcrumb: [
						{
							text: "Cerebro",
							active: true,
						},
                        {
							text: "Items",
							active: true,
						},
                        {
							text: "Listado",
							active: true,
						},
					],
					roles: [1,2]
				},
			},
		],
    },
];
