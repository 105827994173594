import axios from "@/axios"

const url = "/api/tournament/events"

class RecommendedSpecimenService {
	async getEventsForRecommendedSpecimen(body) {
		try {
			const data = await axios.get(`${url}/recommended-specimens`, {
				params: { ...body },
			})
			return data
		} catch (error) {
			throw error
		}
	}

	async getSearchRecommendedSpecimen({ search }) {
		try {
			const data = await axios.get(`${url}/recommended-specimens/search`, {
				params: {
					search,
				},
			})
			return data
		} catch (error) {
			throw error
		}
	}

	async getSpecimensToEvent({ id, role }) {
		try {
			const data = await axios.get(`${url}/recommended-specimens/${id}`, { params: { role } })
			return data
		} catch (error) {
			throw error
		}
	}

	async finalizeRecommended(id, observation, status, specimens = []) {
		try {
			const data = await axios.post(`${url}/recommended-specimens/insert-tracking/${id}`, {
				observation,
				status,
				specimens,
			})
			return data
		} catch (error) {
			throw error
		}
	}

	async storeSpecimens({ body }) {
		try {
			const { data } = await axios.post(`${url}/recommended-specimens`, {
				...body,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async deleteRecommendedSpecimen({ id }) {
		try {
			const data = await axios.delete(`${url}/recommended-specimens/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async changeStatusRecommended(body) {
		try {
			const data = await axios.put(`${url}/recommended-specimens/${body.id}`, { ...body })
			return data
		} catch (error) {
			throw error
		}
	}

	async getRecommendedCounter(role = "admin") {
		try {
			const data = await axios.get(`${url}/recommended-specimens/counters`, { params: { role } })
			return data
		} catch (error) {
			throw error
		}
	}

	async updateToViewed(body) {
		try {
			const data = await axios.post(`${url}/recommended-specimens/update-to-viewed`, { ...body })
			return data
		} catch (error) {
			throw error
		}
	}

	async getTrackingStatus({ id }) {
		try {
			const data = await axios.get(`${url}/recommended-specimens/get-tracking/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async getRecommendedRejected({ id }) {
		try {
			const data = await axios.get(`${url}/recommended-specimens/rejected/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async activateRecommendeds(id, specimens) {
		try {
			const data = await axios.put(`${url}/recommended-specimens/activate/${id}`, { specimens })
			return data
		} catch (error) {
			throw error
		}
	}
}

export default new RecommendedSpecimenService()
