import axios from "@/axios";
const url = "/api/chick/";

class ChicksService {
	async getChicksAtives({ page, perpage }) {
		try {
			const { data } = await axios.get(`${url}get-chicks-actives`, { page, perpage });
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	async getChickById({ chick_id }) {
		try {
			const { status, data } = await axios.get(`${url}${chick_id}`);
			return { status, data };
		} catch (err) {
			const { status, response } = err;
			console.error(response.data.message);
			return { status, message: response.data.message };
		}
	}
}
export default new ChicksService();
