import types from "@/store/modules/encaste/insemination/types";
import fertilidadService from "@/views/amg/encaste/posturas/insemination/fertilidad.service";

/** @return { Object<string, import('vuex').Mutation> } */
export default {
	[types.GET_COUNTER_PENDINGS]: async (state, payload) => {
		// state.pedigree = payload;
		const { data, message, status } = await fertilidadService.getCounterPending();

		// console.log('data', );
		state.pending = data[0].count;
	},
};
