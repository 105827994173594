/** @ts-check */
import { showToast } from "@/helpers/toast";
import types from "./types";
import moment from "moment";

import encasteChicksService from "@/services/encaste/chicks.service";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	// async fetchPedigree({ commit }, { specimen_id }) {
	// 	try {
	// 		const pedigree = await pedigreeService.getSpecimenPedigree(specimen_id);
	// 		commit(types.FETCH_PEDIGREE, pedigree)
	// 		return pedigree;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// },
	async getChicks({ commit }, jsonSearchChicks) {
		const {
			chicks: { data, from, last_page, to, total },
		} = await encasteChicksService.getChicks({
			...jsonSearchChicks,
			chicks_id: JSON.stringify(jsonSearchChicks.chicks_id),
		});

		console.log('2');

		commit(types.GET_CHICKS, {
			data: data.map((item) => {
				return {
					...item,
					plate: item.plate || "",
					dob_edit: false,
					plate_edit: item.plate || "",
					plate_edit_status: false,
					dob: moment(item.dob).format("MM/DD/YYYY"),
					status_id_copy: item.status_id,
					status_dead_massively: false,
					status_gender_massively: false,
					gender_copy: item.copy,
					check_retirement: false
				};
			}),
			from,
			last_page,
			to,
			total,
		});
	},

	async getChicksRecursive({ commit }, jsonSearchChicks) {
		const {
			chicks: { data, from, last_page, to, total },
		} = await encasteChicksService.getChicks({
			...jsonSearchChicks,
			chicks_id: JSON.stringify(jsonSearchChicks.chicks_id),
		});


		commit(types.GET_CHICKS_RECURSIVE, {
			data: data.map((item) => {
				return {
					...item,
					plate: item.plate || "",
					dob_edit: false,
					plate_edit: item.plate || "",
					plate_edit_status: false,
					dob: moment(item.dob).format("MM/DD/YYYY"),
					status_id_copy: item.status_id,
					status_dead_massively: false,
					status_gender_massively: false,
					gender_copy: item.copy,
					check_retirement: false
				};
			}),
		});
	},
};
