// BSV Plugin Registration
import Vue from "vue";

import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { install } from "../directives/preloader";

import moment from "moment";

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue, {
	breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl"],
});
Vue.use(IconsPlugin);
// Composition API
Vue.use(VueCompositionAPI);
Vue.use(install, "loading");

import axios from "@/axios";
window.axios = axios;
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

// Hammer
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Vue clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Mask
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);
