import axios from "@/axios"

const url = "/api/incidences/"

class IncidencesService {
	async getIncidences({ is_admin, page, perpage, user_id = null, specimen_id = null, start_date, end_date, search }) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: { is_admin, page, perpage, user_id, specimen_id, start_date, end_date, search },
			})
			return data
		} catch (error) {
			console.error(error)
		}
	}
	async registerIncidence(body) {
		try {
			const data = await axios.post(`${url}`, body)
			return data
		} catch (error) {
			throw error
		}
	}
	async searchSpecimens({ search }) {
		try {
			const data = await axios.get(`${url}specimens/search/`, {
				params: { search },
			})
			return data
		} catch (error) {
			throw error
		}
	}
	async getUsersWithIncidences() {
		try {
			const { data } = await axios.get(`${url}users`)
			return data
		} catch (error) {
			console.error(error)
		}
	}
	async getIncidencesCounter() {
		try {
			const { data } = await axios.get(`${url}counter`)
			return data
		} catch (error) {
			console.error(error)
		}
	}
	async getIncidencesCounterBySpecimen({ specimen_id }) {
		try {
			const { data } = await axios.get(`${url}specimens/counter`, { params: { specimen_id } })
			return data
		} catch (error) {
			console.error(error)
		}
	}
	async markViewed(id) {
		try {
			const { data } = await axios.post(`${url}mark-viewed`, { user_id: id })
			return data
		} catch (error) {
			throw error
		}
	}
}
export default new IncidencesService()
