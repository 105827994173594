/**
 * @returns {import('vuex').Store}
 */

export const state = () => ({
	eggsFound: {
		sidebar: {
			show: false,
		},
		counter: 0
	},
});
