<template>
	<div>
		<b-modal
			no-close-on-backdrop
			hide-footer
			width="90%"
			title="Registrar Huevos Encontrados"
			v-model="isActive"
			@hidden="handleHidden"
			body-class="p-0 m-0"
		>
			<b-card class="mb-0">
				<div class="containwe-eggs">
					<b-form-group label="Madrilla">
						<b-form-input
							type="number"
							:class="{ 'is-invalid': plateMadrillaError }"
							v-model="plateMAdrilla"
							placeholder="Ingrese placa"
							@keyup.enter="saveRegister"
						></b-form-input>
						<small class="text-danger" v-if="plateMadrillaError">Placa no encontrada.</small>
						<!-- {{ plateMAdrilla }} -->
						<!-- <v-select
						v-model="sMadrilla"
						:options="madrillasOpts"
						:reduce="(opt) => opt.value"
						label="label"
						@search="searchMadrillasOpts"
						@option:selecting="saveSelected"
					>
						<template slot="no-options"> Sin madrillas encontradas </template>
					</v-select> -->
					</b-form-group>
					<b-form-group label="Cantidad">
						<vue-number-input controls center :min="1" v-model="eggsCount" class=" " />
					</b-form-group>
				</div>
				<!-- <b-form-group class="mb-3 w-full" label="Comentario">
					<b-textarea v-model="comment" style="resize: none" />
				</b-form-group> -->
				<div class="w-100 d-flex align-items-center justify-content-between">
					<div
						v-if="!(isAdmin || isSupervisor)"
						class="d-flex align-items-center cursor-pointer"
						@click="modalEggsFound.show = true"
					>
						<FeatherIcon icon="ListIcon" size="18" class="text-primary" />
						<p class="mb-0 font-small-2 ml-25 text-primary">Ver Historico</p>
					</div>
					<div v-else></div>
					<b-button
						class="d-flex align-items-center justify-content-center"
						variant="primary"
						:disabled="!plateMAdrilla"
						@click="saveRegister"
					>
						<FeatherIcon icon="SaveIcon" size="18" class="mr-50" />
						Guardar
					</b-button>
				</div>
			</b-card>
		</b-modal>

		<b-modal
			title="Seleccionar una madrilla"
			no-close-on-backdrop
			v-model="modalSelectMadrilla.show"
			hide-footer
			centered
		>
			<!-- {{ sMadrilla }} -->
			<div class="container-valid-plate">
				<div v-for="(specimen, index) in modalSelectMadrilla.data" class="container-valid-plate__item">
					<div class="skeletor-specimen-add" @click="setSelectSpecimen(specimen.id)">
						<div class="skeletor"></div>
						<SpecimenPlate
							:specimen="{
								id: specimen.id,
								plate: specimen.plate,
								alias: specimen.alias,
								thumb: specimen.thumb,
								url: specimen.image,
								additional_plate: specimen.additional_plate,
							}"
						/>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal
			no-close-on-backdrop
			size="xs"
			hide-footer
			hide-header
			centered
			v-model="modalRegisterMore.show"
			body-class="p-0 m-0"
		>
			<div class="modal-body confirm-title">
				<p class="text-center mt-1 mb-0">¿Continuar registrando?</p>
			</div>
			<div class="confirm m-1">
				<div class="option bg-success cursor-pointer" @click="checkRegisterMore">SI</div>
				<div class="option bg-danger cursor-pointer" @click="cancelRegisterMore">NO</div>
			</div>
		</b-modal>

		<b-modal size="xl" v-model="modalEggsFound.show" title="Huevos encontrados" hide-footer>
			<ListEggsFound v-if="modalEggsFound.show" />
		</b-modal>
	</div>
</template>

<script>
import { ref, watch, computed, reactive } from "@vue/composition-api";
import lodash from "lodash";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import eggsFoundService from "@/services/header/eggs-found.service";
import ListEggsFound from "./ListEggsFound";
import store from "@/store";

export default {
	components: { SpecimenPlate, ListEggsFound },
	props: { show: Boolean },
	setup(props, ctx) {
		// handle sidebar model
		let isActive = ref(false);
		watch(
			() => props.show,
			(val) => {
				if (val) {
					isActive.value = true;
					getMadrillas();
				}
			}
		);

		function handleHidden() {
			sMadrilla.value = null;
			madrillasOpts.value = [];
			comment.value = "";
			plateMAdrilla.value = null;
			eggsCount.value = 1;
			selectedMadrilla = null;
			isActive.value = false;
			ctx.emit("closing");

			modalSelectMadrilla.value.show = false;
			modalSelectMadrilla.value.title = null;
			modalSelectMadrilla.value.data = [];
		}

		// get madrillas
		let sMadrilla = ref(null);

		let madrillasOpts = ref([]);
		async function getMadrillas(str = "") {
			let body = {
				q: str,
			};
			let data = await eggsFoundService.getMadrillas(body);
			if (!data.success) console.error(data);
			madrillasOpts.value = data.data.map((opt) => {
				if (opt.alias) return { label: `${opt.alias} / ${opt.plate}`, value: opt.id };
				return { label: `${opt.plate}`, value: opt.id };
			});
			// inserts the last selected option because when a option in selected, the list reloads and loses the selected label
			if (selectedMadrilla != null && !madrillasOpts.value.some((opt) => opt.value == selectedMadrilla.value)) {
				madrillasOpts.value.push(selectedMadrilla);
			}
		}
		let searchMadrillasOpts = lodash.debounce(async function (str, setLoading) {
			setLoading(true);
			await getMadrillas(str);
			setLoading(false);
		}, 100);

		// register saving
		let comment = ref("");
		let plateMAdrilla = ref("");
		let eggsCount = ref(1);
		let plateMadrillaError = ref("");
		async function saveRegister() {
			this.isPreloading();

			const { message, status, specimens } = await eggsFoundService.validPlateSpecimen({
				plate: plateMAdrilla.value,
			});

			this.isPreloading(false);

			if (status) {
				// En el caso el ejemplar sea unico
				plateMadrillaError.value = "";

				// console.log("VALID");
				const [spe] = specimens;
				this.setSelectSpecimen(spe.id);
			} else {
				if (specimens.length == 0) {
					// plateMAdrilla.value = null;
					plateMadrillaError.value = message;
				} else {
					plateMadrillaError.value = "";
					modalSelectMadrilla.value.show = true;
					modalSelectMadrilla.value.title = `Opciones para la placa ${plateMAdrilla.value}`;
					modalSelectMadrilla.value.data = specimens;
				}
			}
		}

		let selectedMadrilla = null; // label, value

		function saveSelected(val) {
			selectedMadrilla = val;
		}

		// Select madrilla
		const modalSelectMadrilla = ref({
			show: false,
			title: null,
			data: [],
		});

		async function setSelectSpecimen(id) {
			let body = {
				madrilla_id: id,
				quantity: eggsCount.value,
				comment: comment.value,
			};
			this.isPreloading();
			const { data, message } = await eggsFoundService.save(body);

			this.isPreloading(false);
			this.showToast("success", "top-right", "Huevos encontrados", "CheckIcon", message);

			store.commit("headers/SET_VALUES_EGGS_FOUND", {
				eggsFound: {
					...store.state.headers.eggsFound,
					counter: store.state.headers.eggsFound.counter + 1,
				},
			});

			ctx.emit("refresh");

			// hide and clear modal
			modalSelectMadrilla.value.show = false;
			modalSelectMadrilla.value.title = null;
			modalSelectMadrilla.value.data = [];

			modalRegisterMore.value.show = true;
		}

		// Question continue and cancel
		const modalRegisterMore = ref({
			show: false,
			title: null,
		});
		// async

		function checkRegisterMore() {
			comment.value = "";
			eggsCount.value = 1;
			plateMAdrilla.value = null;

			// ctx.emit("refresh");
			modalSelectMadrilla.value.show = false;
			modalSelectMadrilla.value.title = null;
			modalSelectMadrilla.value.data = [];

			modalRegisterMore.value.show = false;
		}

		function cancelRegisterMore() {
			this.handleHidden();

			modalRegisterMore.value.show = false;
		}

		// Modal list eggs found
		const modalEggsFound = ref({
			show: false,
			title: null,
		});

		return {
			isActive,
			handleHidden,
			sMadrilla,
			madrillasOpts,
			searchMadrillasOpts,
			comment,
			plateMAdrilla,
			eggsCount,
			saveRegister,
			saveSelected,
			modalSelectMadrilla,
			setSelectSpecimen,
			modalRegisterMore,
			checkRegisterMore,
			cancelRegisterMore,
			modalEggsFound,
			plateMadrillaError,
		};
	},
};
</script>

<style scoped lang="scss">
.confirm-title {
	font-size: 18px;
	font-weight: bold;
}
.confirm {
	display: flex;
	justify-content: space-between;
	padding: 0px 10px 5px;
	.option {
		width: 46%;
		float: left;
		color: white;
		font-weight: bold;
		font-size: 20px;
		text-align: center;
		padding: 5px;
		border-radius: 5px;
	}
}
.containwe-eggs {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media screen and (min-width: 700px) {
		grid-template-columns: 1fr 1fr;
	}
}
.container-valid-plate {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media screen and (min-width: 800px) {
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	&__item {
		// background: red;
		padding: 0.5rem 0;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		// border: 1px solid #f5f5f5;
		// padding: 1rem;
		border-radius: 12px;
		overflow: hidden;
		// margin-bottom: 1rem;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
		&:hover {
			background: #f5f5f5;
		}

		h3 {
			background: #7367f0;
			color: #fff;
			padding: 0.5rem;
		}
		.skeletor-specimen-add {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.skeletor {
				position: absolute;
				width: 100%;
				height: 100%;
				background: transparent;
			}
		}
	}
}
// .container-box {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	gap: 10px;

// 	// .box {
// 	// 	// height: 125px;
// 	// 	display: flex;
// 	// 	align-items: center;
// 	// 	justify-content: center;
// 	// 	flex-direction: column;
// 	// 	cursor: pointer;
// 	// 	p {
// 	// 		font-weight: bolder;
// 	// 		color: #fff;
// 	// 		// font-size: 16px;
// 	// 		// margin-bottom: 0.5rem;
// 	// 	}
// 	// }
// }
</style>
