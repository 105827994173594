
export default {
	namespaced: true,
	state: {
		amountLoteNotifications: 1,
	},
	getters: {
		amountLoteNotifications(state) {
			return state.amountLoteNotifications
		},
	},
	mutations: {
		'SET_AMOUNT_LOTE_NOTIFICATIONS'(state, payload) {
			state.amountLoteNotifications = payload
		},
	},
	actions: {
		
	},
}
