import router from "@/router";

export default [
    {
        path: "/users-route",
        name: "users-amg",
        component: () => import("@/views/amg/users/User.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de Usuarios',
            breadcrumb: [
                {
                    text: "Usuarios",
                    active: true
                }
            ],
            roles: [1,2,3,4,5,6,7,8,9,10,11]
        }
    },
    {
        path: "/users-route/create",
        name: "create-user",
        component: () => import("@/views/amg/users/CreateUser.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de Usuarios',
            breadcrumb: [
                {
                    text: "Usuarios",
                    active: false,
                    to: '/users-route'
                },
                {
                    text: "Crear",
                    active: true
                }
            ],
            roles: [1]
        }
    },
    {
        path: "/users-route/editar/:user_id",
        name: "editar-user",
        component: () => import("@/views/amg/users/CreateUser.vue"),
        meta: {
            layout: "vertical",
            pageTitle: 'Lista de Usuarios',
            breadcrumb: [
                {
                    text: "Usuarios",
                    active: false,
                    to: '/users-route'
                },
                {
                    text: "Editar",
                    active: true
                }
            ],
            roles: [1,2,3,4,5,6,7,8,9,10,11]
        },
        props: (route) => {
            const id = Number(route.params.user_id);
            return isNaN(id) ? router.push({ name: 'menu' }) : id;
        },
    }
];
