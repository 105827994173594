/** @ts-check */
import { showToast } from "@/helpers/toast";
import pedigreeService from "@/services/pedigree.service";
import types from "./types";

export default {
	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async fetchPedigree({ commit }, { specimen_id }) {
		try {
			const pedigree = await pedigreeService.getSpecimenPedigree(specimen_id);
			commit(types.FETCH_PEDIGREE, pedigree)
			return pedigree;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param { Object<string, number> }
	 */
	async fetchSpecimenForUpdate({ commit }, { specimen_id }) {
		try {
			const specimen = await pedigreeService.fetchSpecimenForUpdate(specimen_id);
			commit(types.FETCH_SPECIMEN_FOR_UPDATE, specimen);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * @param { import('vuex').ActionContext }
	 * @param {boolean} state
	 */
	fetchPending({ commit }, state) {
		commit("app/M_IS_PRELOADING", state, { root: true });
	},
};
