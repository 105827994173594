export default [
	{
		path: "/brain/torneos",
		name: "brain-tournament-main",
		redirect: { name: "brain-tournament-colliseum" },
		component: () => import("@/views/brain/tournament/main.vue"),
		children: [
			{
				path: "coliseos",
				name: "brain-tournament-colliseum",
				component: () => import("@/views/brain/tournament/views/coliseums/ListColiseumBrain.vue"),
				meta: {
					pageTitle: "Configuración Torneos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Torneos",
							active: true,
						},
						{
							text: "Coliseos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "contacts",
				name: "brain-tournament-contacts",
				component: () => import("@/views/brain/tournament/views/contacts/ListContactsBrain.vue"),
				meta: {
					pageTitle: "Configuración Torneos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Torneos",
							active: true,
						},
						{
							text: "Contactos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "participants",
				name: "brain-tournament-participants",
				component: () => import("@/views/brain/tournament/views/participants/ListParticipantsBrain.vue"),
				meta: {
					pageTitle: "Configuración Torneos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Torneos",
							active: true,
						},
						{
							text: "Participantes",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "seasons",
				name: "brain-tournament-seasons",
				component: () => import("@/views/brain/tournament/views/Seasons.vue"),
				meta: {
					pageTitle: "Configuración Torneos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Torneos",
							active: true,
						},
						{
							text: "Temporadas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "expenses",
				name: "brain-tournament-expenses",
				component: () => import("@/views/brain/tournament/views/Expenses.vue"),
				meta: {
					pageTitle: "Configuración Torneos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Torneos",
							active: true,
						},
						{
							text: "Viaticos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
];
