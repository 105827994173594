<template>
  <div class="CRadio">
    <span class="CRadio__label">Color:</span>
    <div class="CRadio__options">
      <div class="Option" v-for="(option, i) in options" :key="option">
        <input
          class="Option__input"
          type="radio"
          name="color"
          :id="`${i}${option}`"
          @input="updateColor(option)"
          :checked="value == option"
        />
        <label class="Option__label" :style="{ background: option }" :for="`${i}${option}`" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: String,
  },
  emits: ["input"],
  setup(props, ctx) {
    let options = ["red", "orange", "yellow", "green", "blue", "purple"].map((color) =>
      getComputedStyle(document.documentElement).getPropertyValue(`--${color}`).trim()
    );
    let selected = ref("#ff9f43");
    // [ "#ea5455", "#ff9f43", "#ffc107", "#28c76f", "#00cfe8", "#7367f0" ]

    function updateColor(val) {
      ctx.emit("input", val);
    }

    return { options, selected, updateColor };
  },
});
</script>

<style lang="scss" scoped>
.CRadio {
  display: flex;
  margin: 1rem 0;

  &__label {
    margin-right: 1rem;
  }

  &__options {
    display: inline-block;
  }
}

.Option {
  display: inline-block;
  margin-right: 1rem;

  &__input {
    display: none;

    &:checked + label {
      position: relative;
      width: 1.4rem;
      height: 1.4rem;
      border: 2px double var(--white);

      &::after {
        content: "✓";
        position: absolute;
        top: -3px;
        left: 3px;
        color: var(--white);
        font-size: 1rem;
      }
    }
  }

  &__label {
    width: 1.2rem;
    height: 1.2rem;
    background: none;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
