import axios from "@/axios";

const url = "/api/notifications";

class NotificationsService {

	async getNotifications({ perpage, page }) {
		try {
			const { data } = await axios.get(`${url}/get-notifications`, {
				params: {
					perpage, page
				},
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

    async getCount() {
		try {
			const { data } = await axios.get(`${url}/get-count-notifications-pending`, {
				
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
	
	async updateSeen({ id, is_global = false }) {
		try {
			const { data } = await axios.put(`${url}/update-seen/${id}`, {
				is_global
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	

	
}
export default new NotificationsService();
