// import { amgApi } from "@/service/axios";

import axios from "@/axios";

class ContactService {
    async saveContact(body) {
        const data = await axios.post(`/api/contact/save`, body);
        return data;
    }
    async getContactsToSelect(body) {
        const data = await axios.get(`/api/contact/get-to-select`, body);
        return data;
    }
    async addContactsToTournament(body) {
        const data = await axios.post(
            `/api/contact/add-contacts-to-tournament`,
            body
        );
        return data;
    }
    async deleteContactFromTournament(body) {
        const data = await axios.post(
            `/api/contact/delete-contact-from-tournament`,
            body
        );
        return data;
    }

    async addHoldersAccounts(body) {
        const { data } = await axios.post(
            `/api/contact/add-holders-accounts`,
            body
        );
        return data;
    }
    async getHoldersAccounts() {
        const { data } = await axios.get(`/api/contact/get-holders-accounts`);
        return data;
    }

    async addBanksAccounts(body) {
        const { data } = await axios.post(
            `/api/contact/add-banks-accounts`,
            body
        );
        return data;
    }
    async saveBankInformation(body) {
        const { data } = await axios.post(
            `/api/contact/save-bank-information`,
            body
        );
        return data;
    }

    async getBanksAccountsByHolder(body) {
        const { data } = await axios.post(
            `/api/contact/get-banks-accounts-by-holder`,
            body
        );
        return data;
    }

    async addBanks(body) {
        const { data } = await axios.post(`/api/contact/add-banks`, body);
        return data;
    }
    async getBanks() {
        const { data } = await axios.get(`/api/contact/get-banks`);
        return data;
    }
}
export default new ContactService();
